// antd
import { Col, Form, Row } from 'antd'
// firebase
import { sendEmailVerification } from 'firebase/auth'
// helper
import { auth } from '../../helpers/firebase/firebase'
import { getAlertMessage } from '../../helpers/utils/AlertUtils'
// hook
import useNotification from '../../hooks/useNotification'
// react
import { useTranslation } from 'react-i18next'
// style
import styles from './SendVerification.module.css'

const SendVerification = () => {
    //i18next for language changing
    const { t } = useTranslation(['app', 'alerts', 'requires']);
    // hook
    const { alertError, alertSuccess } = useNotification();

    const handleSubmit = async () => {
        auth.languageCode = localStorage.getItem('i18nextLng') || 'en';
        sendEmailVerification(auth.currentUser, {
            url: "https://frewell.com",
            languageCode: localStorage.getItem('i18nextLng') || 'en'
        })
            .then(() => {
                alertSuccess(t('email_verification_sent', { ns: 'alerts' }));
            })
            .catch((error) => {
                let errorMessage = getAlertMessage(error.code);
                alertError(t(errorMessage, { ns: "alerts" }));
            });
    }
    return (
        <Row className={styles.container}>
            <Col className={styles.containerColumn}>
                <h1 className={styles.usePromoHeader}>{t('verify_email', { ns: 'app' })}</h1>
                <p className={styles.promoDescription}>{t('verify_email_info', { ns: 'app' })}</p>
                <Form
                    name='promoForm'
                    onFinish={handleSubmit}
                >
                    <button type="submit" className={styles.usePromoButton}>{t('verify_email_button', { ns: 'app' })}</button>
                </Form>
            </Col>
        </Row>
    )
}

export default SendVerification