import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Row } from 'antd'
import { useTranslation } from 'react-i18next';
import { deleteUserFunc } from '../../helpers/firebase/firebase_user_methods';
import useNotification from '../../hooks/useNotification';
import styles from './DeleteUser.module.css'

const { confirm } = Modal;

const DeleteUser = () => {
    //i18next for language changing
    const { t } = useTranslation(['app']);
    // hook
    const { alertSuccess } = useNotification();

    const showConfirm = () => {
        confirm({
            title: t('delete_user_info', { ns: 'app' }),
            icon: <ExclamationCircleOutlined />,
            content: t('delete_user_info2', { ns: 'app' }),
            okText: t('yes', { ns: 'app' }),
            cancelText: t('no', { ns: 'app' }),
            async onOk() {
                await deleteUserFunc().then((res) => {
                    alertSuccess(t('delete_successfully', { ns: 'app' }))
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 3500);
                });
            },
            onCancel() {

            },
        });
    };
    return (
        <Row className={styles.deleteUserContainer}>
            <Row className={styles.firstDescription}>
                <p>{t('warning_delete01', { ns: 'app' })}</p>
            </Row>
            <ol className={styles.list}>
                <li>{t('warning_delete02', { ns: 'app' })}</li>
                <li>{t('warning_delete03', { ns: 'app' })}</li>
                <li>{t('warning_delete04', { ns: 'app' })}</li>
                <li>{t('warning_delete05', { ns: 'app' })}</li>
                <li>{t('warning_delete06', { ns: 'app' })}</li>
            </ol>
            <p className={styles.warning}>{t('delete_user_info2', { ns: 'app' })}</p>
            <Row align='middle'>
                <button className={styles.confirmButton} onClick={showConfirm}>{t('yes', { ns: 'app' })}</button>
            </Row>
        </Row>
    )
}

export default DeleteUser