
import { addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, updateDoc, where } from "firebase/firestore";

import { auth, db } from "./firebase";
import { getCurrentUserDetails } from "./firebase_user_methods";

//Uplaod feedback with feedback object and user id
export const uploadFeedback = async (feedback) => { 
    //to get current user
    let user = await getCurrentUserDetails();
    // create feedback doc with auto generated id
    await addDoc(collection(db, "feedback"), {
        feedback_text : feedback?.feedback_text,
        created_at : new Date(),
        disease_category : feedback?.disease_category,
        disease_name : feedback?.disease_name,
        user_id : user.uid,
        value : feedback.value,
        name : user.name,
        surname : user.surname
    }).catch((error) => {
        console.log(error)
    });
}

export const isVoiceUpload = async () => {
    const usersRef = doc(db, "users", auth.currentUser.uid);
    let documentSnapshots = await getDoc(usersRef);
    return documentSnapshots.data().is_active;
}

// export const isVoiceListened = async () => {
//     const usersRef = doc(db, "users", auth.currentUser.uid);
//     let documentSnapshots = await getDoc(usersRef);
//     return documentSnapshots.data().is_listened;
// }
export const getAverageRating = async () => {
    let averageRating = 0;
    let q = query(collection(db, "feedback"));
    let documentSnapshots = await getDocs(q);
    documentSnapshots.forEach((doc) => {
        averageRating += doc.data().value;
    });
    averageRating = averageRating / documentSnapshots.size;
    //get 1 decimal place
    averageRating = Math.round(averageRating * 10) / 10;
    return averageRating;
}

//Get rating object with given star value
export const getRatingsWithValue = async (value) => {
    let ratings = [];
    let q = query(collection(db, "feedback"), where("value", "==", value));
    let documentSnapshots = await getDocs(q);
    documentSnapshots.forEach((doc) => {
        ratings.push(doc.data());
    });
    return ratings;
}

export const getRatingsWithValueByLimit = async (rating,feedback_number) => {
    let ratings = [];
    let q = query(collection(db, "feedback"),where("value", "==", rating),orderBy("created_at","desc"),limit(feedback_number));
    let documentSnapshots = await getDocs(q);
    documentSnapshots.forEach((doc) => {
        ratings.push(doc.data());
    })
    return ratings;
}
//Get all feedbacks number
export const getFeedbackNumber = async () => {
    let feedbackNumber = 0;
    let q = query(collection(db, "feedback"));
    let documentSnapshots = await getDocs(q);
    feedbackNumber = documentSnapshots.size;
    return feedbackNumber;
}

export const madeNotAvailableForFeedback = async (action_id) => {
    const userRef = doc(db, "users", auth.currentUser.uid);
    let documentSnapshots = await getDoc(userRef);
    //update is_avaliable_feedback to false for given keys of voice
    await updateDoc(userRef, {
        voices: {
            ...documentSnapshots.data().voices,
            [action_id]: {
                ...documentSnapshots.data().voices[action_id],
                is_available_feedback: false,
                // created_at: documentSnapshots.data().voices[action_id].created_at,
                // disease_category: documentSnapshots.data().voices[action_id].disease_category,
                // disease_name: documentSnapshots.data().voices[action_id].disease_name,
                // is_available_feedback: false,
                // is_listened: documentSnapshots.data().voices[action_id].is_listened,
            }
        }
    })
}