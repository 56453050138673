import { Col, Table } from 'antd';
import { useState } from 'react';
import { getNewsletters } from '../../helpers/firebase/firebase_admin_methods';
import useConstructor from './use_constructor';

const columns = [
    {
        title: 'E-posta',
        dataIndex: 'email',
    },
    {
        title: 'Kayıt Tarihi',
        dataIndex: 'created_at',
    },
];

const NewsDisplay = () => {
    const [newsletter, setNewsletter] = useState();
    useConstructor(async () => {
        setNewsletter(await getNewsletters())
    });
    return (
        <Col span={24} >
            <Table columns={columns} dataSource={newsletter} />
        </Col>
    )
}

export default NewsDisplay