import { useEffect, useState } from 'react'
import Loading from '../components/Loading/Loading'
import SuccessComponent from '../components/SuccessComponent/SuccessComponent'

const Successful = () => {
    let [showSuccess, setShowSuccess] = useState(false)
    useEffect(() => {
        //if previous page is url is starts with https://checkout.stripe.com/c/pay/, so show success page
        //if not ,user trying to access this page directly, so redirect to home page
        //else redirect to what-is-frewell
        // console.log(document.referrer)
        // if (document.referrer.startsWith('https://checkout.stripe.com/c/pay/')) {
        //     console.log('success')
        //     //load success page
        //     setShowSuccess(true)
        // } else {
        //     window.location.href = '/';
        // }
        setShowSuccess(true)
    }, [])
    return (
        <>
            {
                showSuccess ? <SuccessComponent /> : <Loading />
            }
        </>
    )
}

export default Successful