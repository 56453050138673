// antd
import { Col, Row } from 'antd'
import { MenuOutlined, RightOutlined } from '@ant-design/icons'
// components
import Navbar from '../Navbar/Navbar'
import OneFrequencySend from '../OneFrequencySend/OneFrequencySend'
import MyRecord from '../MyRecord/MyRecord'
import SpecialFrequencySend from '../SpecialFrequencySend/SpecialFrequencySend'
// import PromoCode from '../PromoCode/PromoCode'
// import UsePromoCode from '../UsePromoCode/UsePromoCode'
import OneFrequencySendError from '../OneFrequncySendError/OneFrequencySendError'
import SpecialFrequencySendError from '../SpecialFrequencySendError/SpecialFrequencySendError'
// helpers
import { currentUserAvailableForUploadOneFrequency, currentUserHaveSpecialPackage } from '../../helpers/firebase/firebase_check_utils';
//i18next
import { useTranslation } from 'react-i18next'
// react
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
// style
import styles from './AppComponent.module.css'
import RemaninigFrequncies from '../RemaninigFrequncies/RemaninigFrequncies'
import RemainingPackage from '../RemainingPackage/RemainingPackage'
// import DeleteUser from '../DeleteUser/DeleteUser'

const AppComponent = () => {
    //it is used for changing language
    const { t } = useTranslation(['app', 'record']);
    // get parametres from url
    const { diseaseNameFromUrl, promoCodeUrl } = useParams();
    // react-router-dom
    let { pathname } = useLocation();
    const navigate = useNavigate();

    // states
    const [menu, setMenu] = useState({
        frequncy: {
            display: false,
            error: false,
        },
        specialPackage: {
            display: false,
            error: false,
        },
        myHistory: false,
        proCode: false,
        usePromo: false,
    })

    useEffect(() => {
        //call async function
        const checkUser = async () => {
            await currentUserAvailableForUploadOneFrequency().then((res) => {
                if (res.status) {
                    setMenu({ frequncy: { display: true, error: false } })
                } else {
                    setMenu({ frequncy: { display: true, error: true, message: res?.message } })
                }
            })
        }
        // if user click promo code link, this control working
        if (pathname === '/app/promoCode') {
            setMenu({ proCode: true })
        } else if (pathname === '/app/special') {
            setMenu({ specialPackage: { display: true, error: false } });
        } else if (promoCodeUrl) {
            setMenu({ usePromo: true })
        } else {
            checkUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Navbar design={true} />
            <Row className={styles.appContainer} justify='center'>
                <Col xxl={5} xl={5} lg={5} md={22} sm={22} xs={22} justify='end' align='end'>
                    <RemaninigFrequncies />
                    <RemainingPackage />
                    <Col className={styles.menuContainer}>
                        <Row
                            className={menu.frequncy?.display ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={async () => {
                                navigate("/app");
                                await currentUserAvailableForUploadOneFrequency().then((res) => {
                                    if (res.status) {
                                        setMenu({ frequncy: { display: true, error: false } })
                                    } else {
                                        setMenu({ frequncy: { display: true, error: true, message: res?.message } })
                                    }
                                });
                            }
                            }
                            key='frequency'
                            wrap={false}
                        >
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                {
                                    menu.frequncy?.display
                                        ?
                                        <img className={styles.gradientIcon} src="/assets/images/wave-gradient.png" alt="active menu icon" />
                                        :
                                        <img className={styles.passiveIcon} src="/assets/images/wave-passive.png" alt="passive menu icon" />
                                }
                                <p className={menu.frequncy?.display ? styles.menuTitleActive : styles.menuTitlePassive}>{t('single_frequency', { ns: "app" })}</p>
                            </Row>
                            {menu.frequncy?.display && <RightOutlined className={styles.arrow} />}
                        </Row>
                        <Row
                            className={menu?.specialPackage?.display ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={async () => {
                                navigate('/app/special')
                                if (await currentUserHaveSpecialPackage()) {
                                    setMenu({ specialPackage: { display: true, error: false } });
                                } else {
                                    setMenu({ specialPackage: { display: true, error: true } });
                                }
                            }}
                            wrap={false}
                        >
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                {
                                    menu.specialPackage?.display ?
                                        <img className={styles.gradientIcon} src="/assets/images/wave-gradient.png" alt="active menu icon" />
                                        :
                                        <img className={styles.passiveIcon} src="/assets/images/wave-passive.png" alt="passive menu icon" />
                                }
                                <p className={menu.specialPackage?.display ? styles.menuTitleActive : styles.menuTitlePassive}>{t('use_package', { ns: "app" })}</p>
                            </Row>
                            {menu.specialPackage?.display && <RightOutlined className={styles.arrow} />}
                        </Row>
                        <Row
                            className={menu?.myHistory ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={() => {
                                navigate("/app");
                                setMenu({ myHistory: true })
                            }
                            }
                            wrap={false}
                        >
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                <MenuOutlined className={styles.menuIcons} />
                                <p className={menu.myHistory ? styles.menuTitleActive : styles.menuTitlePassive}>{t('frequency_record', { ns: "app" })}</p>
                            </Row>
                            {menu.myHistory && <RightOutlined className={styles.arrow} />}
                        </Row>
                        {/* <Row
                            className={menu?.proCode ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={() => {
                                navigate("/app");
                                setMenu({ proCode: true })
                            }
                            }
                            wrap={false}
                        >
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                <ShareAltOutlined className={styles.menuIcons} />
                                <p className={menu.proCode ? styles.menuTitleActive : styles.menuTitlePassive}>{t('promo_codes', { ns: "app" })}</p>
                            </Row>
                            {menu.proCode && <RightOutlined className={styles.arrow} />}
                        </Row> */}
                        {/* <Row
                            className={menu?.usePromo ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={() => {
                                navigate("/app");
                                setMenu({ usePromo: true })
                            }
                            }
                            wrap={false}
                        >
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                <GiftOutlined className={styles.menuIcons} />
                                <p className={menu.usePromo ? styles.menuTitleActive : styles.menuTitlePassive}>{t('use_promo_code', { ns: "app" })}</p>
                            </Row>
                            {menu.usePromo && <RightOutlined className={styles.arrow} />}
                        </Row> */}
                    </Col>
                </Col>
                <Col xxl={16} xl={16} lg={16} md={22} sm={22} xs={22} className={styles.sideComponents}>
                    {menu.frequncy?.display && !menu.frequncy?.error && <OneFrequencySend diseaseNameFromUrl={diseaseNameFromUrl} />}
                    {menu.frequncy?.display && menu.frequncy?.error && <OneFrequencySendError message={menu.frequncy?.message} />}
                    {menu.specialPackage?.display && !menu.specialPackage?.error && <SpecialFrequencySend />}
                    {menu.specialPackage?.display && menu.specialPackage?.error && <SpecialFrequencySendError />}
                    {menu.myHistory && <MyRecord />}
                    {/* {menu.proCode && <PromoCode />} */}
                    {/* {menu.usePromo && <UsePromoCode promoCodeUrl={promoCodeUrl} />} */}
                </Col>
            </Row>
        </>
    )
}

export default AppComponent