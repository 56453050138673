import { Pie } from "@ant-design/plots";
import { useState } from "react";
import { getAgeStats } from "../../helpers/firebase/firebase_admin_methods";
import useConstructor from "./use_constructor";
import styles from "./statistic_style.module.css"
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";


const AgeChart = () => {
    const { t } = useTranslation(['admin']);
    const [data, setData] = useState([]);
    const { RangePicker } = DatePicker;
    const config = {
        appendPadding: 10,
        data,
        angleField: "count",
        colorField: "name",
        radius: 0.75,
        label: {
            type: "spider",
            labelHeight: 28,
            content: "{name}\n{percentage}",
        },
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
        ],
    };
    useConstructor(async () => {
        await getAgeStats((Date.now() - 30 * 24 * 60 * 60 * 1000), Date.now()).then((res) => {
            setData(res);
        });
    });
    return (
        <div className="App">
            <h2>{t("age")}</h2>
            <div>
                <RangePicker onChange={async (date) => {
                    await getAgeStats(date[0]._d.getTime(), date[1]._d.getTime()).then((res) => {
                        setData(res);
                    });
                }} />
                <Pie className={styles.Pie} {...config} />
            </div>
        </div>
    )
}

export default AgeChart