// antd
import { Carousel, Col } from 'antd'
// components
import CommentCard from '../CommentCard/CommentCard'
// style
import styles from './CommentCardContainer.module.css'

// dummy data
const users = [
    {
        rating: 5,
        comment: "Ayaklarımda sürekli yanma hissediyordum. Frewell Quantum Teknolojisi ile göndermiş olduğum frekans sayesinde yıllardır geçmeyen ayak yanması problemin saniyeler içinde kayboldu. Ve inanılmaz bir şekilde bir daha yaşamadım. Kesinlikle buna benzer şikayetleriniz varsa tavsiyederim.",
        user: "Seyfullah A.",
        country: "Türkiye"
    },
    {
        rating: 4.5,
        comment: "Sınavlardan önce gereksiz çok fazla heyecanlanıyordum. Frewell ile sınavlarda yapmış olduğum gereksiz heyecan ortadan kalktı. Frewell'i kesinlikle tavsiye ederim.",
        user: "Ö. Faruk Y.",
        country: "Türkiye"
    },
    {
        rating: 5,
        comment: "İstemsiz bacaklarımı çok sallıyordum. Frewell'e gönderdiğim frekans ile bacaklarımı sallama hissi bir anda ortadan kalktı. Bunun gibi sizi rahatsız eden tikleriniz varsa sizlere de tavsiye edebilirim.",
        user: "Uğur Aslan",
        country: "Türkiye"
    },
    {
        rating: 5,
        comment: "Eşimle sorunlarımızın çoğunu çözdük. Her zaman evrene doğru mesajı göndermenin önemine inanırdım. Buna aracı olduğunuz için teşekkür ederim. Herkese öneririm.",
        user: "Şahin A.",
        country: "Türkiye"
    },
]

var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    focusOnSelect: true,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 438,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const CommentCardContainer = () => {
    return (
        <Col span={18} justify='center' className={styles.column}>
            <Carousel
                className={styles.crouselContainer}
                {...settings}
            >
                {
                    users.map((user, index) => {
                        return (
                            <CommentCard user={user} key={index} />
                        )
                    })
                }
            </Carousel>
        </Col>
    )
}

export default CommentCardContainer