// antd
import { Col, Row } from 'antd'
// import { LoadingOutlined } from '@ant-design/icons'
// components
import Navbar from '../Navbar/Navbar'
import SpecialPackageCard from '../SpecialPackageCard/SpecialPackageCard'
import TotalUserFeedback from '../TotalUserFeedback/TotalUserFeedback'
import News from '../News/News'
// helpers
import { checkOut, getSpecialPackageDetailsWithName, getOtherSpecialPackagesWithoutThis, getProductIdWithPackageName, isUserHaveThisPackage, purchaseSpecialPackageForAdmin } from '../../helpers/firebase/firebase_package_methods'
// import { auth } from '../../helpers/firebase/firebase'
// import { isCurrentUserAdmin, isUserVerified } from '../../helpers/firebase/firebase_user_methods'
// hook
// import useNotification from '../../hooks/useNotification'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
// react-share
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import styles from './PackageDetailComponent.module.css'

const PackageDetailComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['frequencies', 'record', 'alerts']);
    // react-router-dom
    const { packageNameFromUrl } = useParams();
    // const navigate = useNavigate();
    // states
    const [specialPackages, setSpecialPackages] = useState([]);
    const [packageDetails, setPackageDetails] = useState({});
    // const [productId, setProductId] = useState('');
    // const [loading, setLoading] = useState(false);
    // hook
    // const { alertError } = useNotification();

    useEffect(() => {
        const getData = async () => {
            setSpecialPackages(await getOtherSpecialPackagesWithoutThis(packageNameFromUrl));
            setPackageDetails(await getSpecialPackageDetailsWithName(packageNameFromUrl));
            // setProductId(await getProductIdWithPackageName(packageNameFromUrl));
        }
        getData();
    }, [packageNameFromUrl])
    // navigate to stripe checkout page with product id
    // const navigateToStripe = async (productId) => {
    //     if (auth.currentUser === null) {
    //         navigate('/login');
    //     } else if (await isUserVerified()) {
    //         if (await isCurrentUserAdmin()) {
    //             // buy package without payment
    //             await purchaseSpecialPackageForAdmin(packageNameFromUrl).then((res) => {
    //                 if (res) {
    //                     navigate('/');
    //                 }
    //             })
    //         } else {
    //             //pass price data to payment page
    //             checkOut(productId)
    //         }
    //     } else {
    //         alertError(t('verify-email', { ns: 'alerts' }));
    //         navigate('/')
    //     }
    // }

    return (
        <>
            <Navbar design={true} />
            <Row className={styles.headerContainer}>
                <h1 className={styles.detailHeader}>{t('frequencies_packs', { ns: "frequencies" })}</h1>
            </Row>
            <Row className={styles.contentContainer}>
                <Row className={styles.packageDetailContainer} >
                    <Col xxl={6} xl={6} lg={6} md={22} sm={22} xs={22} align='middle' className={styles.imageContainer}>
                        <img
                            className={styles.packageImage}
                            src={packageDetails?.img_url || '/assets/images/no_image.png'}
                            alt="paket"
                        />
                        <Row justify='center'>
                            <h2 className={styles.packageNameTitle}>
                                {t(packageDetails?.package_name, { ns: "record" })}
                            </h2>
                        </Row>
                        <Row justify='space-around'>
                            <p className={styles.specialPackagePrice}>{packageDetails?.price} $</p>
                            {/* <button className={styles.specialPackageBuy}
                                onClick={async () => {
                                    if (auth.currentUser === null) {
                                        navigate('/login');
                                    }
                                    else if (!(await isUserHaveThisPackage(packageNameFromUrl))) {
                                        setLoading(true);
                                        navigateToStripe(productId);
                                    } else {
                                        alertError(t('already-have', { ns: 'alerts' }));
                                    }
                                }}>{loading ? <LoadingOutlined /> : `${t('buy', { ns: "frequencies" })}`}</button> */}
                        </Row>
                        <Row justify='center' className={styles.shareContainer}>
                            <WhatsappShareButton url={`${window.location.href}`}>
                                <WhatsappIcon round={true} size={40} />
                            </WhatsappShareButton>
                            <TelegramShareButton url={`${window.location.href}`}>
                                <TelegramIcon round={true} size={40} />
                            </TelegramShareButton>
                            <TwitterShareButton url={`${window.location.href}`}>
                                <TwitterIcon round={true} size={40} />
                            </TwitterShareButton>
                            <FacebookShareButton url={`${window.location.href}`}>
                                <FacebookIcon round={true} size={40} />
                            </FacebookShareButton>
                        </Row>
                    </Col>
                    <Col xxl={18} xl={18} lg={18} md={22} sm={22} xs={22} className={styles.descriptionContainer}>
                        <Row>
                            <h1 className={styles.packageFirstTitle}>{t("why_special", { ns: "frequencies" })}</h1>
                            <p className={styles.firstDescription}>
                                {t("why_special_text", { ns: "frequencies" })}
                            </p>
                        </Row>
                        <Row>
                            <h2 className={styles.packageTitle}>{t(packageDetails?.package_name, { ns: "record" })}</h2>
                            <p className={styles.secondDescription}>{t(packageDetails?.package_name + "_01", { ns: "record" })}</p>
                        </Row>
                        <Row>
                            <h2 className={styles.packageTitle}>{t('attention', { ns: "frequencies" })}</h2>
                            <p className={styles.secondDescription}>{t(packageDetails?.package_name + "_02", { ns: "record" })}</p>
                        </Row>

                        <Row>
                            <h2 className={styles.packageTitle}>{t('included_frequencies', { ns: "frequencies" })}</h2>
                            {
                                packageDetails?.frequencies && packageDetails?.frequencies?.map((frequency, index) => {
                                    return (
                                        <Col xl={12} lg={12} md={24} sm={24} xs={24} key={index}>
                                            <Row className={styles.diseaseRow} align='middle' wrap={false}>
                                                <img className={styles.icon} src="/assets/images/wave-gradient.png" alt="wave icon" />
                                                <p className={styles.disease}>{t(frequency, { ns: "record" })}</p>
                                            </Row>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Col>
                </Row>
                <Row className={styles.othersContainer}>
                    <h1 className={styles.othersTitle}>{t('other_packages', { ns: "frequencies" })}</h1>
                    <Row className={styles.othersPackages} justify='center'>
                        {specialPackages.map((specialPackage, index) => {
                            return (
                                <Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={24} align='middle' key={index}>
                                    <SpecialPackageCard specialPackage={specialPackage} />
                                </Col>
                            )
                        })}
                    </Row>
                </Row>
            </Row>
            <TotalUserFeedback />
            <News />
        </>
    )
}

export default PackageDetailComponent