//admin
import AdminPanel from "../admin_panel/AdminPanel";
//components
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import CookiesComponent from "../components/CookiesComponent/CookiesComponent";
//context
import { useAuth } from "../contexts/AuthContext";
//pages
import App from "../pages/App";
import Frequencies from "../pages/Frequencies";
import Help from "../pages/Help";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Pricing from "../pages/Pricing";
import Register from "../pages/Register";
import WhatIsFrewell from "../pages/WhatIsFrewell";
import PackageDetail from "../pages/PackageDetail";
import PageNotFound from "../pages/PageNotFound";
import Contact from "../pages/Contact";
import Successful from "../pages/Successful";
import Cancel from "../pages/Cancel";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import UserAgreement from "../pages/UserAgreement";
import Profile from "../pages/Profile";
// react
import {
    Routes,
    Route,
    BrowserRouter,
    useLocation,
    Navigate,
    Outlet,
} from "react-router-dom";


const AppRouter = () => {
    //AuthContext
    const { currentUser } = useAuth();

    function PrivateRouter() {
        let location = useLocation();
        if (!currentUser) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            return <Navigate to="/login" state={{ from: location }} replace={true} />;
        } else {
            return <Outlet />;
        }
    }

    return (
        <BrowserRouter>
            <div className="pageContainer">
                <ScrollToTop />
                <Routes>
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/what-is-frewell" element={<WhatIsFrewell />} />
                    <Route path="/frequencies" element={<Frequencies />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/user-agreement" element={<UserAgreement />} />
                    <Route element={<PrivateRouter />}>
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/app" element={<App />}>
                            <Route path="/app/:diseaseNameFromUrl" element={<App />} />
                            {/* <Route path="/app/promo/:promoCodeUrl" element={<App />} /> */}
                            {/* It is necessary to main page promo code link clicked */}
                            {/* <Route path="/app/promoCode" element={<App />} /> */}
                            <Route path="/app/special" element={<App />} />
                        </Route>
                        <Route path="/admin" element={<AdminPanel />} />
                        <Route path="/success" element={<Successful />} />
                        <Route path="/cancel" element={<Cancel />} />
                    </Route>
                    <Route path="/details" element={<PackageDetail />}>
                        <Route path="/details/:packageNameFromUrl" element={<PackageDetail />} />
                    </Route>
                </Routes>
                <CookiesComponent />
                {/* <Footer error={false} /> */}
            </div>
        </BrowserRouter>
    )
}

export default AppRouter