// antd
import { Col, Modal } from 'antd'
import Countdown from 'antd/lib/statistic/Countdown'
// helpers
import { getRemainWaitingDate } from '../../helpers/firebase/firebase_check_utils'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// style
import styles from './OneFrequencySendError.module.css'

const OneFrequencySendError = (data) => {
    //i18next for language changing
    const { t } = useTranslation(['errors', 'frequencies']);
    // states
    const [remainDate, setRemainDate] = useState(null);
    const navigate = useNavigate();

    const info = (res) => {
        Modal.info({
            title: t(data?.message, { ns: "errors" }),
            okText: t("ok", { ns: "frequencies" }),
            content: (
                <div>
                    {
                        data?.message === 'waiting_date_expired' ?
                            <button className={styles.nextButton} disabled={true}>
                                <Countdown value={res} onFinish={
                                    //refresh page
                                    () => window.location.reload()
                                } />
                            </button>
                            : <button className={styles.nextButton} onClick={() => {
                                //navigate to pricing
                                navigate('/pricing');
                            }}> {t("buy", { ns: "frequencies" })}
                            </button>
                    }
                </div>
            ),
            onOk() { },
        });
    };

    useEffect(() => {
        //call async function
        getRemainWaitingDate().then((res) => {
            setRemainDate(res);
            info(res);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Col className={styles.errorContainer} span={24}>
            <p className={styles.errorDescription}>{t(data?.message, { ns: "errors" })}</p>
            {
                data?.message === 'waiting_date_expired' ?
                    <button className={styles.nextButton} disabled={true}>
                        <Countdown value={remainDate} onFinish={
                            //refresh page
                            () => window.location.reload()
                        } />
                    </button>
                    : <button className={styles.nextButton} onClick={() => {
                        //navigate to pricing
                        navigate('/pricing');
                    }}> {t("buy", { ns: "frequencies" })}
                    </button>

            }
        </Col>
    )
}

export default OneFrequencySendError