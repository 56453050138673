// antd
import { AudioFilled, CheckOutlined, ExclamationCircleOutlined, LoadingOutlined, PauseCircleFilled, ReloadOutlined } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import Countdown from 'antd/lib/statistic/Countdown'
// components
import SpecialPackageCard from '../SpecialPackageCard/SpecialPackageCard'
import useConstructor from '../../admin_panel/components/use_constructor'
import Timer from '../Timer/Timer'
// helpers
import { uploadBytesFirebaseSpecial } from '../../helpers/firebase/firebase_voice_methods'
import { getAllSpecialPackages, getSpecialPackages, getPackageWithNameFromCurrentUser, makeSpecialPackageStepEnabled, checkThisStepIsEnabledAndHoursLimit, getHourLimitForThisStep } from '../../helpers/firebase/firebase_package_methods'
// hook
import useNotification from '../../hooks/useNotification'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { useRef, useState, useEffect } from 'react'
// react-mic
import { ReactMic } from 'react-mic'
// style
import styles from './SpecialFrequencySend.module.css'

// antd
const { confirm } = Modal;

const SpecialFrequencySend = () => {
    //i18next for language changing
    const { t } = useTranslation(['app', 'record', 'alerts', 'requires']);
    // states
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState({
        firstStep: true,
        secondStep: false,
        thirthStep: false,
        fourthStep: false,
    })
    const [title, setTitle] = useState({
        firstTitle: true,
        secondTitle: false,
        thirthTitle: false,
        fourthTitle: false,
    })
    const [voiceRecord, setVoiceRecord] = useState({
        record: false,
        isUploading: false,
        blob: null,
        voiceUrl: "",
    });
    const [timer, setTimer] = useState(0);
    const [specialPackages, setSpecialPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState('')
    const [myPackage, setMyPackage] = useState();
    const [selectedDisease, setSelectedDisease] = useState("");
    const [diseaseCategory, setDiseaseCategory] = useState("");
    const [recommendedText, setRecommendedText] = useState("");
    const [isEnableForAll, setIsEnableForAll] = useState(false);
    const [remainDateForStep, setRemainDateForStep] = useState(0);
    // ref
    const countRef = useRef(null);
    // hook
    const { alertError, alertSuccess } = useNotification();

    useEffect(() => {
        //call async function
        const check = async (package_name, selectedDisease) => {
            let isEnable = await checkThisStepIsEnabledAndHoursLimit(package_name, selectedDisease);
            setIsEnableForAll(isEnable)
            if (!isEnable) {
                setRemainDateForStep(await getHourLimitForThisStep(package_name, selectedDisease))
            }
        }
        //get is enabled step from mypackage
        myPackage?.steps.forEach((step) => {
            if (step?.is_enabled) {
                setSelectedDisease(step?.step.step_name);
                setDiseaseCategory(myPackage.disease_category);
                setRecommendedText(step?.step.recommenned_text);
                check(myPackage.package_name, step?.step.step_name);
            }
        })
    }, [myPackage])

    useConstructor(async () => {
        let usersPackagesName = [];
        let userPackagesActiveness = [];
        await getSpecialPackages().then((res) => {
            res.forEach((doc) => {
                usersPackagesName.push(doc.package_name);
                userPackagesActiveness.push(doc.is_active);
            });
        });
        await getAllSpecialPackages().then((res) => {
            res.forEach((doc) => {
                if (usersPackagesName.includes(doc.title)) {
                    let indices = [];
                    let idx = usersPackagesName.indexOf(doc.title);
                    while (idx !== -1) {
                        indices.push(idx);
                        idx = usersPackagesName.indexOf(doc.title, idx + 1);
                    }
                    indices.forEach((index) => {
                        if (userPackagesActiveness[index]) {
                            setSpecialPackages((prev) => [...prev, doc]);
                        }
                    });
                }
            })
        });
    });
    // when user select click package, this function work
    const handleClick = async (e) => {
        setSelectedPackage(e.target.parentElement.children[1].title);
        await getPackageWithNameFromCurrentUser(e.target.parentElement.children[1].title).then((res) => {
            setMyPackage(res);
        });
        setTitle({ firstTitle: false, secondTitle: true })
        setSteps({ secondStep: true });
    }
    //When click start recording button, this function call and timer start ticking!
    const timerStart = () => {
        let count = 0;
        countRef.current = setInterval(() => {
            //set timer plus one and if timer is 20, call error function
            count++;
            setTimer(count);
            if (count === 21) {
                timerStop()
                error();
                setVoiceRecord({
                    ...voiceRecord,
                    record: false,
                });
                //stop get the audio from microphone
            }
        }, 1000);
    };
    //When click stop button, this function call and timer stop!
    const timerStop = () => {
        clearInterval(countRef.current);
    };
    // When click start button, this function work
    const startRecording = () => {
        setVoiceRecord({
            ...voiceRecord,
            record: true,
        });
        timerStart();
    };
    // When click stop button, this function work
    const stopRecording = () => {
        timerStop();
        setVoiceRecord({
            ...voiceRecord,
            record: false,
        });
    };
    // error popup
    const error = () => {
        Modal.error({
            title: t('tenseconds', { ns: "requires" }),
            okText: t('ok', { ns: "requires" }),
            content: (
                <div>
                    <p>{t('try_again', { ns: "requires" })}</p>
                </div>
            ),
            onOk() { window.location.reload(true) },
        });
    };
    // react-mic stop function
    const onStop = (recordedBlob) => {
        //if blob is null or size is 0, call error function
        if (recordedBlob.blob === null || recordedBlob.blob.size === 0 || recordedBlob.blob.size === undefined || (recordedBlob.startTime === recordedBlob.stopTime)) {
            alert(t('novoice', { ns: "requires" }));
            return;
        }
        const duration = Number(recordedBlob.stopTime) - Number(recordedBlob.startTime);
        if (duration < 21000) {
            setVoiceRecord({
                ...voiceRecord,
                isUploading: true,
                blob: recordedBlob.blob,
                voiceUrl: recordedBlob.blobURL,
            });
            setSteps({ fourthStep: true })
        } else {
            setVoiceRecord({
                record: false,
                isUploading: false,
                blob: null,
                voiceUrl: "",
            });
            error();
        }
    };
    // upload function
    const uploadingRecord = () => {
        setLoading(true);
        setVoiceRecord({
            record: false,
            isUploading: false,
        });
        uploadBytesFirebaseSpecial(voiceRecord.blob, diseaseCategory, selectedDisease)
            .then(async (res) => {
                if (res) {
                    alertSuccess(t('succesfull_upload_one_frequency', { ns: "alerts" }));
                    await makeSpecialPackageStepEnabled(myPackage.package_name, selectedDisease)
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 2000);
                } else {
                    alertError(t('network_error', { ns: "alerts" }));
                }
            })
            .catch((err) => {
                alertError(t('network_error', { ns: "alerts" }));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // warning pop up
    const showConfirm = () => {
        confirm({
            title: t('confirm_upload', { ns: "requires" }),
            icon: <ExclamationCircleOutlined />,
            content: t('confirm_upload_pack', { ns: "requires" }),
            okText: t('ok', { ns: "requires" }),
            cancelText: t('cancel', { ns: "requires" }),
            onOk() {
                uploadingRecord();
            },
            onCancel() { },
        });
    };
    // if user click first step, this function work
    const handleFirstStep = () => {
        if ((steps.secondStep === true || steps.thirthStep === true) && steps.firstStep !== true && steps.fourthStep !== true) {
            setSteps({
                firstStep: true,
                secondStep: false,
                thirthStep: false,
            });
            setTitle({
                firstTitle: true,
                secondTitle: false,
                thirthTitle: false,
            });
            if (voiceRecord.record) {
                window.location.reload(true);
            }
        }
    }
    // user select frequency and go through next step
    const handleSecondStep = () => {
        setSteps({ thirthStep: true });
        setTitle({ ...title, thirthTitle: true })
    }
    return (
        <Col className={styles.specialPackageContainer}>
            <Row align='middle'>
                <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                    <p className={styles.firstActiveTitle} onClick={handleFirstStep}>1 - {t('choose_package', { ns: "app" })} </p>
                </Col>
            </Row>
            <Row className={styles.contentRow} justify='space-between'>
                <Col span={1} className={styles.lineColumn}>
                    <p className={styles.line}></p>
                </Col>
                <Col span={23}>
                    {title.firstTitle ?
                        <Row className={styles.specialPackagesRow} justify='start'>
                            {
                                specialPackages.map((specialPackage, index) => {
                                    return (
                                        <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24} key={index}>
                                            <SpecialPackageCard specialPackage={specialPackage} button={true} handleClick={handleClick} />
                                        </Col>
                                    )
                                })
                            }
                        </Row> :
                        <p className={styles.selectedPackage}><CheckOutlined className={styles.checkIcon} /> <span className={styles.package}>"{t(selectedPackage, { ns: "record" })}"</span> {t('choosen', { ns: "app" })}</p>
                    }
                </Col>
            </Row>
            <Row align='middle'>
                <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                    <p className={title.secondTitle ? styles.activeTitle : styles.passiveTitle}>2 - {t('choose_frequency', { ns: "app" })}</p>
                </Col>
            </Row>
            <Row className={styles.contentRow} justify='space-between'>
                <Col span={1} className={styles.lineColumn}>
                    <p className={styles.line}></p>
                </Col>
                {steps.secondStep &&
                    <Col span={23} className={styles.frequencyStepsColumn}>
                        <h2 className={styles.packageTitle}>{t(selectedPackage, { ns: "record" })} {t('frequencies', { ns: "app" })}</h2>
                        {
                            !isEnableForAll && <button className={styles.nextButton} disabled={!isEnableForAll}>
                                <Row>
                                    <Row>{t("waiting_info", { ns: "app" })}</Row>
                                    <Countdown value={remainDateForStep} onFinish={() => setIsEnableForAll(true)} />
                                </Row>
                            </button>
                        }

                        {
                            myPackage?.steps.map((frequency, index) => {
                                return (
                                    (isEnableForAll && frequency.is_enabled) ?
                                        <Row className={styles.stepContainer} align='middle' key={index} wrap={false}>
                                            <p className={styles.stepNumber}>{index + 1}</p>
                                            <img className={styles.stepIcon} src="/assets/images/wave-gradient.png" alt="icon" />
                                            <p className={styles.stepName}>{t(frequency.step.step_name, { ns: "record" })}</p>
                                        </Row>
                                        :
                                        <Row className={styles.stepContainerBlur} align='middle' key={index}>
                                            <p className={styles.stepNumber}>{index + 1}</p>
                                            <img className={styles.stepIcon} src="/assets/images/wave-gradient.png" alt="icon" />
                                            <p className={styles.stepName}>{t(frequency.step.step_name, { ns: "record" })}</p>
                                        </Row>
                                )
                            })
                        }
                        {
                            isEnableForAll &&
                            <button className={styles.nextButton} onClick={handleSecondStep} disabled={!isEnableForAll}>
                                {t("send_next", { ns: "app" })}
                            </button>
                        }

                    </Col>
                }
            </Row>
            <Row align='middle'>
                <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                    <p className={title.thirthTitle ? styles.activeTitle : styles.passiveTitle}>3 - {t('get_record', { ns: "app" })}</p>
                </Col>
            </Row>
            <Row className={styles.contentRow} justify='space-between'>
                <Col span={1} className={styles.lineColumn}>
                    <p className={styles.line}></p>
                </Col>
                <Col span={23} className={styles.recordColumn} justify='center' align='middle'>
                    {steps.thirthStep
                        ?
                        (
                            <Col justify='center'>
                                <ReactMic
                                    record={voiceRecord.record}
                                    className={styles.record}
                                    visualSetting="frequencyBars"
                                    onStop={onStop}
                                    strokeColor="#3e4581"
                                    backgroundColor="#ffffff"
                                    mimeType='audio/mp3'
                                />

                                <Timer timer={timer} />
                                <Row className={styles.buttonGroup}>
                                    <button className={styles.startButton} onClick={startRecording} disabled={voiceRecord.record}>
                                        <AudioFilled /> {t('start_record', { ns: "app" })}
                                    </button>
                                    <button className={styles.stopButton} onClick={stopRecording} disabled={!voiceRecord.record}>
                                        <PauseCircleFilled /> {t('stop_record', { ns: "app" })}
                                    </button>
                                </Row>
                                <p className={styles.attention}>{t('package_info', { ns: "app" })}</p>
                                <p className={styles.recommendedText} >{t(recommendedText, { ns: "record" })}</p>
                            </Col>
                        )
                        :
                        steps.fourthStep ?
                            <p className={styles.recordIsDone}>
                                <CheckOutlined className={styles.selectedDiseaseIcon} /> {t('done', { ns: "app" })}
                            </p>
                            :
                            <p className={styles.recordWarning}>
                                {t('before_the_record_choose_frequency', { ns: "app" })}
                            </p>
                    }
                </Col>
            </Row>
            <Row align='middle'>
                <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                    <p className={steps.fourthStep ? styles.activeTitle : styles.passiveTitle}>4 - {t('check_and_send', { ns: "app" })}</p>
                </Col>
            </Row>
            <Row className={styles.contentRow} justify='space-between'>
                <Col span={1} className={styles.lineColumn}>
                </Col>
                <Col span={23} >
                    {steps.fourthStep &&
                        <Col className={styles.lastStepContainer}>
                            {loading && <LoadingOutlined className={styles.loadingGif} />}
                            <p className={styles.uploadWarning}>{t('send_info', { ns: "app" })}</p>
                            <audio
                                controls
                                controlsList="nodownload"
                                src={voiceRecord.voiceUrl}
                                className={styles.voiceAudio}
                            />
                            {
                                loading ?
                                    <button className={styles.uploadButton} onClick={showConfirm} disabled>
                                        <CheckOutlined /> {t('check_and_send2', { ns: "app" })}
                                    </button>
                                    :
                                    <button className={styles.uploadButton} onClick={showConfirm}>
                                        <CheckOutlined /> {t('check_and_send2', { ns: "app" })}
                                    </button>
                            }
                            <button className={styles.refreshButton} onClick={() => { window.location.reload(true) }}>
                                <ReloadOutlined /> {t('again_record', { ns: "app" })}
                            </button>
                        </Col>
                    }
                </Col>
            </Row>
        </Col>
    )
}

export default SpecialFrequencySend