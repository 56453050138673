import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc } from "firebase/firestore";
import { listAll, ref, uploadBytes } from "firebase/storage";
import { auth, db, storageRef } from "./firebase";
import { getDiseasesEmptyParam } from "./firebase_admin_methods";
import { decreaseOneRemainingCredit,  updateWaitingTime } from "./firebase_package_methods";
import {currentUserAvailableForUploadOneFrequency} from "./firebase_check_utils";
import { getFunctions, httpsCallable } from "firebase/functions";

export let childRef = null;
//Upload voice details to firebase with given info
export const uploadVoiceDetails = async (disease_category, disease_name, id) => {
    const user = auth.currentUser;
    const userRef = doc(db, "users", user.uid);
    // create voice doc with id
    await setDoc(doc(db, "voices", id.toString()), {
        disease_name: disease_name,
        disease_category: disease_category,
        created_at: new Date(),
        uid: user.uid,
    });
    //add object to map of voices in userref
    let snapshot = await getDoc(userRef);
    let voices = snapshot.data().voices;
    await updateDoc(userRef, {
        voices: {
            ...voices,
            [id]: {
                disease_name: disease_name,
                disease_category: disease_category,
                created_at: new Date(),
                is_listened: false,
                is_available_feedback: true,
                delete_date: null
            }
        }
    });
}

//Upload voice to firebase storage 
export const uploadBytesFirebaseOne = async (blob, disease_category, disease_name) => {
    if ((await currentUserAvailableForUploadOneFrequency()).status) {
        var date = new Date();
        childRef = ref(storageRef, disease_category + "/" + disease_name + "/" + auth.currentUser.uid + "_" + date.getTime());
        //upload bytes format mp3
        await uploadBytes(childRef, blob);
        //Upload voice details to firebase with given info
        await uploadVoiceDetails(disease_category, disease_name, date.getTime());
        await decreaseOneRemainingCredit()
        await updateWaitingTime()
       
        return true;
    } else {
        return false;
    }

}

//Upload voice to firebase storage 
export const uploadBytesFirebaseSpecial = async (blob, disease_category, disease_name) => {
        var date = new Date();
        childRef = ref(storageRef, disease_category + "/" + disease_name + "/" + auth.currentUser.uid + "_" + date.getTime());
        try {
            await uploadBytes(childRef, blob);
            //Upload voice details to firebase with given info
            await uploadVoiceDetails(disease_category, disease_name, date.getTime());
            const functions = getFunctions();
            const sendNotificationForCheck = httpsCallable(functions, 'sendNotificationSpecialPackage');
            await sendNotificationForCheck({ uid: auth.currentUser.uid});
            return true;
        } catch (error) {
            return false;
        }
}
//Get disease from db with disease name 
export const getDiseaseWithName = async (disease_name) => {
    let diseases = await getDiseasesEmptyParam()
    for (let i = 0; i < diseases.length; i++) {
        for (let j = 0; j < diseases[i].diseases.length; j++) {
            if (diseases[i].diseases[j].disease_name === disease_name) {
                let index = getUntilIthElementVoicesLength(i, diseases);
                return { key: index + j + i + 1, disease_category: diseases[i].category_name, disease_name: diseases[i].diseases[j].disease_name, recommended_text: diseases[i].diseases[j].recommended_text };
            }
        }
    }
}

export const getUntilIthElementVoicesLength = (i, array) => {
    let sum = 0;
    for (let j = 0; j < i; j++) {
        sum += array[j].diseases.length;
    }
    return sum;
}

export const getPopulerFrequencies = async () => {
    let q = query(collection(db, "popular_frequencies"));
    let querySnapshot = await getDocs(q);
    let frequencies = [];
    querySnapshot.forEach((doc) => {
        doc.data().popular_frequencies.forEach((frequency) => {
            frequencies.push(frequency);
        })
    });
    return frequencies;
}

//get file number in firebase storage not firestore
export const getVoiceNumber = async () => {
    const voicesRef = ref(storageRef, "");
    let list = await listAll(voicesRef);
    let listNumber = 0;
    for(let i = 0; i < list.prefixes.length; i++){
        let res = await listAll(list.prefixes[i]);
        for(let j = 0; j < res.prefixes.length; j++){
            let res2 = await listAll(res.prefixes[j]);
            listNumber += parseInt(res2.items.length);
        }   
    }
    return listNumber;
}