import React, { useState } from "react";
import styles from "../Create.module.css";
import { Button, Form, Col, Row, Card, Select, Input } from "antd";
import { useEffect } from "react";
import {
    getAllSpecialPackages,
 updateSpecialPackage,
} from "../../helpers/firebase/firebase_package_methods";
import useNotification from "../../hooks/useNotification";

const UpdateSpecialPackage = () => {
  const [packageRef, setPackage] = useState();
  const [packages, setPackages] = useState([]);
  const { Option } = Select;
  const { alertSuccess, alertError } = useNotification();
  const handleSubmit = async () => {
    try {
      await updateSpecialPackage(packageRef.package_name,packageRef.active, packageRef.package_price);
      alertSuccess("Özel Paket Güncellendi");
    } catch (err) {
      alertError(`${err}`);
    }
  };
  useEffect(() => {
    //call async function
    const getPackage = async () => {
      await getAllSpecialPackages().then((res) => {
        setPackages(res);
      });
    }
    getPackage();
  }, []);
  return (
    <div className={styles.container}>
      <Row className={styles.rowstyle}>
        <Col>
          <Card>
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="package_name"
                label="Package Name"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input package!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a package!"
                  onChange={(value) => {
                    setPackage({ ...packageRef, package_name: value });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {
                    packages.map((packageDoc, index) => {
                      return <Option value={packageDoc.title} key={index}>{packageDoc.title}</Option>;
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name="active"
                label="Active"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input active or passive!",
                    },
                ]}
                >
                <Select
                    showSearch
                    placeholder="Select a active or passive!"
                    onChange={(value) => {
                        setPackage({ ...packageRef, active: value });
                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    <Option value={true}>Active</Option>
                    <Option value={false}>Passive</Option>
                </Select>
                </Form.Item>


              <Form.Item
                name="package_price"
                label="Package Price"
                labelCol={{ span: 24 }}
              >
                <Input
                  type="number"
                  placeholder="Package Price"
                  onChange={(e) => {
                    setPackage({ ...packageRef, package_price: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item className={styles.loginButton}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginformbutton}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UpdateSpecialPackage;
