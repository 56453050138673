import { Button, Table } from 'antd';
import { useState } from 'react';
import { deleteContactMessages, getContactMessages } from '../../helpers/firebase/firebase_admin_methods';
import useConstructor from './use_constructor';

const ContactDisplay = () => {
    const [contact, setContact] = useState();
    useConstructor(async () => {
        setContact(await getContactMessages())
        console.log(await getContactMessages())
    });
    const columns = [
        {
            title: 'Kullanıcı Adı',
            dataIndex: 'name',
            // ellipsis: {
            //     showTitle: false,
            // },
            // render: (name) => (
            //     <Tooltip placement="topLeft" title={name}>
            //         {name}
            //     </Tooltip>
            // ),
        },
        {
            title: 'Kullanıcı Soyadı',
            dataIndex: 'surname',
            // ellipsis: {
            //     showTitle: false,
            // },
            // render: (surname) => (
            //     <Tooltip placement="topLeft" title={surname}>
            //         {surname}
            //     </Tooltip>
            // ),
        },
        {
            title: 'E-posta',
            dataIndex: 'email',
            // ellipsis: {
            //     showTitle: false,
            // },
            // render: (email) => (
            //     <Tooltip placement="topLeft" title={email}>
            //         {email}
            //     </Tooltip>
            // ),
        },
        {
            title: 'Mesaj',
            dataIndex: 'message',
            width: 400
            // ellipsis: {
            //     showTitle: false,
            // },
            // render: (message) => (
            //     <Tooltip placement="topLeft" title={message}>
            //         {message}
            //     </Tooltip>
            // ),
        },
        {
            title: 'Tarih',
            dataIndex: 'created_at',
            // ellipsis: {
            //     showTitle: false,
            // },
            // render: (created_at) => (
            //     //object has seconds and nanoseconds return date string
            //     <Tooltip placement="topLeft" title={new Date(created_at.seconds * 1000).toLocaleString()}>
            //         {new Date(created_at.seconds * 1000).toLocaleString()}
            //     </Tooltip>
            // ),
        },
        {
            title: 'Sil',
            dataIndex: 'id',
            render: (text, record) => {
                return <div>
                    <Button onClick={async () => {
                        await deleteContactMessages(record.id)
                        //manually update the table
                        contact.splice(contact.indexOf(record), 1);
                        setContact([...contact])
                    }}>Sil</Button>
                </div>
            }
        }

    ];
    return (
        <div style={{
            width: '90%',
        }}>
            <Table columns={columns} dataSource={contact} scroll={true} />
        </div>
    )
}

export default ContactDisplay