// antd
import { CheckOutlined, DashboardOutlined, DollarOutlined, DownOutlined, GlobalOutlined, HomeOutlined, InfoCircleOutlined, LoginOutlined, LogoutOutlined, MenuOutlined, SearchOutlined, UsergroupAddOutlined, UserOutlined, } from '@ant-design/icons'
import { Col, Drawer, Dropdown, Menu, Row, Space } from 'antd'
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext';
// helpers
import { auth } from '../../helpers/firebase/firebase';
import styles from './Navbar.module.css'

const menu = (
    // language select Menu
    <Menu
        items={[
            {
                label: <p>TR</p>,
                key: "0",
                onClick: () => {
                    i18next.changeLanguage("tr");
                    //set auth language
                    auth.languageCode = "tr";
                    localStorage.setItem("i18nextLng", 'tr');
                    window.location.reload(false);
                },
            },
            {
                label: <p>EN</p>,
                key: "1",
                onClick: () => {
                    i18next.changeLanguage("en");
                    auth.languageCode = "en";
                    localStorage.setItem("i18nextLng", 'en')
                    window.location.reload(false);
                },
            },
        ]}
    />
);
// for mobil hamburger menu logo
const Logo = () => {
    return (
        <div className={styles.hamburgerImageContainer}>
            <img className={styles.hamburgerImage} src="/assets/images/frewell-logo.png" alt="frewell logo" />
        </div>
    )
}
// Navbar visibilty change in order to design props
const Navbar = ({ design }) => {
    //i18next for language changing
    const { t } = useTranslation(['navbar']);
    // Auth context
    const { currentUser, userDetails, logout } = useAuth();
    const [open, setOpen] = useState(false);
    //states
    const [language, setLanguage] = useState('EN')
    // react-router-dom
    const navigate = useNavigate()

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const handleLogout = () => {
        logout();
        navigate('/')
        setOpen(false)
    }
    useEffect(() => {
        setLanguage(localStorage.getItem('i18nextLng'))
    }, [])

    return (
        <Row id='navbarId' className={design ? styles.designedContainer : styles.headerContainer} justify='center' >
            <Row align='middle' justify='space-between' className={styles.innerContainer}>
                <Col xxl={4} lg={4} md={5} sm={5} xs={12}>
                    <Link to={'/'} className={styles.logoContainer}>
                        <img src="/assets/images/frewell-logo.png" alt="frewell logo" />
                    </Link>
                </Col>
                <Col xxl={13} xl={13} lg={13} md={0} sm={0} xs={0} align='middle' >
                    <Row align='middle' justify='space-between'>
                        <Link to={'/'} className={styles.headerPagination}>{t('navbar:home')}</Link>
                        <Link to={'/what-is-frewell'} className={styles.headerPagination}>{t("navbar:whatisfrewell")}</Link>
                        <Link to={'/frequencies'} className={styles.headerPagination}>{t("navbar:frequencies")}</Link>
                        <Link to={'/pricing'} className={styles.headerPagination}>{t("navbar:pricing")}</Link>
                        <Link to={'/help'} className={styles.headerPagination}>{t("navbar:help")}</Link>
                    </Row>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={0} sm={0} xs={0}>
                    <Row justify="end" align='middle' gutter={1} span={24} wrap={false}>
                        {
                            currentUser ? (
                                <Row align='middle' wrap={false}>
                                    <Link to={'/profile'} className={styles.userName}>{`${userDetails.name} ${userDetails.surname}`}</Link>
                                    <Link to={'/app'} className={styles.appButton}>{t("navbar:gotoapp")}</Link>
                                </Row>
                            ) : (
                                <>
                                    <Link to={'/login'} className={styles.loginButton}>{t("navbar:login")}</Link>
                                    <Link to={'/register'} className={styles.registerButton}>{t("navbar:register")}</Link>
                                </>
                            )
                        }

                        <Dropdown overlay={menu} trigger={["click"]}>
                            <div
                                onClick={(e) => e.preventDefault()}
                                className={styles.languageChoices}
                            >
                                <Space>
                                    <GlobalOutlined />
                                    <p className={styles.language}>{language}</p>
                                    <DownOutlined />
                                </Space>
                            </div>
                        </Dropdown>
                    </Row>
                </Col>
                <Col xxl={0} lg={0} md={17} sm={17} xs={9} align='end' >
                    {
                        currentUser && <Link to={'/profile'} className={styles.headerPaginationSmallName}>{`${userDetails.name} ${userDetails.surname}`}</Link>
                    }
                </Col>
                <Col xxl={0} lg={0} md={2} sm={2} xs={3}>
                    <Row justify='end' >
                        <MenuOutlined onClick={showDrawer} className={styles.menuIcon} />
                    </Row>
                    <Drawer
                        title={<Logo />}
                        placement='right'
                        closable={true}
                        onClose={onClose}
                        open={open}
                        maskClosable={true}
                        bodyStyle={{ backgroundColor: '#f5f5f5' }}
                    >
                        <Row>
                            <Link to={'/'} className={styles.headerPaginationSmall} onClick={onClose}><HomeOutlined /> {t('navbar:home')}</Link>
                        </Row>
                        <Row>
                            <Link to={'/what-is-frewell'} className={styles.headerPaginationSmall} onClick={onClose}><SearchOutlined /> {t("navbar:whatisfrewell")}</Link>
                        </Row>
                        <Row>
                            <Link to={'/frequencies'} className={styles.headerPaginationSmall} onClick={onClose}><CheckOutlined /> {t("navbar:frequencies")}</Link>
                        </Row>
                        <Row>
                            <Link to={'/pricing'} className={styles.headerPaginationSmall} onClick={onClose}><DollarOutlined /> {t("navbar:pricing")}</Link>
                        </Row>
                        <Row>
                            <Link to={'/help'} className={styles.headerPaginationSmall} onClick={onClose}><InfoCircleOutlined /> {t("navbar:help")}</Link>
                        </Row>
                        {
                            currentUser ? (
                                <>
                                    <Row align='middle' wrap={false}>
                                        <Link to={'/app'} className={styles.headerPaginationSmall} onClick={onClose}><DashboardOutlined /> {t("navbar:gotoapp")}</Link>
                                    </Row>
                                    <Row>
                                        <Link to={'/profile'} className={styles.headerPaginationSmall} onClick={onClose}><UserOutlined /> {t("navbar:profile")}</Link>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        <Link to={'/login'} className={styles.headerPaginationSmall} onClick={onClose}><LoginOutlined /> {t("navbar:login")}</Link>
                                    </Row>
                                    <Row>
                                        <Link to={'/register'} className={styles.headerPaginationSmall} onClick={onClose}><UsergroupAddOutlined /> {t("navbar:register")}</Link>
                                    </Row>
                                </>
                            )
                        }
                        {currentUser && <Row align='middle' wrap={false} onClick={handleLogout}>
                            <p className={styles.headerPaginationSmallSignout}>
                                <LogoutOutlined /> {t('logout', { ns: "app" })}
                            </p>
                        </Row>}
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <div
                                onClick={(e) => e.preventDefault()}
                                className={styles.languageChoices}
                            >
                                <Space>
                                    <GlobalOutlined />
                                    <p className={styles.language}>{language}</p>
                                    <DownOutlined />
                                </Space>
                            </div>
                        </Dropdown>

                    </Drawer>
                </Col>
            </Row>
        </Row>
    )
}

export default Navbar