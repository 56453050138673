import { Col, Row } from 'antd'
import styles from './RemaninigFrequncies.module.css'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getRemainingCredit } from '../../helpers/firebase/firebase_package_methods'
import useNotification from '../../hooks/useNotification'
import { getAlertMessage } from '../../helpers/utils/AlertUtils'
import { useTranslation } from 'react-i18next'

const RemaninigFrequncies = () => {
    //it is used for changing language
    const { t } = useTranslation(['app', 'record']);
    // state
    const [remainingCredit, setRemainingCredit] = useState(0);
    //hooks
    const { alertError } = useNotification();
    useEffect(() => {
        // get remaining credit
        getRemainingCredit()
            .then((res) => {
                // set state in order to response
                setRemainingCredit(res);
            })
            .catch((err) => {
                let alertMessage = getAlertMessage(err.code);
                alertError(t(alertMessage, { ns: 'alerts' }));
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Row span={24} align='middle' justify='space-between'>
            <Col span={8}>
                <p className={remainingCredit === 0 ? styles.remainingUsageZero : styles.remainingUsage}>
                    {remainingCredit} {t('frequency', { ns: "app" })}
                </p>
            </Col>
            <Col span={14}>
                <p className={styles.buyButton}>
                    <Link to={'/pricing'}>
                        {t('buy_credit', { ns: "app" })}
                    </Link>
                </p>
            </Col>
        </Row>
    )
}

export default RemaninigFrequncies