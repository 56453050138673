// antd
import { Col, Row } from 'antd'
// i18next
import { useTranslation } from 'react-i18next'
// components
import CommentCardContainer from '../CommentCardContainer/CommentCardContainer'
// style
import styles from './UserComment.module.css'

const UserComment = () => {
    //i18next for language changing
    const { t } = useTranslation(['user_comment']);
    return (
        <Row id='userCommentContainerId' className={styles.container} >
            <Col className={styles.titleContainer} span={24} align='middle'>
                <h1 className={styles.commentTitle}><span className={styles.user}>{t("user_comment:user")}</span> {t("user_comment:comment")}</h1>
                <p className={styles.commentDescription}>{t("user_comment:comment_description")}</p>
            </Col>
            <Row justify='center' className={styles.carouselRow}>
                <CommentCardContainer />
            </Row>
        </Row>
    )
}

export default UserComment