import Footer from '../components/Footer/Footer'
import LoginComponent from '../components/LoginComponent/LoginComponent'

const Login = () => {
    return (
        <>
            <LoginComponent />
            <Footer />
        </>
    )
}

export default Login