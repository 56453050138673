import Footer from '../components/Footer/Footer'
import WhatIsFrewellComponent from '../components/WhatIsFrewellComponent/WhatIsFrewellComponent'

const WhatIsFrewell = () => {
    return (
        <>
            <WhatIsFrewellComponent />
            <Footer />
        </>
    )
}

export default WhatIsFrewell