import Footer from '../components/Footer/Footer'
import PricingComponent from '../components/PricingComponent/PricingComponent'

const Pricing = () => {
    return (
        <>
            <PricingComponent />
            <Footer />
        </>
    )
}

export default Pricing