import Footer from '../components/Footer/Footer'
import HelpComponent from '../components/HelpComponent/HelpComponent'

const Help = () => {
    return (
        <>
            <HelpComponent />
            <Footer />
        </>
    )
}

export default Help