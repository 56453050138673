// antd
import { Col, Row } from 'antd'
// import { LoadingOutlined } from '@ant-design/icons'
// components
import styles from './PricingComponent.module.css'
import Navbar from '../Navbar/Navbar'
import News from '../News/News'
import TotalUserFeedback from '../TotalUserFeedback/TotalUserFeedback'
// helpers
// import { checkOut } from '../../helpers/firebase/firebase_package_methods'
// import { isUserVerified } from '../../helpers/firebase/firebase_user_methods'
// import { auth } from '../../helpers/firebase/firebase'
// // hook
// import useNotification from '../../hooks/useNotification'
// // react
// import { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// i18next
import { useTranslation } from 'react-i18next'

const PricingComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['pricing', 'alerts']);
    // states
    // const [loadingFirst, setLoadingFirst] = useState(false)
    // const [loadingSecond, setLoadingSecond] = useState(false)
    // const [loadingThirth, setLoadingThirth] = useState(false)
    // react-router-dom
    // const navigate = useNavigate()
    // hook
    // const { alertError } = useNotification();

    // navigate to stripe checkout page with product id
    // const navigateToStripe = async (priceId) => {
    //     if (auth.currentUser === null) {
    //         navigate('/login');
    //     } else if (await isUserVerified()) {
    //         //pass price data to payment page
    //         checkOut(priceId)
    //     } else {
    //         alertError(t('verify-email', { ns: 'alerts' }));
    //         navigate('/')
    //     }
    // }
    return (
        <>
            <Navbar design={true} />
            <Row className={styles.pricingContainer}>
                <h1 className={styles.pricingHeader}>{t('pricing:pricing')}</h1>
            </Row>
            <Row className={styles.cardContainerRow} align='middle'>
                <Col className={styles.cardContainerColumn} span={24} justify='center' align='middle'>
                    <h1 className={styles.title}>{t('pricing:constant_price')}</h1>
                    {/* <p className={styles.description}>{t('pricing:promo_value_description')}</p> */}
                    <Row className={styles.pricingRow} justify='center' align='middle' wrap={true}>
                        {/* {
                            pricingPackage.map((pricingCard, index) => {
                                return (
                                    pricingCard.popular ? (
                                        <Col className={styles.popularCard} key={index} xxl={5} xl={5} lg={5} md={20} sm={22} xs={22} justify='center'>
                                            <p className={styles.firstTitle}>{t('pricing:popular')}</p>
                                            <p className={styles.frequncyCount}>
                                                <b>{pricingCard.credit_value} {t('pricing:frequency')}</b></p>
                                            <p className={styles.frequncyPrice}>${pricingCard.price}</p>
                                            <p className={styles.usageDate}><b>{t('pricing:date')}: {pricingCard.date} {t('pricing:' + pricingCard.date_text)}</b></p>
                                            {localStorage.getItem('language') === 'tr' ? <p className={styles.descriptionFirst}>Dilediğin <b>{pricingCard.credit_value} Adet </b> Frekansta Geçerli
                                            </p> :
                                                <p className={styles.descriptionFirst}> Valid at <b>{pricingCard.credit_value}</b> Frequencies You Wish</p>}
                                            <p className={styles.descriptionFirst}><b>{pricingCard.credit_value} </b> {t('pricing:promo_description')}</p>
                                            <p className={styles.descriptionSecond}>{pricingCard?.description}</p>
                                            <p className={styles.tax}>{t('pricing:tax')}</p>
                                            <button className={styles.popularCardButton} onClick={() => navigateToPayment(pricingCard)}>{t('pricing:buy')}</button>
                                        </Col>
                                    ) : (
                                        <Col className={styles.regularCard} key={index} xxl={5} xl={5} lg={5} md={20} sm={22} xs={22} justify='center'>
                                            {pricingCard.credit_value === 1 ?
                                                <p className={styles.regularCardFrequncyCount}>{t('pricing:one')} {t('pricing:frequency')}</p>
                                                :
                                                <p className={styles.regularCardFrequncyCount}>{pricingCard.credit_value} {t('pricing:frequency')}</p>}
                                            <p className={styles.regularCardFrequncyPrice}>${pricingCard.price}</p>
                                            <p className={styles.regularCardUsageDate}><b>{t('pricing:date')}: {pricingCard.date} {t('pricing:' + pricingCard.date_text)}</b></p>
                                            {localStorage.getItem('language') === 'tr' ? <p className={styles.regularCardDescriptionFirst}>Dilediğin <b>{pricingCard.credit_value} Adet </b> Frekansta Geçerli
                                            </p> :
                                                <p className={styles.regularCardDescriptionFirst}> Valid at <b>{pricingCard.credit_value}</b> Frequencies You Wish</p>}
                                            <p className={styles.regularCardDescriptionFirst}><b>{pricingCard.credit_value}</b> {t('pricing:promo_description')}</p>
                                            <p className={styles.regularCardTax}>{t('pricing:tax')}</p>
                                            <button className={styles.regularCardButton} onClick={() => navigateToPayment(pricingCard)}>{t('pricing:buy')}</button>
                                        </Col>
                                    )
                                )
                            }
                            )
                        } */}
                        {/* <Col xxl={5} xl={6} lg={8} md={8} sm={15} xs={18} >
                            <Col className={styles.regularCard} xxl={24} xl={24} lg={23} md={23} sm={23} xs={23}
                                key='price_1M9oZ9GjFDbtMan9SNpEfaDZ'
                                justify='center'>
                                <p className={styles.regularCardFrequncyCount}>{t('pricing:one')} {t('pricing:frequency')}</p>
                                <p className={styles.regularCardFrequncyPrice}>$1</p>
                                <p className={styles.regularCardUsageDate}>
                                    <b>{t('pricing:date')}: 3 {t('pricing:day')}</b>
                                </p>
                                {localStorage.getItem('i18nextLng') === 'tr' ?
                                    <p className={styles.regularCardDescriptionFirst}>
                                        Dilediğin <b>1 Adet </b> Frekansta Geçerli
                                    </p> :
                                    <p className={styles.regularCardDescriptionFirst}> Valid at <b>1</b> Frequencies You Wish</p>}
                                <p className={styles.regularCardTax}>{t('pricing:tax')}</p>
                                <button className={styles.regularCardButton}
                                    onClick={() => {
                                        setLoadingFirst(true);
                                        navigateToStripe('price_1M9oZ9GjFDbtMan9SNpEfaDZ')
                                    }}
                                >
                                    {loadingFirst ? <LoadingOutlined /> : `${t('pricing:buy')}`}
                                </button>
                            </Col>
                        </Col> */}
                        <Col xxl={5} xl={6} lg={8} md={8} sm={15} xs={18}>
                            <Col className={styles.popularCard}
                                key='price_1M9oaSGjFDbtMan97rGlQaWP'
                                xxl={24} xl={24} lg={23} md={23} sm={23} xs={23} justify='center'>
                                <p className={styles.firstTitle}>{t('pricing:popular')}</p>
                                <p className={styles.frequncyCount}>
                                    <b>20 {t('pricing:frequency')}</b></p>
                                <p className={styles.frequncyPrice}>$20</p>
                                <p className={styles.usageDate}><b>{t('pricing:date')}: 6 {t('pricing:month')}</b></p>
                                {/* <p className={styles.popularPromoPlus}><PlusOutlined /></p> */}
                                {localStorage.getItem('i18nextLng') === 'tr' ? <p className={styles.descriptionFirst}>Dilediğin <b>20 Adet </b> Frekansta Geçerli
                                </p> :
                                    <p className={styles.descriptionFirst}> Valid at <b>20</b> Frequencies You Wish</p>}
                                {/* {
                                    localStorage.getItem('i18nextLng') === 'tr' ?
                                        <p className={styles.descriptionFirst}><b>20 </b> Adet Hediye <b> <span className={styles.pricingPromo}>Promosyon Kodu</span> </b></p>
                                        :
                                        <p className={styles.descriptionFirst}><b>20 </b> Gift <span className={styles.pricingPromo}>Promotion Code</span> </p>
                                } */}

                                <p className={styles.tax}>{t('pricing:tax')}</p>
                                {/* <button className={styles.popularCardButton}
                                    onClick={() => {
                                        setLoadingSecond(true);
                                        navigateToStripe('price_1M9oaSGjFDbtMan97rGlQaWP')
                                    }}
                                >
                                    {loadingSecond ? <LoadingOutlined /> : `${t('pricing:buy')}`}
                                </button> */}
                            </Col>
                        </Col>
                        <Col xxl={5} xl={6} lg={8} md={8} sm={15} xs={18}>
                            <Col className={styles.regularCard}
                                key='price_1M9oZrGjFDbtMan95kA7IulM'
                                xxl={24} xl={24} lg={23} md={23} sm={23} xs={23} justify='center'>
                                <p className={styles.regularCardFrequncyCount}>5 {t('pricing:frequency')}</p>
                                <p className={styles.regularCardFrequncyPrice}>$5</p>
                                <p className={styles.regularCardUsageDate}>
                                    <b>{t('pricing:date')}: 1 {t('pricing:month')}</b>
                                </p>
                                {/* <p className={styles.promoPlus}><PlusOutlined /></p> */}
                                {localStorage.getItem('i18nextLng') === 'tr' ? <p className={styles.regularCardDescriptionFirst}>Dilediğin <b>5 Adet </b> Frekansta Geçerli
                                </p> :
                                    <p className={styles.regularCardDescriptionFirst}> Valid at <b>5</b> Frequencies You Wish</p>}
                                {/* <p className={styles.regularCardDescriptionFirst}><b>5</b> {t('pricing:promo_description')}</p> */}
                                <p className={styles.regularCardTax}>{t('pricing:tax')}</p>
                                {/* <button className={styles.regularCardButton}
                                    onClick={() => {
                                        setLoadingThirth(true)
                                        navigateToStripe('price_1M9oZrGjFDbtMan95kA7IulM')
                                    }}
                                >
                                    {loadingThirth ? <LoadingOutlined /> : `${t('pricing:buy')}`}
                                </button> */}
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Row >
            <TotalUserFeedback />
            <News />
        </>
    )
}

export default PricingComponent