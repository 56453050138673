// antd
import { Form, Select } from "antd";
// json
import trCities from "../../trCities.json";
// style
import styles from './CityDropdown.module.css'
// antd
const { Option } = Select;

// handleCityChange function came from register component or userKnowledge component
const CityDropdown = ({ handleCityChange, city }) => {
    return (
        <Form.Item
            name="city"
            rules={[
                {
                    max: 15,
                    required: 'Lütfen şehir seçiniz!',
                },
            ]}
        >
            <Select
                size="large"
                className={styles.cityDropdown}
                showSearch
                onChange={handleCityChange}
                placeholder="Şehir seçin!"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                }
            >
                {trCities.map((state, key) => (
                    <Option key={key} value={state.sehir} size='large'>
                        {state.sehir}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default CityDropdown;
