// antd
import { Col, Collapse, Row } from 'antd'
// components
import Navbar from '../Navbar/Navbar'
//i18next
import { useTranslation } from 'react-i18next';
// style
import styles from './HelpComponent.module.css'

// antd
const { Panel } = Collapse;

const HelpComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['help']);
    return (
        <>
            <Navbar design={true} />
            <Col span={24} className={styles.helpContainer}>
                <Row className={styles.headerContainer}>
                    <h1 className={styles.header}>{t('help:faq')}</h1>
                </Row>
                <Row className={styles.questionRow} align='middle' justify='center'>
                    <Col xxl={14} xl={14} lg={14} md={22} sm={22} xs={22}>
                        <Collapse
                            className={styles.collapse}
                            expandIconPosition='end'
                            bordered={false}
                            ghost={true}>
                            <Panel className={styles.panel} header={t('help:title_01')} key="1">
                                <p className={styles.answer}>{t('help:description_01_01')}</p>
                                <p className={styles.answer}>{t('help:description_01_02')}</p>
                            </Panel>
                            <Panel className={styles.panel} header={t('help:title_02')} key="2">
                                <p className={styles.answer}>{t('help:description_02_01')}</p>
                            </Panel>
                            <Panel className={styles.panel} header={t('help:title_03')} key="3">
                                <p className={styles.answer}>{t('help:description_03_01')}</p>
                                <p className={styles.answer}>
                                    {t('help:description_03_02')}
                                </p>
                                <p className={styles.answer}>
                                    {t('help:description_03_03')}
                                </p>
                            </Panel>
                            <Panel className={styles.panel} header={t('help:title_04')} key="4">
                                <p className={styles.answer}>{t('help:description_04_01')}</p>
                            </Panel>
                            {/* <Panel className={styles.panel} header={t('help:title_05')} key="5">
                                <p className={styles.answer}>{t('help:description_05_01')}</p>
                                <p className={styles.answer}>{t('help:description_05_02')}</p>
                            </Panel>
                            <Panel className={styles.panel} header={t('help:title_06')} key="6">
                                <p className={styles.answer}>{t('help:description_06_01')}</p>
                            </Panel>
                            <Panel className={styles.panel} header={t('help:title_07')} key="7">
                                <p className={styles.answer}>{t('help:description_07_01')}</p>
                                <p className={styles.answer}>{t('help:description_07_02')}</p>
                            </Panel> */}
                            <Panel className={styles.panel} header={t('help:title_08')} key="8">
                                <p className={styles.answer}>{t('help:description_08_01')}</p>
                            </Panel>
                            <Panel className={styles.panel} header={t('help:title_09')} key="9">
                                <p className={styles.answer}>{t('help:description_09_01')}</p>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default HelpComponent