// i18next
import { useTranslation } from "react-i18next";
// react-cookies
import CookieConsent from "react-cookie-consent";
// style
import styles from './CookiesComponent.module.css'

const CookiesComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['app']);
    return (
        <CookieConsent
            buttonText={t('accept_cookies', { ns: 'app' })}
            expires={365}
            containerClasses={styles.cookieContainer}
            buttonClasses={styles.cookiesButton}
            contentClasses={styles.content}
        >
            {
                localStorage.getItem('i18nextLng') === 'tr' ? <div>
                    Sitemizde; siteyi kullanımınızı analiz etmek, ziyaret tercihlerinizi hatırlamak, site kullanım istatistiklerini raporlamak için çerezler kullanılmaktadır. Çerezler hakkında detaylı bilgi almak için <a href='/privacy-policy'> Çerez (Cookie) Politikası’nı</a> incelemenizi rica ederiz.
                </div> : <div>
                    We use cookies to analyze site usage, remember your visit preferences, and report site usage statistics. For more information about cookies, please read our <a href='/privacy-policy'>Cookie Policy</a>.
                </div>
            }

        </CookieConsent>
    )
}

export default CookiesComponent