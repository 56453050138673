// antd
import { LoadingOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
// components
import Navbar from '../Navbar/Navbar'
// helpers
import { contactMessage } from '../../helpers/firebase/firebase_user_methods'
import { getAlertMessage } from '../../helpers/utils/AlertUtils'
// hooks
import useNotification from '../../hooks/useNotification'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { useState } from 'react'
import { useRef } from 'react'
// style
import styles from './ContactComponent.module.css'

const Contact = () => {
    //i18next for language changing
    const { t } = useTranslation(['help', 'alerts', 'requires', 'navbar']);
    // hook
    const { alertError, alertSuccess } = useNotification();
    // ref
    const form = useRef();
    // states
    const [help, setHelp] = useState({
        userName: "",
        userSurname: "",
        userEmail: "",
        userPhone: "",
        userMessage: "",
    })
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        // this function set info to contact table
        contactMessage(help)
            .then((res) => {
                if (res) {
                    alertSuccess(t('succesfull_contact', { ns: 'alerts' }));
                    form.current.reset();
                    setLoading(false)
                } else {
                    let errorMessage = getAlertMessage(res);
                    alertError(t(errorMessage, { ns: 'alerts' }));
                    setLoading(false)
                }
            })
    }

    return (
        <Row>
            <Navbar design={true} />
            <h1 className={styles.contactHeader}> {t('navbar:contactHeader')} {t('navbar:contact')} </h1>
            <Row className={styles.contactFormContainer} justify='center'>
                <Col className={styles.contactForm} xxl={14} xl={14} lg={14} md={22} sm={22} xs={22}>
                    <h1 className={styles.formHeader}>{t('help:suggestion')}</h1>
                    <form onSubmit={handleSubmit} ref={form}>
                        <Row className={styles.firstInputsContainer} align='middle' justify='space-between'>
                            <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24} className={styles.littleInputContainer} justify='center'>
                                <UserOutlined />
                                <input
                                    className={styles.littleInput}
                                    type="text"
                                    placeholder={t('name', { ns: 'requires' })}
                                    rules={
                                        [
                                            {
                                                required: true,
                                                message: t('please_enter_name', { ns: 'requires' })
                                            },

                                        ]
                                    }
                                    onChange={(e) => { setHelp({ ...help, userName: e.target.value.trim() }) }}
                                />
                            </Col>
                            <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24} className={styles.littleInputContainer}>
                                <UserOutlined />
                                <input
                                    className={styles.littleInput}
                                    type="text"
                                    placeholder={t('surname', { ns: 'requires' })}
                                    rules={
                                        [
                                            {
                                                required: true,
                                                message: t('please_enter_sur_name', { ns: 'requires' })
                                            },

                                        ]
                                    }
                                    onChange={(e) => { setHelp({ ...help, userSurname: e.target.value.trim() }) }}
                                />
                            </Col>
                        </Row>
                        <Row className={styles.secondInputsContainer} align='middle' justify='space-between'>
                            <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24} className={styles.littleInputContainer} justify='center'>
                                <MailOutlined />
                                <input
                                    className={styles.littleInput}
                                    type='email'
                                    placeholder={t('help:email')}
                                    rules={
                                        [
                                            {
                                                required: true,
                                                message: t('please_enter_email', { ns: 'requires' })
                                            },

                                        ]
                                    }
                                    onChange={(e) => { setHelp({ ...help, userEmail: e.target.value.trim() }) }}
                                />
                            </Col>
                            <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24} className={styles.littleInputContainer}>
                                <PhoneOutlined />
                                <input
                                    className={styles.littleInput}
                                    type="text"
                                    placeholder={t('help:phone')}
                                    rules={
                                        [
                                            {
                                                required: true,
                                                message: t('please_enter_phone_number', { ns: 'requires' })
                                            },
                                        ]
                                    }
                                    onChange={(e) => { setHelp({ ...help, userPhone: e.target.value.trim() }) }}
                                />
                            </Col>
                        </Row>
                        <Row className={styles.textareaContainer}>
                            <Col span={24} className={styles.textareaColumnContainer}>
                                <TextArea
                                    size='large'
                                    className={styles.textareaInput}
                                    bordered={false}
                                    showCount
                                    maxLength={300}
                                    placeholder={t('help:message')}
                                    allowClear={true}
                                    autoSize={{ minRows: 4, maxRows: 6 }}
                                    required
                                    onChange={(e) => { setHelp({ ...help, userMessage: e.target.value.trim() }) }}
                                />
                            </Col>
                        </Row>
                        <Row className={styles.buttonContainer} align='middle' justify='end'>
                            {
                                loading ?
                                    <button className={styles.helpButton} type="submit" disabled><LoadingOutlined /></button>
                                    :
                                    <button className={styles.helpButton} type="submit">{t('help:submit')}</button>
                            }

                        </Row>
                    </form>
                </Col>
                <Col className={styles.contactKnowledge} xxl={14} xl={14} lg={14} md={22} sm={22} xs={22}>
                    <h1 className={styles.formHeader}>{t('help:contact_us')}</h1>
                    {/* <Row>
                        <Col xxl={3} xl={3} lg={3} md={5} sm={10} xs={5} className={styles.label}>
                            {t('help:phoneNumber')}:
                        </Col>
                        <Col xxl={14} xl={14} lg={14} md={14} sm={14} xs={14} className={styles.response}>+14253262814</Col>
                    </Row> */}
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={5} sm={10} xs={5} className={styles.label}>
                            {t('help:emailAddress')}:
                        </Col>
                        <Col xxl={14} xl={14} lg={14} md={14} sm={14} xs={14} className={styles.response}>
                            info@frewell.com
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={5} sm={10} xs={5} className={styles.label}>
                            {t('help:address')}:
                        </Col>
                        <Col xxl={14} xl={14} lg={14} md={14} sm={14} xs={14} className={styles.response}>
                            <address>
                                {t('help:folcomedAddress')}
                            </address>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Row>
    )
}

export default Contact