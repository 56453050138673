// antd
import { Col, Row } from 'antd'
// context
import { useAuth } from '../../contexts/AuthContext';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { Link } from 'react-router-dom'
// style
import styles from './WelcomeTitle.module.css'

const WelcomeTitle = () => {
    //i18next for language changing
    const { t } = useTranslation(['worksteps', 'alerts']);
    // auth context
    const { currentUser } = useAuth();
    return (
        <Row className={styles.welcomeContainer} align='middle' justify="center" >
            <Col xxl={10} lg={10} md={24} sm={24} xs={24} className={styles.container} justify='center'>
                {
                    localStorage.getItem('i18nextLng') === 'tr' ?
                        <div className={styles.mottoContainer}>
                            Fre<b><span className={styles.highlight}>Well</span></b> ile <b><span className={styles.highlight}>Kuantuma</span></b> Sesinizle Bağlanın ve İstediğiniz Frekanslara <b> <span className={styles.highlight}>Uyumlanın!</span></b>
                        </div> :
                        <div className={styles.mottoContainer}>
                            Connect to <b><span className={styles.highlight}>Quantum</span></b> with your Voice and Tune into the Frequencies You Want with <b><span className={styles.highlight}>FreWell!</span></b>
                        </div>
                }
                {
                    localStorage.getItem('i18nextLng') === 'tr' ?
                        <div className={styles.description}>
                            <b>Fre<span className={styles.highlight}>Well</span> ,</b> kuantum frekanslarına sesinizle bağlanmanızı ve istediğiniz <b>frekanslara uyumlanmanızı</b> sağlar. Dilediğiniz zaman, dilediğiniz yerde…
                        </div> :
                        <div className={styles.description}>
                            <b>FreWell</b> allows you to connect to quantum frequencies with your voice and <b>tune in to the frequencies</b> you want. Whenever you want, wherever you want…
                        </div>
                }

                <div className={styles.buttonContainer}>
                    {
                        currentUser ?
                            <>
                                <Link to={'/frequencies'} className={styles.frequencyButton}>{t("worksteps:frequencies")}</Link>
                                <Link to={'/app'} className={styles.userFrequencyButton}>{t("worksteps:tryNow")}</Link>
                            </>
                            :
                            <>
                                <Link to={'/frequencies'} className={styles.frequencyButton}>{t("worksteps:frequencies")}</Link>
                                <Link to={'/register'} className={styles.registerButton}>{t("worksteps:register")}</Link>
                                <Link to={'/login'} className={styles.loginButton}>{t("worksteps:login")}</Link>
                            </>
                    }

                </div>
            </Col>
            <Col xxl={10} lg={10} md={24} sm={24} xs={24} className={styles.humanContainer}>
                <img
                    className={styles.heroImage}
                    src={"/assets/images/frewell-hero-last-ps.gif" || '/assets/images/hero-img.png'}
                    alt="frekans wave"
                />
                <img
                    className={styles.waveImage}
                    src="/assets/images/wave.png"
                    alt="frekans wave"
                />
            </Col>
        </Row>
    )
}

export default WelcomeTitle