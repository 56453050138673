// antd
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";
// i18next
import { useTranslation } from "react-i18next";


const useNotification = () => {
  //i18next for language changing
  const { t } = useTranslation(["requires"]);
  // antd
  const alertError = (msg) => {
    notification.config({
      placement: "topRight",
      duration: 5,
      style: {
        height: "5vw",
      },
      closeIcon: (
        <CloseCircleOutlined style={{ color: "red", fontSize: "3vh" }} />
      ),
    });
    notification.open({
      key: "unupdatable",
      message: t("error_alert", { ns: "requires" }),
      description: msg,
    });
  };

  const alertSuccess = (msg) => {
    notification.config({
      placement: "topRight",
      duration: 3.5,
      closeIcon: (
        <CheckCircleOutlined style={{ color: "green", fontSize: "3vh" }} />
      ),
    });
    notification.open({
      key: "unupdatable",
      message: t("error_success", { ns: "requires" }),
      description: msg,
      style: {
        marginTop: "4vh",
      },
    });
  };

  return {
    alertError,
    alertSuccess,
  };
};
export default useNotification;
