// antd
import { Button, Col, Form, Modal, Rate, Row, Table } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
// helpers
import { madeNotAvailableForFeedback, uploadFeedback } from '../../helpers/firebase/firebase_feedback_methods';
import { getUserVoices } from '../../helpers/firebase/firebase_user_methods';
import { auth } from '../../helpers/firebase/firebase';
import { getAlertMessage } from '../../helpers/utils/AlertUtils';
// hook
import useNotification from '../../hooks/useNotification';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// style
import styles from './MyRecord.module.css'
import { LoadingOutlined } from '@ant-design/icons';

// comment description
const desc = ["Terrible", "Bad", "Normal", "Good", "Wonderful"];

const MyRecord = () => {
    //i18next for language changing
    const { t } = useTranslation(['app', 'record', 'alerts']);
    // states
    const [data, setData] = useState([]);
    const [actionId, setActionId] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newFeedback, setnewFeedback] = useState({
        feedback_text: "",
        value: "",
        disease_category: "",
        disease_name: "",
        uid: "",
    });
    const [loading, setLoading] = useState(false)
    // react-router-dom
    const navigate = useNavigate();
    // hook
    const { alertSuccess, alertError } = useNotification();
    // star change function
    const handleStarChange = (value) => {
        setnewFeedback({ ...newFeedback, value: value });
    };
    // get text and set state
    const handleTextChange = (e) => {
        setnewFeedback({ ...newFeedback, feedback_text: e.target.value });
    };

    const handleFeedback = async () => {
        setLoading(true)
        try {
            await uploadFeedback(newFeedback);
            await madeNotAvailableForFeedback(actionId).then(() => {
                alertSuccess(t('succesfull_feedback', { ns: 'alerts' }));
                setIsModalOpen(false);
                navigate('/');
            });
        } catch (err) {
            let errorMessage = getAlertMessage(err.code);
            alertError(t(errorMessage, { ns: 'alerts' }));
            setLoading(false)
        }
    };
    // antd table columns
    const columns = [
        {
            title: t('frequency', { ns: 'app' }),
            key: "disease_name",
            dataIndex: "disease_name",
        },
        {
            title: t('date', { ns: 'app' }),
            key: "created_at",
            dataIndex: "created_at",
        },
        {
            title: t('situation', { ns: 'app' }),
            key: "is_listened",
            dataIndex: "is_listened",
            render: (text, record) => {
                return (
                    <Row wrap={false} align='middle'>
                        {
                            record.is_listened
                                ? t('listened', { ns: 'app' })
                                :
                                <>
                                    <Row className={styles.situationDescription}>
                                        {t('on_process', { ns: 'app' })}
                                    </Row>
                                    <img className={styles.loadingWave} src="/assets/images/Loading3.gif" alt="loading" />
                                </>
                        }
                    </Row>);
            },
        },
        {
            title: t('feedback', { ns: 'app' }),
            key: "action",
            dataIndex: "action_id",
            render: (text, record) => (
                <div>
                    <Button
                        type='primary'
                        className={styles.feedbackButton}
                        disabled={!record.is_listened || !record.is_available_feedback}
                        onClick={() => {
                            setActionId(record.action_id);
                            showModal(record);
                        }}
                    >
                        {t('feedback', { ns: 'app' })}
                    </Button>
                </div>
            ),
        },
        {
            title: t("delete_date", { ns: "app" }),
            key: "delete_date",
            dataIndex: "delete_date",
            render: (text, record) => {
                return <Row className={styles.situationDescription}>{record?.delete_date === "0" || record?.delete_date === null ? t('on_process', { ns: 'app' }) : record?.delete_date}</Row>;
            }
        }
    ];
    useEffect(() => {
        try {
            getUserVoices().then((res) => {
                // console.log(res)
                let data = res.map((item) => {
                    return {
                        ...item,
                        disease_name: item.disease_name === "Custom Frequency" ? t("custom_freq", { ns: 'app' }) : t(item.disease_name, { ns: 'record' }),
                    }
                });
                // console.log(data)
                setData(data);
            })
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showModal = (record) => {
        setnewFeedback({
            ...newFeedback,
            feedback_text: "",
            value: "",
            disease_category: record.category_name,
            disease_name: record.disease_name,
            uid: auth.currentUser.uid,
        });
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <Col className={styles.myRecordContainer} wrap={false}>
            <Row className={styles.description}>
                {t('voice_delete_warning', { ns: 'app' })}
            </Row>
            <Table
                className={styles.recordTable}
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.action_id}
                size='small'
            />
            <Modal
                style={{ textAlign: 'center' }}
                title={t('feedback', { ns: 'app' })}
                open={isModalOpen}
                footer={null}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form className={styles.formContainer} onFinish={handleFeedback}>
                    <div className={styles.Rate}>
                        <Rate tooltips={desc} onChange={handleStarChange} />
                    </div>
                    <TextArea
                        onChange={handleTextChange}
                        className={styles.Textarea}
                        showCount
                        maxLength={300}
                    />
                    <Row className={styles.buttonRow} justify='end' span={24}>
                        <button className={styles.modalButton} type="submit">
                            {loading ? <LoadingOutlined /> : t('send', { ns: 'app' })}
                        </button>
                    </Row>
                </Form>
            </Modal>
        </Col>
    )
}

export default MyRecord