import { Button, Input, Table } from "antd";
import { useState } from "react";
import { updateExpirationDateOfUser, updateRemainingCreditOfUser } from "../../helpers/firebase/firebase_admin_methods";
import styles from "./statistic_style.module.css"
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { getUserWithEmail, getUserWithNameAndSurname } from "../../helpers/firebase/firebase_user_methods";
import useNotification from "../../hooks/useNotification";

const GivePromoTable = () => {

    const [data, setData] = useState([]);
    const dataSource = data;
    const { alertSuccess } = useNotification();
    const onEmailSearch = async () => {
        //get value of id emailSearch
        var email = document.getElementById("emailSearch").value;
        await getUserWithEmail(email).then((user) => {
            if (user) {
                setData(user);
            }
        });
    };
    const onNameSearch = async () => {
        var name = document.getElementById("nameSearch").value;
        var surname = document.getElementById("surnameSearch").value;
        await getUserWithNameAndSurname(name,surname).then((users)=>{
            if(users){
                setData(users);
            }
        })
    }
    const columns = [
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: "Son Kullanma Tarihi",
            dataIndex: 'expiration_date',
            key: 'expiration_date',
            render: (text, record) => {
                return <div>
                    <p>{new Date(text).toLocaleDateString()}</p>
                    <Button onClick={async () => {
                        setData(data.map((item) => {
                            if (item.uid === record.uid) {
                                if(item.expiration_date === "Invalid Date" || item.expiration_date === null){
                                    item.expiration_date = Date.now()
                                }
                                item.expiration_date = new Date(new Date(item.expiration_date).getTime() + 86400000);
                            }
                            return item;
                        }))
                    }}> <CaretUpOutlined /></Button>
                    <Button onClick={async () => {
                        setData(data.map((item) => {
                            if (item.uid === record.uid) {
                                if(item.expiration_date === "Invalid Date" || item.expiration_date === null){
                                    item.expiration_date = Date.now()
                                }
                                item.expiration_date = new Date(new Date(item.expiration_date).getTime() - 86400000);
                            }
                            return item;
                        }))
                    }}> <CaretDownOutlined /></Button>
                </div>;
            },
        },
        {
            title: "Kalan Kredi",
            dataIndex: 'remaining_credit',
            key: 'remaining_credit',
            render: (text, record) => {
                return <div>
                    <p>{text}</p>
                    <Button onClick={async () => {
                        //set this record of data to new value
                        setData(data.map((item) => {
                            if (item.uid === record.uid) {
                                item.remaining_credit += 1;
                            }
                            return item;
                        }))
                    }}> <CaretUpOutlined /></Button>
                    <Button onClick={async () => {
                        setData(data.map((item) => {
                            if (item.uid === record.uid) {
                                item.remaining_credit -= 1;
                            }
                            return item;
                        }))
                    }}> <CaretDownOutlined /></Button>
                </div>;
            },
        },
        {
            title: "Onayla",
            dataIndex: 'is_verified',
            render: (text, record) => {
                return <div> 
                    <Button onClick={async () => {
                        let uid = record.uid;
                        let newCredit = record.remaining_credit;
                        let newExpirationDate = record.expiration_date;
                        if(newExpirationDate !== "Invalid Date"){
                            await updateExpirationDateOfUser(uid, newExpirationDate);
                        }
                        await updateRemainingCreditOfUser(uid, newCredit);
                        alertSuccess(record.email + "Mailli Kullanıcı Güncellendi");
                    }} 
                    >Onayla</Button>
                </div>
            },
        }
    ];
    return (
        <div>
            <Input id="emailSearch" placeholder="Search email" />
            <Button onClick={onEmailSearch}>Email ile Ara</Button>
            <Input id="nameSearch" placeholder="Search Name"/>
            <Input id="surnameSearch" placeholder="Search Surname"/>
            <Button onClick={onNameSearch}>İsim ve Soyisim ile Ara</Button>
            <Table className={styles.Line} dataSource={dataSource} key="Id" columns={columns} />
        </div>
    );
}

export default GivePromoTable;
