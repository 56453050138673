// antd
import { MailOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
import { Col, Form, Input, Modal, Row } from 'antd'
// components
import Navbar from "../Navbar/Navbar";
// context
import { useAuth } from "../../contexts/AuthContext";
// firebase
import { sendPasswordResetEmail } from 'firebase/auth';
// helpers
import { getAlertMessage } from '../../helpers/utils/AlertUtils';
import { auth } from '../../helpers/firebase/firebase';
// hooks
import useNotification from '../../hooks/useNotification';
//i18next
import { useTranslation } from 'react-i18next';
// react
import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// style
import styles from './LoginComponent.module.css'
import { login } from '../../helpers/firebase/firebase_user_methods';

const LoginComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['register', 'alerts', 'requires']);
    // auth context
    const { currentUser } = useAuth();
    // react-router-dom
    const navigate = useNavigate();
    let location = useLocation();
    // states
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false)
    const [resetLoading, setResetLoading] = useState(false)
    // hook
    const { alertSuccess, alertError } = useNotification();

    useEffect(() => {
        // when this page mount, it is controlled user login or not
        if (currentUser) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await login(user.email, user.password)
                .then(() => {
                    alertSuccess(t('succesfull_login', { ns: 'alerts' }));
                })
            // if the user is using the promotional link, 
            // this control enables the component to be used after 
            // logging in this link that user entered without logging in.
            if (location.state) {
                navigate(`${location.state.from.pathname}`)
            } else {
                navigate('/')
            }
        } catch (err) {
            let errorMessage = getAlertMessage(err.code);
            alertError(t(errorMessage, { ns: 'alerts' }));
            setLoading(false)
        }
    };
    //email reset function
    const handleReset = async () => {
        //get input with id 
        const email = document.getElementById("email-reset-id").value;
        setResetLoading(true)
        try {
            await sendPasswordResetEmail(auth, email).then((res) => console.log(res))
            alertSuccess(t('succesfull_reset_password', { ns: 'alerts' }));
            setResetLoading(false)
        } catch (err) {
            let errorMessage = getAlertMessage(err.code);
            alertError(t(errorMessage, { ns: 'alerts' }));
            setResetLoading(false)
        }
        setIsModalOpen(false);
    };
    return (
        <>
            <Navbar design={true} />
            <Row className={styles.loginContainer} justify='center' align='middle'>
                <Col className={styles.loginColumn} xxl={6} xl={6} lg={8} md={10} sm={14} xs={20} align='middle'>
                    <h1 className={styles.header}>{t('register:login')}</h1>
                    <Form
                        className={styles.formContainer}
                        name='loginForm'
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            className={styles.inputContainer}
                            name='Email'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: t('register:email_required')
                                    },

                                ]
                            }>
                            <Input
                                className={styles.loginInput}
                                size='large'
                                type="email"
                                name="email"
                                id="email"
                                placeholder={t('register:email')}
                                prefix={<MailOutlined />}
                                onChange={(e) => {
                                    setUser({ ...user, email: e.target.value.trim() });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name='password'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: t('register:password_required')
                                    },

                                ]
                            }>
                            <Input.Password
                                className={styles.loginInput}
                                size='large'
                                type="password"
                                name="password"
                                id="password"
                                placeholder={t('register:password')}
                                prefix={<LockOutlined />}
                                onChange={(e) => {
                                    setUser({ ...user, password: e.target.value.trim() });
                                }}
                            />
                        </Form.Item>
                        <button type="submit" className={styles.loginButton}>
                            {loading ? <LoadingOutlined /> : t('register:login')}
                        </button>
                    </Form>
                    <div className={styles.description}>{t('register:youdonthaveaccount')}<Link to={'/register'} className={styles.registerLink}>{t('register:register')}</Link></div>
                    <div className={styles.forgotPassword} onClick={() => { setIsModalOpen(true) }}>
                        {t('register:forgotpassword')}
                    </div>
                    <Modal open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={false}
                    >
                        <Input className={styles.modalInput} type="email" name="email" id="email-reset-id" placeholder={t('register:email')} prefix={<MailOutlined />} />
                        <Row align='end'>
                            <button onClick={handleReset} className={styles.modalButton}>
                                {resetLoading ? <LoadingOutlined /> : t('register:changepassword')}
                            </button>
                        </Row>
                    </Modal>
                </Col>
            </Row>
        </>
    )
}

export default LoginComponent