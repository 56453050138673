// antd
import { Col } from 'antd'
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useNavigate } from 'react-router-dom'
// style
import styles from './SpecialPackageCard.module.css'

const SpecialPackageCard = ({ specialPackage, button, handleClick }) => {
    //i18next for language changing
    const { t } = useTranslation(['record']);
    // react-router-dom
    const navigate = useNavigate();
    // if user click card navigate detail page
    const navigateToDetails = async () => {
        navigate(`/details/${specialPackage.title}`);
    }

    return (
        <Col align="middle" className={styles.cardContainer} span={23}>
            <img
                className={styles.packageImage}
                src={specialPackage?.image || '/assets/images/no_image.png'}
                alt={specialPackage?.title} />
            <p className={styles.cardTitle} title={specialPackage?.title}>{t(specialPackage?.title)}</p>
            {
                button ?
                    <button onClick={handleClick} className={styles.choosePackageButton}>{t('frequencies:choose_package')}</button>
                    :
                    <button onClick={navigateToDetails} className={styles.cardButton}>{t('frequencies:details')}</button>
            }
        </Col>
    )
}

export default SpecialPackageCard