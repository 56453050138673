import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "./firebase";
import { getRemainingCredit } from "./firebase_package_methods";
import { isCurrentUserAdmin } from "./firebase_user_methods";

export const currentUserHaveSpecialPackage = async () => {
  //get purhcased packages of current user
  const userRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(userRef);
  //if user have special package return true
  let specialPackages = documentSnapshots.data().special_packages;
  if (specialPackages?.length === null || specialPackages?.length === 0) {
    return false;
  } else if (specialPackages?.length > 0) {
    for (let i = 0; i < specialPackages.length; i++) {
      if (specialPackages[i].is_active) {
        return true;
      }
    }
    return false;
  }
};

export const currentUserAvailableForUploadOneFrequency = async () => {
  if (await isCurrentUserAdmin()) {
    return {status:true};
  } else if (!(await isRemainingCreditValid())) {
    return {status: false, message: "not_enough_credit"};
  } else if (await isPackageExpired()) {
    return {status: false, message: "package_expired"};
  }  //else if (!(await isWaitingTimeExpired())) {
    //return {status: false, message: "waiting_date_expired"};
  //}
  return {status:true};
};

//control if package is expired or not
export const isPackageExpired = async () => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  if (documentSnapshots.data().expiration_date == null) {
    return true;
  } else {
    const expirationDate = new Date(
      documentSnapshots.data().expiration_date?.seconds * 1000
    );
    if (expirationDate < Date.now()) {
      //remove remaining credit and expiration date
      await updateDoc(packageRef, {
        remaining_credit: 0,
        expiration_date: null,
      });
      return true;
    }
  }
};
//When user upload a voice, user cannot upload another voice in three hours
//To check if waiting_date is expired or not
export const isWaitingTimeExpired = async () => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  const waiting_date = new Date(
    documentSnapshots.data().waiting_date?.seconds * 1000
  );
  if (waiting_date < Date.now()) {
    await updateDoc(packageRef, {
      waiting_date: null,
    });
    return true;
  } else if (waiting_date > Date.now()) {
    return false;
  }
  return true;
};

export const isRemainingCreditValid = async () => {
  let credit = await getRemainingCredit();
  if (credit <= 0) {
    return false;
  }
  return true;
};

export const getRemainWaitingDate = async () => {
  //get purhcased packages of current user
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  const waiting_date = new Date(
    documentSnapshots.data().waiting_date?.seconds * 1000
  );
  //get remaining date - current date
  return waiting_date;
}

export const isPurchasePackageCompleted = async () => {
  const packageRef = doc(db, "payment_status", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  //if doc is exist return status
  //else return false
  if (documentSnapshots.exists()) {
    return true;
  }else {
    return false;
  }
};

export const deleteStatusOfPayment = async () => {
  const packageRef = doc(db, "payment_status", auth.currentUser.uid);
  await deleteDoc(packageRef);
}

export const putErrorToPaymentStatus = async () => {
  const packageRef = doc(db, "payment_error", auth.currentUser.uid);
  //if doc exist add error to array
  //else create doc and add error to array
  let documentSnapshots = await getDoc(packageRef);
  if (documentSnapshots.exists()) {
    let currentDate = new Date();
    await updateDoc(packageRef, {
      errors: [...documentSnapshots.data().errors, {error:"error", date: currentDate}],
    });
  }else {
    let currentDate = new Date();
    await setDoc(packageRef, {
      errors: [{error:"error", date: currentDate}],
    });
  }
}