import React, { useState } from "react";
import styles from "../Create.module.css";
import { Button, Form, Col, Input, Row, Card, Select } from "antd";
import {
    createSpecialPackage,
  getDiseaseCategories, getDiseasesEmptyParamForDiseaseTable,
} from "../../helpers/firebase/firebase_admin_methods";
import useConstructor from "./use_constructor";
import useNotification from "../../hooks/useNotification";

const CreateSpecialPackage = () => {
  const [disease, setDisease] = useState();
  const [diseases, setDiseases] = useState([]);
  const [allDiseases, setAllDiseases] = useState([]);
  const [paragraphs, setParagraphs] = useState([]);
  const [steps, setSteps] = useState([]);
  const { alertSuccess, alertError } = useNotification();
  const { Option } = Select;
  const handleSubmit = async () => {
    try {
      //call function
    await createSpecialPackage(disease);
    alertSuccess("Yeni Paket Oluşturuldu");
    } catch (err) {
      alertError(`${err}`);
    }
  };
  useConstructor(async () => {
    await getDiseaseCategories().then((res) => {
      setDiseases(res);
    })
    await getDiseasesEmptyParamForDiseaseTable().then((res) => {
      setAllDiseases(res);
    })
  });
  return (
    <div className={styles.container}>
      <Row className={styles.rowstyle}>
        <Col>
          <Card>
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="disease_category"
                label="Disease Category"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input disease category!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a category!"
                  onChange={(value) => {
                    setDisease({ ...disease, disease_category: value });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {
                    diseases.map((disease,index) => {
                      return <Option key= {index} value={disease}>{disease}</Option>;
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name="hours"
                label="Hours"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input hours!",
                    },
                ]}
                >
                <Input
                    type="number"
                    placeholder="Hours"
                    onChange={(e) => {
                    setDisease({ ...disease, hours: e.target.value });
                    }}
                />
                </Form.Item>
                <Form.Item
                name="package_name"
                label="Package Name"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input package name!",
                    },
                ]}
                >
                <Input
                    placeholder="Package Name"
                    onChange={(e) => {
                    setDisease({ ...disease, package_name: e.target.value });
                    }}
                />
                </Form.Item>
                <Form.Item
                name="price"
                label="Price"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input price!",
                    },
                ]}
                >
                <Input
                    type="number"
                    placeholder="Price"
                    onChange={(e) => {
                    setDisease({ ...disease, price: e.target.value });
                    }}
                />
                </Form.Item>
                {/* <Form.Item
                name="description_en"
                label="Description (English)"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input description!",
                    },
                ]}
                >
                <Input
                    placeholder="Description"
                    onChange={(e) => {
                    setDisease({ ...disease, description_en: e.target.value });
                    }}
                />
                </Form.Item>
                <Form.Item
                name="description_tr"
                label="Description (Turkish)"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input description!",
                    },
                ]}
                >
                <Input
                    placeholder="Description"
                    onChange={(e) => {
                    setDisease({ ...disease, description_tr: e.target.value });
                    }}
                />
                </Form.Item> */}
                <Form.Item
                name="paragraph_value"
                label="Paragraph Value"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input paragraphs!",
                    },
                ]}
                >
                <Input
                    type="number"
                    placeholder="Paragraph Value"
                    onChange={(e) => {
                    setDisease({ ...disease, paragraph_value: e.target.value });
                    setParagraphs([]);
                    for (let i = 0; i < e.target.value; i++) {
                        setParagraphs((paragraphs) => [...paragraphs, i]);
                    }
                    }}
                />
                </Form.Item>
                {
                    paragraphs.map((paragraph, index) => {
                        return (
                            <div>
                                <Form.Item
                                name={`subtitle_en_${index}`}
                                label={`Subtitle (English) ${index + 1}`}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input subtitle!",
                                    },
                                ]}
                                >
                                <Input
                                    placeholder="Subtitle"
                                    onChange={(e) => {
                                    setDisease({ ...disease, [`subtitle_en_${index}`]: e.target.value });
                                    }}
                                />
                                </Form.Item>
                                <Form.Item
                                name={`subtitle_tr_${index}`}
                                label={`Subtitle (Turkish) ${index + 1}`}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input subtitle!",
                                    },
                                ]}
                                >
                                <Input
                                    placeholder="Subtitle"
                                    onChange={(e) => {
                                    setDisease({ ...disease, [`subtitle_tr_${index}`]: e.target.value });
                                    }}
                                />
                                </Form.Item>
                                <Form.Item
                                name={`paragraph_en_${index}`}
                                label={`Paragraph (English) ${index + 1}`}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input paragraph!",
                                    },
                                ]}
                                >
                                <Input
                                    placeholder="Paragraph"
                                    onChange={(e) => {
                                    setDisease({ ...disease, [`paragraph_en_${index}`]: e.target.value });
                                    }}
                                />
                                </Form.Item>
                                <Form.Item
                                name={`paragraph_tr_${index}`}
                                label={`Paragraph (Turkish) ${index + 1}`}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input paragraph!",
                                    },
                                ]}
                                >
                                <Input
                                    placeholder="Paragraph"
                                    onChange={(e) => {
                                    setDisease({ ...disease, [`paragraph_tr_${index}`]: e.target.value });
                                    }}
                                />
                                </Form.Item>
                            </div>
                        );
                    })
                }
                <Form.Item
                name="step_number"
                label="Step Number"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input step number!",
                    },
                ]}
                >
                <Input
                    type="number"
                    placeholder="Step Number"
                    onChange={(e) => {
                    setDisease({ ...disease, step_number: e.target.value });
                    setSteps([]);
                    for (let i = 0; i < e.target.value; i++) {
                        setSteps((steps) => [...steps, i]);
                    }
                    }}
                />
                </Form.Item>
                {
                    steps.map((step, index) => {
                        return (
                            <div>
                                <Form.Item
                name={`disease_sub_${index}`}
                label="Step name"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input disease!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a disease!"
                  onChange={(value) => {
                    //create steps array and push it in disease object
                    setDisease({ ...disease, [`disease_sub_${index}`]: value });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {
                    allDiseases.map((disease,index) => {
                      return <Option key= {index} value={disease.disease_name}>{disease.disease_name}</Option>;
                    })
                  }
                </Select>
              </Form.Item>
                            </div>
                        );
                    })
                }
                <Form.Item
                name="image"
                label="Image"
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: "Please input image!",
                    },
                ]}
                >
                <Input
                    placeholder="Image"
                    type="file"
                    onChange={(e) => {
                    setDisease({ ...disease, image: e.target.files[0] });
                    }}
                />
                </Form.Item>
              <Form.Item className={styles.loginButton}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginformbutton}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateSpecialPackage;
