import CancelComponent from '../components/CancelComponent/CancelComponent'
import Footer from '../components/Footer/Footer'

const Cancel = () => {
    return (
        <>
            <CancelComponent />
            <Footer />
        </>
    )
}

export default Cancel