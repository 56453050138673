// components
import Loading from '../components/Loading/Loading'
// firebase
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
} from "firebase/auth";
// helpers
import { auth } from "../helpers/firebase/firebase";
import { getCurrentUserDetails } from "../helpers/firebase/firebase_user_methods";
import { useContext, useState, useEffect, createContext } from "react";

// create context
const AuthContext = createContext();
// this function use different components  to call easyly
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    // states
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({
        name: "",
        surname: "",
        email: "",
        phoneNumber: "",
        gender: "",
        age: "",
        region: "",
        city: "",
        verified: false,
        isAdmin: false,
    });
    // signup function
    async function signup(email, password) {
        return await createUserWithEmailAndPassword(auth, email, password);
    }
    // // login function
    // function login(email, password) {
    //     return signInWithEmailAndPassword(auth, email, password);
    // }

    // logout function
    function logout() {
        signOut(auth);
    }
    // get user knowledge from firebase
    const getDetails = async () => {
        await getCurrentUserDetails()
            .then((data) => {
                setUserDetails({
                    name: data?.name,
                    surname: data?.surname,
                    email: data?.email,
                    phoneNumber: data?.phone,
                    gender: data?.gender,
                    age: data?.age_interval,
                    region: data?.region,
                    city: data?.city,
                    verified: auth.currentUser.emailVerified,
                    isAdmin: data?.is_admin,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // function loginWithGoogle() {
    //   googleProvider.setCustomParameters({ prompt: "select_account" });
    //   signInWithPopup(auth, googleProvider)
    //     .then((result) => {
    //       console.log(result);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }

    useEffect(() => {
        if (!localStorage.getItem('i18nextLng')) {
            let browserLanguage = (navigator.languages)
            if (browserLanguage[0].includes('tr')) {
                localStorage.setItem('i18nextLng', `${browserLanguage}`)
            } else {
                localStorage.setItem('i18nextLng', 'en')
            }
        }
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            auth.languageCode = localStorage.getItem("i18nextLng");
            setCurrentUser(user);
            setLoading(false);
            getDetails();
        });

        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const value = {
        currentUser,
        userDetails,
        setUserDetails,
        signup,
        logout,
        //   loginWithGoogle,
    };

    return (
        <AuthContext.Provider value={value}>
            {loading && <Loading />}
            {!loading && children}
        </AuthContext.Provider>
    );
}