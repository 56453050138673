// antd
import { LoadingOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd'
// components
import CityDropdown from '../CityDropdown/CityDropdown';
// context
import { useAuth } from '../../contexts/AuthContext';
// helpers
import { updateProfileFirebase } from '../../helpers/firebase/firebase_user_methods';
import { getAlertMessage } from '../../helpers/utils/AlertUtils';
// hook
import useNotification from '../../hooks/useNotification';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useEffect, useState } from 'react';
// react-country-list
import countryList from 'react-select-country-list';
// phone-input
import PhoneInput from 'react-phone-input-2';
// style
import styles from './UserKnowledge.module.css'

// antd
const { Option } = Select;

const UserKnowledge = () => {
    //i18next for language changing
    const { t } = useTranslation(['app', 'record', 'alerts', 'requires']);
    // auth context
    const { userDetails } = useAuth();
    // states
    const [userInfoUpdate, setUserInfoUpdate] = useState(userDetails);
    const [newCountryList, setNewCountryList] = useState()
    const [loading, setLoading] = useState(false);
    // hook
    const { alertSuccess, alertError } = useNotification();

    useEffect(() => {
        // get country list for register form
        let options = countryList().getData();
        const newCountry = []

        // Turkey's code change with this function
        options.forEach((item) => {
            newCountry.push({ label: item.label, value: item.label })
            if (item.label === 'Turkey') {
                item.value = 'Türkiye';
                item.label = 'Türkiye';
                newCountry.push({ label: item.label, value: item.value })
            }
        })
        setNewCountryList(newCountry)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleCityChange = (value) => {
        setUserInfoUpdate({ ...userDetails, city: value.trim() });
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await updateProfileFirebase(userInfoUpdate);
            alertSuccess(t('succesfull_profile_update', { ns: 'alerts' }));
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (err) {
            let errorMessage = getAlertMessage(err.code);
            alertError(t(errorMessage, { ns: 'alerts' }));
            setLoading(false)
        }
    };

    return (
        <Row className={styles.userKnowledgeContainer} align='top' justify='start'>
            <Col className={styles.userKnowledgeColumn} justify='center' span={24}>
                <h1 className={styles.userKnowledgeHeader}>{t('user_info', { ns: 'app' })}</h1>
                <Col xxl={10} xl={10} lg={10} md={22} sm={22} xs={24} className={styles.formColumn}>
                    <Form
                        initialValues={{
                            "name": userDetails?.name,
                            "surname": userInfoUpdate?.surname,
                            "email": userInfoUpdate?.email,
                            "phoneNumber": userInfoUpdate?.phoneNumber,
                            "age": userInfoUpdate?.age,
                            "gender": userInfoUpdate?.gender,
                            "region": userInfoUpdate?.region.toLowerCase(),
                            "city": userInfoUpdate?.city
                        }}
                        name='register form'
                        className={styles.formContainer}
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            className={styles.inputContainer}
                            name='name'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: t('please_enter_name', { ns: 'requires' }),
                                    },
                                ]
                            }>
                            <Input
                                className={styles.registerInput}
                                size='large'
                                type="text"
                                name="name"
                                id="name"
                                placeholder={t('name', { ns: 'requires' })}
                                prefix={<UserOutlined />}
                                onChange={(e) => {
                                    let val = e.target.value.trim();
                                    setUserInfoUpdate({ ...userInfoUpdate, name: val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() });
                                }}
                                onKeyPress={(event) => {
                                    if (!/[a-zA-ZğüıIşöçİĞÜŞÖÇ\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name='surname'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: t('please_enter_surname', { ns: 'requires' }),
                                    },

                                ]
                            }>
                            <Input
                                className={styles.registerInput}
                                size='large'
                                type="text"
                                name="surname"
                                id="surname"
                                placeholder={t('surname', { ns: 'requires' })}
                                prefix={<UserOutlined />}
                                onChange={(e) => {
                                    let val = e.target.value.trim();
                                    setUserInfoUpdate({ ...userInfoUpdate, surname: val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() });
                                }}
                                onKeyPress={(event) => {
                                    if (!/[a-zA-ZğüıIşöçİĞÜŞÖÇ\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name='email'
                            rules={[
                                {
                                    type: "email",
                                    message: t('please_enter_valid_email', { ns: 'requires' }),
                                },
                                {
                                    required: true,
                                    message: t('please_enter_email', { ns: 'requires' }),
                                },
                            ]}
                        >
                            <Input
                                className={styles.registerInput}
                                size='large'
                                type="email"
                                name="email"
                                id="email"
                                placeholder={t('email', { ns: 'requires' })}
                                prefix={<MailOutlined />}
                                onChange={(e) => {
                                    setUserInfoUpdate({ ...userInfoUpdate, email: e.target.value.trim() });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            className={styles.phoneContainer}
                            name="phoneNumber"
                            rules={[
                                {
                                    required: true,
                                    message: t('please_enter_phone_number', { ns: 'requires' }),
                                },
                            ]}
                        >
                            <PhoneInput
                                className={styles.phoneInput}
                                country={"tr"}
                                inputProps={{ required: true }}
                                inputStyle={{ width: "100%" }}
                                placeholder={t('phone_number', { ns: 'requires' })}
                                onChange={(e) => { setUserInfoUpdate({ ...userInfoUpdate, phoneNumber: "+" + e.trim() }); }}
                            >{userInfoUpdate?.phoneNumber}</PhoneInput>
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name="age"
                            rules={[
                                { required: true, message: t('please_select_age', { ns: 'requires' }) },
                            ]}
                        >

                            <Select
                                size='large'
                                className={styles.ageInput}
                                placeholder={t('age', { ns: 'requires' })}
                                onChange={(e) => { setUserInfoUpdate({ ...userInfoUpdate, age_interval: e }); }}
                            >
                                <Option value="0-18">0-18</Option>
                                <Option value="18-25">18-25</Option>
                                <Option value="25-40">25-40</Option>
                                <Option value="40-65">40-65</Option>
                                <Option value="65+">65+</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name="gender"
                            rules={[{ required: true, message: t('please_select_gender', { ns: 'requires' }) }]}
                        >
                            <Select
                                size='large'
                                className={styles.genderInput}
                                dropdownStyle={styles.dropdown}
                                placeholder={t('gender', { ns: 'requires' })}
                                onChange={(e) => { setUserInfoUpdate({ ...userInfoUpdate, gender: e }); }}
                            >
                                <Option value="Male">{t('male', { ns: 'requires' })}</Option>
                                <Option value="Female"> {t('female', { ns: 'requires' })}</Option>
                                <Option value="Other"> {t('other', { ns: 'requires' })}</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name="region"
                            rules={[
                                {
                                    required: true,
                                    message: t('please_select_country', { ns: 'requires' }),
                                },
                            ]}
                        >
                            <Select
                                size='large'
                                dropdownStyle={styles.dropdown}
                                className={styles.regionInput}
                                showSearch
                                placeholder={t('country', { ns: 'requires' })}
                                options={newCountryList}
                                onChange={(e) => { setUserInfoUpdate({ ...userInfoUpdate, region: e }) }}
                            />
                        </Form.Item>

                        {userInfoUpdate.region.toLowerCase() === "türkiye" &&
                            <CityDropdown handleCityChange={handleCityChange} city={userInfoUpdate.city} />
                        }
                        {
                            loading ?
                                <button type="submit" className={styles.updateButton} disabled>
                                    <LoadingOutlined />
                                </button>
                                :
                                <button type="submit" className={styles.updateButton}>
                                    {t('update', { ns: 'app' })}
                                </button>
                        }
                    </Form>
                </Col>
            </Col>
        </Row>
    )
}

export default UserKnowledge