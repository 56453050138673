import React, { useState } from "react";
import styles from "../Create.module.css";
import { Button, Form, Col, Input, Row, Card, Select } from "antd";

import {
  getDiseaseCategories,
  putDisease,
  putDiseaseToLocalization,
} from "../../helpers/firebase/firebase_admin_methods";
import useConstructor from "./use_constructor";
import { useTranslation } from "react-i18next";
import useNotification from "../../hooks/useNotification";

const CreateDisease = () => {
  const { t } = useTranslation(["record"]);
  const { alertSuccess, alertError } = useNotification();
  const [disease, setDisease] = useState();
  const [diseases, setDiseases] = useState([]);
  const { Option } = Select;
  const handleSubmit = async () => {
    try {
      await putDisease(disease);
      await putDiseaseToLocalization(disease);
      alertSuccess("Yeni Frekans Oluşturuldu");
    } catch (err) {
      alertError(`${err}`);
    }
  };
  useConstructor(async () => {
    await getDiseaseCategories().then((res) => {
      setDiseases(res);
    });
  });
  return (
    <div className={styles.container}>
      <Row className={styles.rowstyle}>
        <Col>
          <Card>
            Girdiğiniz Metinlerin Baş Harflerinin Büyük Olması Önerilir 
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="disease_category"
                label="Disease Category"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input disease category!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a category!"
                  onChange={(value) => {
                    setDisease({ ...disease, disease_category: value });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {
                    diseases.map((disease,index) => {
                      return <Option key= {index} value={disease}>{t(disease)}</Option>;
                    })
                  }
                </Select>
              </Form.Item>
              Lütfen Frekansın İngilizce Karşığını Giriniz - "/ , ." gibi özel karakterler kullanmayınız
              <Form.Item
                name="disease_name"
                label="Disease Name"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input disease name",
                  },
                ]}
              >
                <Input
                  placeholder="Disease Name"
                  onChange={(e) => {
                    setDisease({ ...disease, disease_name: e.target.value });
                  }}
                />
              </Form.Item>
              Lütfen Frekansın Türkçe Karşığını Giriniz - "/ , ." gibi özel karakterler kullanmayınız
              <Form.Item
                name="hastalık_adı"
                label="Hastalık Adı"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input disease name",
                  },
                ]}
              >
                <Input
                  placeholder="Hastalık Adı"
                  onChange={(e) => {
                    setDisease({ ...disease, hastalık_adı: e.target.value });
                  }}
                />
              </Form.Item>
              Lütfen Frekansın Önerilen Metninin İngilizce Karşığını Giriniz - "/ , ." gibi özel karakterler kullanmayınız
              <Form.Item
                name="recommended_text"
                label="Recomenned Text"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input recomenned text!",
                  },
                ]}
              >
                <Input
                  placeholder="Recommended Text"
                  onChange={(e) => {
                    setDisease({
                      ...disease,
                      recommended_text: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              Lütfen Frekansın Önerilen Metninin Türkçe Karşığını Giriniz - "/ , ." gibi özel karakterler kullanmayınız
              <Form.Item
                name="önerilen_metin"
                label="Önerilen Metin"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input recomenned text!",
                  },
                ]}
              >
                <Input
                  placeholder="Recommended Text"
                  onChange={(e) => {
                    setDisease({ ...disease, önerilen_metin: e.target.value });
                  }}
                />
              </Form.Item>
              {/* <Form.Item
                name="status"
                label="Status"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input status!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a status!"
                  onChange={(value) => {
                    setDisease({ ...disease, status: value });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value="Normal">Normal Frequency</Option>
                  <Option value="Special">Special Package</Option>
                  <Option value="Both">Both</Option>
                </Select>
              </Form.Item> */}
              <Form.Item className={styles.loginButton}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginformbutton}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateDisease;
