//antd
import { Col, Result } from 'antd'
//component
import Navbar from '../Navbar/Navbar';
//i18next
import { useTranslation } from 'react-i18next';
//react
import { Link } from 'react-router-dom'
//style
import styles from './CancelComponent.module.css'

const CancelComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['navbar']);
    return (
        <>
            <Navbar design={true} />
            <Col className={styles.cancelContainer}>
                <Result
                    status="warning"
                    title={t("navbar:cancel")}
                    extra={
                        <Link to={'/'} className={styles.headerPagination} key='home'>{t('navbar:home')}</Link>
                    }
                />
            </Col>
        </>
    )
}

export default CancelComponent