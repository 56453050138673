import { Pie } from "@ant-design/plots";
import { useState } from "react";
import { getGenderStats } from '../../helpers/firebase/firebase_admin_methods';
import useConstructor from "./use_constructor";
import styles from "./statistic_style.module.css"
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";



const GenderChart = () => {
    const { t } = useTranslation(['admin']);
    //when render initiate 
    const { RangePicker } = DatePicker;
    const [data, setData] = useState([]);
    const config = {
        appendPadding: 1,
        data,
        angleField: "count",
        colorField: "name",
        radius: 0.75,
        label: {
            type: "spider",
            labelHeight: 28,
            content: "{name}\n{percentage}",
        },
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
        ],
    };
    useConstructor(async () => {
        //call get income method with now and 1 month before
        //default dates last 1 month    
        await getGenderStats((Date.now() - 30 * 24 * 60 * 60 * 1000), Date.now()).then((res) => {
            res.forEach((element) => {
                if(data.length === 0){
                    setData((prev) => [...prev, { name: t(element.name), count: element.count }]);
                }else {
                    data.forEach((elem) => {
                        if (elem.name === t(element.name)) {
                            setData((prev) => [...prev.filter((e) => e.name !== t(element.name)), { name: t(element.name), count: element.count }]);
                        }
                    })
                }
                
            });
        });
    });
    return (
        <div className="App">
            <h2>{t("gender")}</h2>
            <div>
                <RangePicker onChange={async (date) => {
                    await getGenderStats(date[0]._d.getTime(), date[1]._d.getTime()).then((res) => {
                        res.forEach((element) => {
                            if(data.length === 0){
                                setData((prev) => [...prev, { name: t(element.name), count: element.count }]);
                            }else {
                                data.forEach((elem) => {
                                    if (elem.name === t(element.name)) {
                                        setData((prev) => [...prev.filter((e) => e.name !== t(element.name)), { name: t(element.name), count: element.count }]);
                                    }
                                })
                            }
                            
                        });
                    });
                }} />
                <Pie className={styles.Pie} {...config} />
            </div>
        </div>
    )
}

export default GenderChart