// antd
import { Col, Row } from 'antd'
// components
import Navbar from '../Navbar/Navbar'
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useNavigate } from "react-router-dom";
// style
import styles from './PageNotFoundComp.module.css'

const PageNotFoundComp = () => {
    //i18next for language changing
    const { t } = useTranslation(['navbar']);
    // react-router-dom
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <Navbar design={true} />
            <Row className={styles.innerContainer} justify='center'>
                <Col xxl={12} xl={12} lg={12} md={20} sm={20} xs={22} className={styles.imageContainer} align='middle'>
                    <img className={styles.pageNotFound} src="/assets/images/page-not-found.jpg" alt="Page Not Found" />
                    <Row className={styles.buttonGroup} align='middle' justify='center'>
                        <button className={styles.goBackButton} onClick={() => { navigate(-1) }}>{t('navbar:go_back')}</button>
                        <button className={styles.homeButton} onClick={() => { navigate('/') }}>{t('navbar:home')}</button>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default PageNotFoundComp