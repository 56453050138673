// antd
import { Modal } from 'antd'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { useNavigate } from 'react-router-dom'
// style
import { useEffect, useState } from 'react'
import { deleteStatusOfPayment, isPurchasePackageCompleted, putErrorToPaymentStatus } from '../../helpers/firebase/firebase_check_utils'
import Loading from '../Loading/Loading'


const SuccessComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['navbar', 'frequencies']);
    // states
    const [payment, setPayment] = useState(true);
    // react-router-dom
    const navigate = useNavigate()
    // pop up
    const success = (message) => {
        Modal.success({
            content: `${message}`,
            onOk() {
                navigate('/app');
            },
            okText: t("ok", { ns: "frequencies" }),
        });
    };

    const error = (message) => {
        Modal.error({
            content: `${message}`,
            onOk() {
                navigate('/');
            },
            okText: t("ok", { ns: "frequencies" }),
        });
    };

    useEffect(() => {
        //get session id from url
        // const urlParams = new URLSearchParams(window.location.search);
        // const sessionId = urlParams.get('session_id');
        // //call request every 2 seconds in 1 minute
        let seconds = 0;
        const interval = setInterval(async () => {
            //call request
            if (await isPurchasePackageCompleted()) {
                setPayment(false);
                await deleteStatusOfPayment();
                clearInterval(interval)
                //show popup if press ok navigate to app
                success(t('navbar:successful_payment'))
            } else {
                seconds += 2;
                if (seconds === 160) {
                    setPayment(false);
                    clearInterval(interval);
                    //write the error doc
                    await putErrorToPaymentStatus();
                    //show error popup // bizimle iletişime geçin
                    error(t('navbar:error_payment'))
                }
            }
        }, 2000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Loading payment={payment} />
            {/* <Col className={styles.successContainer}>
                <Result
                    status="success"
                    title={t("navbar:success")}
                    subTitle={t("navbar:successSubTitle")}
                    extra={[
                        <Row justify='center' align='middle'>
                            <p className={styles.appButton} key='app' onClick={appClicked}>
                                {loading ? <LoadingOutlined /> : t("navbar:gotoapp")}
                            </p>
                            <Link to={'/'} className={styles.headerPagination} key='home'>{t('navbar:home')}</Link>
                        </Row>,
                    ]}
                />
            </Col> */}
        </>
    )
}

export default SuccessComponent