// antd
import { Col, Row } from 'antd'
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons'
// helpers
import { getPurchaseToNewsletters } from '../../helpers/firebase/firebase_user_methods'
// hook
import useNotification from '../../hooks/useNotification'
// i18next
import { useTranslation } from 'react-i18next'
// style
import styles from './News.module.css'
import { useState } from 'react'

const News = () => {
    //i18next for language changing
    const { t } = useTranslation(['news', 'alerts']);
    // hook
    const { alertError, alertSuccess } = useNotification();
    // states
    const [loading, setLoading] = useState(false)
    //when submit
    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        const email = document.getElementById('email').value.trim();
        // to write info to firebase
        getPurchaseToNewsletters(email)
            .then((res) => {
                if (res === true) {
                    alertSuccess(t('succesfull_news_subscription', { ns: 'alerts' }));
                    setLoading(false)
                } else {
                    alertError(t('network_error', { ns: 'alerts' }));
                    setLoading(false)
                }
            });
    }
    return (
        <Row id='newsContainerId' className={styles.container} justify='center'>
            <Col xxl={7} xl={7} lg={7} md={5} sm={0} xs={0}></Col>
            <Col xxl={10} xl={10} lg={10} md={14} sm={24} xs={24} align='middle' className={styles.socialMediaContainer}>
                <h1 className={styles.socialMediaTitle}>{t('news:followsocial')}</h1>
                <form className={styles.inputConutainer} onSubmit={handleSubmit}>
                    <input className={styles.socialMediaEmail} type="email" placeholder={t("news:email")} id='email' required />
                    <div className={styles.buttonContainer} >
                        {
                            loading ?
                                <button type="submit" className={styles.socialMediaButton} disabled><LoadingOutlined /></button>
                                :
                                <button type="submit" className={styles.socialMediaButton} >{t('news:subscribe')}</button>
                        }
                        <ArrowRightOutlined className={styles.arrow} />
                    </div>
                </form>
            </Col>
            <Col xxl={7} xl={7} lg={7} md={5} sm={0} xs={0} align='flex-start' className={styles.arrowImageContainer}>
                <img className={styles.arrowImage} src="/assets/images/arrow.png" alt="arrow" />
            </Col>
        </Row>
    )
}

export default News