import { Button, Col, Menu, Row } from "antd";
import { useState } from "react";
import styles from './AdminPanel.module.css'
import Income from "./components/stats_income_chart";
import TotalUsers from "./components/stats_total_user_chart";
import GenderChart from "./components/stats_gender_chart";
import AgeChart from "./components/stats_age_chart";
import RatingChart from "./components/stats_rating_chart";
import DiseaseCategoryChart from "./components/stats_disease_category_chart";
import CreateDisease from "./components/CreateDisease";
import DiseaseTable from "./components/DiseaseTable";
import useConstructor from "./components/use_constructor";
import { getDiseaseCategories } from "../helpers/firebase/firebase_admin_methods";
import DiseaseChart from "./components/DiseaseChart";
import { isCurrentUserAdmin } from "../helpers/firebase/firebase_user_methods";
import { useNavigate } from "react-router";
// import LoadingComponent from "../components/loading/LoadingComponent";
import { useTranslation } from "react-i18next";
import CreateDiseaseCategory from "./components/CreateCategory";
import GivePromoTable from "./components/GivePromo";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Packages from "./components/Packages";
import CreateSpecialPackage from "./components/CreateSpecialPackage";
import UpdateSpecialPackage from "./components/UpdateSpecialPackage";
import { getVoiceNumber } from "../helpers/firebase/firebase_voice_methods";
import ContactDisplay from "./components/ContactDisplay";
import NewsDisplay from "./components/NewsDisplay";

function getItem(label, key, description, role, icon, children, type) {
    return {
        key,
        icon,
        description,
        role,
        children,
        label,
        type,
    };
}


const AdminPanel = () => {
    const { t } = useTranslation(['record', 'admin']);
    const navigate = useNavigate();
    const [current, setCurrent] = useState("0");
    const [description, setDescription] = useState("");
    const [diseaseCategories, setDiseaseCategories] = useState([]);
    const [isRender, setIsRender] = useState(false);
    const [remainingVoiceNumber, setRemainingVoiceNumber] = useState(0);
    const items = [
        getItem("Frekanslar", 'sub1', null, t("admin:diseases", { lng: "en" }), null, [
            ...diseaseCategories.map((diseaseCategory, i) => {
                return getItem(t(diseaseCategory), i, t(diseaseCategory), t(diseaseCategory, { lng: "en" }))
            }),
            getItem(t("admin:alldiseases"), "21", t("admin:alldiseases"), t("admin:alldiseases", { lng: "en" }))
        ]),
        getItem("İstatistikler", 'sub2', null, t("admin:stats", { lng: "en" }), null, [
            getItem(t("admin:income"), "22", t("admin:income"), t("admin:income", { lng: "en" })),
            getItem(t("admin:totalusers"), "23", t("admin:totalusers"), t("admin:totalusers", { lng: "en" })),
            getItem(t("admin:gender"), "24", t("admin:gender"), t("admin:gender", { lng: "en" })),
            getItem(t("admin:age"), "25", t("admin:age"), t("admin:age", { lng: "en" })),
            getItem(t("admin:rating"), "26", t("admin:rating"), t("admin:rating", { lng: "en" })),
            getItem(t("admin:disease_category"), "27", t("admin:disease_category"), t("admin:disease_category", { lng: "en" })),
            ...diseaseCategories.map((diseaseCategory, i) => getItem(t(diseaseCategory), (i + 28), t(diseaseCategory), t(diseaseCategory, { lng: "en" }))),
        ]),
        getItem("Yeni Frekans Oluştur", "50", 'sub3', "Yeni Frekans Oluştur", null, null),
        // getItem("Create a category", "51", 'sub4', "Create a category", null, null),
        getItem("Kullanıcılar", "52", 'sub5', "Kullanıcılar", null, null),
        // getItem("Paketlerin Fiyatını Güncelle", "53", 'sub6', "Paketlerin Fiyatını güncelle", null, null),
        // getItem("Create Special Package", "54", 'sub7', "Create Special Package", null, null),
        // getItem("Özel Paketleri Güncelle", "55", 'sub8', "Paketleri Güncelle", null, null),
        getItem("İletişim Sayfasından Gelen Mesajlar", "56", 'sub9', null, null),
        getItem("Bültene Kaydolanlar", "57", 'sub9', null, null),
    ];
    useConstructor(async () => {
        if (!(await isCurrentUserAdmin())) {
            setIsRender(false);
            navigate("/");
        } else {
            setDiseaseCategories(await getDiseaseCategories());
            await getVoiceNumber().then((res) => (setRemainingVoiceNumber(res)));
            setIsRender(true);
        }
    })
    const reloadRemaining = async () => {
        await getVoiceNumber().then((res) => (setRemainingVoiceNumber(res)));
    };
    const menuClicked = (e) => {
        setCurrent(e.key);
        setDescription(e.item.props.role);
    };
    return (
        <div>
            <Navbar design={true} />
            {
                isRender ?
                    <div className={styles.recordPageContainer}>
                        <Col className={styles.voiceNumber}>
                            <Row>
                                <h2>Çalınmayı bekleyen ses sayısı:</h2>
                                <h2 className={styles.recordPageTitle}>{remainingVoiceNumber}</h2>
                            </Row>
                            <Row>
                                <Button type="primary" onClick={reloadRemaining}>Yenile</Button>
                            </Row>
                        </Col>
                        <Row wrap={true} className={styles.menuContainer} justify='center'>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={22} xs={22} className={styles.menuColumn}>
                                <Menu
                                    onClick={menuClicked}
                                    className={styles.menu}
                                    defaultOpenKeys={['sub1']}
                                    selectedKeys={[current]}
                                    mode="inline"
                                    items={items}
                                />
                            </Col>
                            <Col xxl={17} xl={17} lg={17} md={17} sm={22} xs={23}>
                                <div className={styles.recordContainer}>
                                    {current === "0" && <DiseaseTable data={description} />}
                                    {current === "1" && <DiseaseTable data={description} />}
                                    {current === "2" && <DiseaseTable data={description} />}
                                    {current === "3" && <DiseaseTable data={description} />}
                                    {current === "4" && <DiseaseTable data={description} />}
                                    {current === "5" && <DiseaseTable data={description} />}
                                    {current === "6" && <DiseaseTable data={description} />}
                                    {current === "7" && <DiseaseTable data={description} />}
                                    {current === "8" && <DiseaseTable data={description} />}
                                    {current === "9" && <DiseaseTable data={description} />}
                                    {current === "10" && <DiseaseTable data={description} />}
                                    {current === "11" && <DiseaseTable data={description} />}
                                    {current === "12" && <DiseaseTable data={description} />}
                                    {current === "13" && <DiseaseTable data={description} />}
                                    {current === "14" && <DiseaseTable data={description} />}
                                    {current === "15" && <DiseaseTable data={description} />}
                                    {current === "16" && <DiseaseTable data={description} />}
                                    {current === "17" && <DiseaseTable data={description} />}
                                    {current === "18" && <DiseaseTable data={description} />}
                                    {current === "19" && <DiseaseTable data={description} />}
                                    {current === "20" && <DiseaseTable data={description} />}
                                    {current === "21" && <DiseaseTable data={description} />}
                                    {current === "22" && <Income />}
                                    {current === "23" && <TotalUsers />}
                                    {current === "24" && <GenderChart />}
                                    {current === "25" && <AgeChart />}
                                    {current === "26" && <RatingChart />}
                                    {current === "27" && <DiseaseCategoryChart />}
                                    {current === "28" && <DiseaseChart data={description} />}
                                    {current === "29" && <DiseaseChart data={description} />}
                                    {current === "30" && <DiseaseChart data={description} />}
                                    {current === "31" && <DiseaseChart data={description} />}
                                    {current === "32" && <DiseaseChart data={description} />}
                                    {current === "33" && <DiseaseChart data={description} />}
                                    {current === "34" && <DiseaseChart data={description} />}
                                    {current === "35" && <DiseaseChart data={description} />}
                                    {current === "36" && <DiseaseChart data={description} />}
                                    {current === "37" && <DiseaseChart data={description} />}
                                    {current === "38" && <DiseaseChart data={description} />}
                                    {current === "39" && <DiseaseChart data={description} />}
                                    {current === "40" && <DiseaseChart data={description} />}
                                    {current === "41" && <DiseaseChart data={description} />}
                                    {current === "42" && <DiseaseChart data={description} />}
                                    {current === "43" && <DiseaseChart data={description} />}
                                    {current === "44" && <DiseaseChart data={description} />}
                                    {current === "45" && <DiseaseChart data={description} />}
                                    {current === "46" && <DiseaseChart data={description} />}
                                    {current === "47" && <DiseaseChart data={description} />}
                                    {current === "48" && <DiseaseChart data={description} />}
                                    {current === "49" && <DiseaseChart data={description} />}
                                    {current === "50" && <CreateDisease />}
                                    {current === "51" && <CreateDiseaseCategory />}
                                    {current === "52" && <GivePromoTable />}
                                    {current === "53" && <Packages />}
                                    {current === "54" && <CreateSpecialPackage />}
                                    {current === "55" && <UpdateSpecialPackage />}
                                    {current === "56" && <ContactDisplay />}
                                    {current === "57" && <NewsDisplay />}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    : <div>Loading...</div>
            }
            <Footer />
        </div>
    )
}
export default AdminPanel