import { Table } from "antd";
import { useState } from "react";
import { getDiseases, getDiseasesEmptyParamForDiseaseTable } from "../../helpers/firebase/firebase_admin_methods";
import useConstructor from "./use_constructor";
import styles from "./statistic_style.module.css"
import { useTranslation } from "react-i18next";


const DiseaseTable = (props) => {

    const {t} = useTranslation(['record']);
    const [data, setData] = useState([]);
    const dataSource = data;

    const columns = [
        {
            title: t("category"),
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: t("name"),
            dataIndex: 'disease_name',
            key: 'disease_name',
        },
    ];
    useConstructor(async () => {
        if(props.data === "All Frequencies") {
            getDiseasesEmptyParamForDiseaseTable().then((res) => {
                res.forEach((element) => {
                    setData((prev) => [...prev, { category_name: t(element.category_name), disease_name: t(element.disease_name) }]);
                });
            });
        }else {
            getDiseases(props.data).then((res) => {
                res.forEach((element) => {
                    if(element.status !== "Special"){
                        setData((prev) => [...prev, { category_name: t(element.category_name), disease_name: t(element.disease_name) }]);
                    }
                });
            });
        }
    })
    return (
        <div>
            <Table className={styles.Line} dataSource={dataSource} columns={columns} />
        </div>
    );
}

export default DiseaseTable;
