// antd
import { Col, Row } from 'antd'
// components
import SpecialPackageCard from '../SpecialPackageCard/SpecialPackageCard';
import Navbar from '../Navbar/Navbar'
import PopularFrequency from '../PopularFrequency/PopularFrequency'
import Categories from '../Categories/Categories'
import TotalUserFeedback from '../TotalUserFeedback/TotalUserFeedback'
import News from '../News/News'
import FrequncySearchInput from '../FrequncySearchInput/FrequncySearchInput';
// helpers
import { getAllSpecialPackages } from '../../helpers/firebase/firebase_package_methods';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useEffect, useState } from 'react';
// style
import styles from './FrequenciesComponent.module.css'

const FrequenciesComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['frequencies']);
    //states
    const [specialPackages, setSpecialPackages] = useState([]);
    // get special packages when page mount
    useEffect(() => {
        const getData = async () => {
            setSpecialPackages(await getAllSpecialPackages());
        }
        getData();
    }, [])
    return (
        <>
            <Navbar design={true} />
            <Col className={styles.frequenciesContainer}>
                <Row className={styles.headerContainer} align='middle' justify='space-between'>
                    <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24} >
                        <h1 className={styles.header}>
                            Fre<span className={styles.headerWell}>Well</span> {t('frequencies:frequencies')}
                        </h1>
                    </Col>
                    <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24}>
                        <FrequncySearchInput />
                    </Col>
                </Row>
                <Col className={styles.frequnciesPackage}>
                    <h1 className={styles.frequnciesPackageHeader}>{t('frequencies:frequencies_packs')}</h1>
                    <Row wrap={true} className={styles.specialPackageCardContainer} justify='center' align='middle'>
                        {
                            specialPackages?.map((specialPackage, index) => {
                                return (
                                    specialPackage.is_active &&
                                    <Col xxl={5} xl={5} lg={5} md={10} sm={10} xs={18} align='middle' key={index}>
                                        <SpecialPackageCard specialPackage={specialPackage} />
                                    </Col>
                                )
                            })
                        }
                    </Row>
                    <PopularFrequency />
                    <Categories />
                </Col>
                <TotalUserFeedback />
                <News />
            </Col>
        </>
    )
}

export default FrequenciesComponent