import React, { useState } from "react";
import styles from "../Create.module.css";
import { Button, Form, Col, Input, Row, Card } from "antd";
// import useNotification from "../../components/usemessage/UseMessage";
import {
  // putDisease,
  putDiseaseCategory,
  putDiseaseCategoryToLocalization,
  // putDiseaseToLocalization,
} from "../../helpers/firebase/firebase_admin_methods";
import useNotification from "../../hooks/useNotification";

const CreateDiseaseCategory = () => {
  const { alertSuccess, alertError } = useNotification();
  const [disease, setDisease] = useState();
  const handleSubmit = async () => {
    try {
      await putDiseaseCategory(disease);
      await putDiseaseCategoryToLocalization(disease);
      alertSuccess("Yeni Kategori Oluşturuldu");
    } catch (err) {
      alertError(`${err}`);
    }
  };
  return (
    <div className={styles.container}>
      <Row className={styles.rowstyle}>
        <Col>
          <Card>
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="disease_category"
                label="Disease Category"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input disease category!",
                  },
                ]}
              >
                <Input
                  placeholder="Disease Category"
                  onChange={(e) => {
                    setDisease({ ...disease, disease_category: e.target.value });
                  }}
                />


              </Form.Item>
              <Form.Item
                name="hastalık_kategorisi"
                label="Hastalık Kategorisi"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input disease category!",
                  },
                ]}
              >
                <Input
                  placeholder="Hastalık Kategorisi"
                  onChange={(e) => {
                    setDisease({
                      ...disease,
                      hastalık_kategorisi: e.target.value,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item className={styles.loginButton}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginformbutton}
                >
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateDiseaseCategory;
