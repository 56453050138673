// antd
import { Col } from 'antd'
// component
import Navbar from '../Navbar/Navbar'
// i18next
import { useTranslation } from 'react-i18next';
// style
import styles from './UserAgreementComp.module.css'

const UserAgreementComp = () => {
    //i18next for language changing
    const { t } = useTranslation(['userAgreement']);

    return (
        <>
            <Navbar design={true} />
            <Col className={styles.agreementContainer} span={24} align='middle'>
                <Col className={styles.page} xxl={20} xl={20} lg={20} md={22} sm={23} xs={23}>
                    <h1>{t('userAgreement:mainTitle')}</h1>
                    <h2>A. {t('userAgreement:partiesTitle')}</h2>
                    <p>{t('userAgreement:parties')}</p>
                    <h2>B. {t('userAgreement:corporateInformation')}</h2>
                    <p>{t('userAgreement:tradeTitle')}</p>
                    <p>{t('userAgreement:tradeRegistaryNumber')}</p>
                    <p>{t('userAgreement:address')}</p>
                    <p>{t('userAgreement:contact')}</p>
                    <p>{t('userAgreement:email')}</p>
                    <h2>C. {t('userAgreement:subjectTitle')}</h2>
                    <p>{t('userAgreement:subject')}</p>
                    <h2>D. {t('userAgreement:definitionsTitle')}</h2>
                    <ol>
                        <li>{t('userAgreement:frewell')}</li>
                        <li>{t('userAgreement:webSite')}</li>
                        <li>{t('userAgreement:company')}</li>
                        <li>{t('userAgreement:customer')}</li>
                        <li>{t('userAgreement:customerAccount')}</li>
                        <li>{t('userAgreement:campaign')}</li>
                        <li>{t('userAgreement:services')}</li>
                        <li>{t('userAgreement:payment')}</li>
                        <li>{t('userAgreement:termsOfUse')}</li>
                    </ol>
                    <h2>E. {t('userAgreement:termsOfUseSafetyTitle')}</h2>
                    <ol>
                        <li>{t('userAgreement:termsOfUseSafety_01')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_02')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_03')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_04')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_05')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_06')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_07')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_08')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_09')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_10')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_11')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_12')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_13')}
                            <ul>
                                <li>{t('userAgreement:termsOfUseSafety_13_01')},</li>
                                <li>{t('userAgreement:termsOfUseSafety_13_02')},</li>
                                <li>{t('userAgreement:termsOfUseSafety_13_03')},</li>
                                <li>{t('userAgreement:termsOfUseSafety_13_04')},</li>
                                <li>{t('userAgreement:termsOfUseSafety_13_05')},</li>
                                <li>{t('userAgreement:termsOfUseSafety_13_06')},</li>
                                <li>{t('userAgreement:termsOfUseSafety_13_07')},</li>
                            </ul>
                        </li>
                        <li>{t('userAgreement:termsOfUseSafety_14')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_15')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_16')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_17')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_18')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_19')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_20')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_21')}</li>
                        <li>{t('userAgreement:termsOfUseSafety_22')}</li>
                    </ol>
                    <h2>F. {t('financial', { ns: 'userAgreement' })}</h2>
                    <ol>
                        <li>{t('userAgreement:financial_01')}</li>
                        <li>{t('userAgreement:financial_02')}</li>
                        <li>{t('userAgreement:financial_03')}</li>
                        <li>{t('userAgreement:financial_04')}</li>
                        <li>{t('userAgreement:financial_05')}</li>
                        <li>{t('userAgreement:financial_06')}</li>
                        <li>{t('userAgreement:financial_07')}</li>
                    </ol>
                    <h2>G. {t('userAgreement:personalData')}</h2>
                    <ol>
                        <li>{t('userAgreement:personalData_01')}</li>
                        <li>{t('userAgreement:personalData_02')}</li>
                    </ol>
                    <h2>H. {t('userAgreement:disputeResolution')}</h2>
                    <ol>
                        <li>{t('userAgreement:disputeResolution_01')}</li>
                        <li>{t('userAgreement:disputeResolution_02')}</li>
                    </ol>
                    <h2>I. {t('userAgreement:notifications')}</h2>
                    <ol>
                        <li>
                            {t('userAgreement:notifications_01')}
                        </li>
                        <li> {t('userAgreement:notifications_02')}</li>
                    </ol>
                    <h2>J. {t('userAgreement:evidence')}</h2>
                    <p>{t('userAgreement:evidence_01')}</p>
                    <h2>K. {t('userAgreement:signature')}</h2>
                    <ol>
                        <li>{t('userAgreement:signature_01')}</li>
                        <li>{t('userAgreement:signature_02')}</li>
                    </ol>
                    <h2>L. {t('userAgreement:other')}</h2>
                    <ol>
                        <li>{t('userAgreement:other_01')}</li>
                        <li>{t('userAgreement:other_02')}</li>
                    </ol>
                    <h2>M. {t('userAgreement:refund')}</h2>
                    <ol>
                        <li>{t('userAgreement:refund_01')}</li>
                    </ol>
                </Col>
            </Col>
        </>
    )
}

export default UserAgreementComp