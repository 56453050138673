// antd
import { Col, Form, Input, Row, Select } from 'antd'
import { UserOutlined, MailOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
// components
import Navbar from '../Navbar/Navbar'
import CityDropdown from '../CityDropdown/CityDropdown';
// context
import { useAuth } from "../../contexts/AuthContext";
// helpers
import { getAlertMessage } from '../../helpers/utils/AlertUtils';
import { registerFirebase, sendEmailVerificationFirebase } from '../../helpers/firebase/firebase_user_methods';
// hook
import useNotification from '../../hooks/useNotification';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// react-select-country
import countryList from "react-select-country-list";
// phone-input
import PhoneInput from "react-phone-input-2";
// style
import styles from './RegisterComponent.module.css'


const RegisterComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['register', 'alerts']);
    // antd
    const { Option } = Select;
    // auth context
    const { signup, currentUser } = useAuth();
    // states
    const [newUser, setNewUser] = useState({
        name: "",
        surname: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        age_interval: "",
        gender: "",
        region: "",
        city: "",
    });
    const [newCountryList, setNewCountryList] = useState()
    const [loading, setLoading] = useState(false)
    // react-router-dom
    const navigate = useNavigate();
    // hook
    const { alertError } = useNotification();

    const handleCityChange = (value) => {
        setNewUser({ ...newUser, city: value.trim() });
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        try {
            await signup(newUser.email, newUser.password);
            await registerFirebase(newUser).then(async () => {
                await sendEmailVerificationFirebase();
                //navigate with param register:true
                navigate('/', { state: { register: true } });
            });
        } catch (err) {
            let errorMessage = getAlertMessage(err.code);
            alertError(t(errorMessage, { ns: 'alerts' }));
            setLoading(false)
        }
    };

    useEffect(() => {
        if (currentUser) {
            navigate("/");
        }
        // get country list for register form
        let options = countryList().getData();
        const newCountry = []
        // Turkey's code change with this function
        options.forEach((item) => {

            newCountry.push({ label: item.label, value: item.label })
            if (item.label === 'Turkey') {
                item.value = 'türkiye';
                item.label = 'Türkiye';
                newCountry.push({ label: item.label, value: item.value })
            }
        })
        setNewCountryList(newCountry)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Navbar design={true} />
            <Row className={styles.registerContainer} justify='center' align='middle'>
                <Col className={styles.registerColumn} xxl={6} xl={6} lg={8} md={10} sm={14} xs={20} align='middle'>
                    <h1 className={styles.header}>{t('register:register')}</h1>
                    <p className={styles.formDescription}>{t('register:allfields_required')}</p>
                    <Form
                        name='register form'
                        className={styles.formContainer}
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            className={styles.inputContainer}
                            name='name'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: t('register:name_required'),
                                    },

                                ]
                            }>
                            <Input
                                className={styles.registerInput}
                                size='large'
                                type="text"
                                name="name"
                                id="name"
                                placeholder={t('register:name')}
                                prefix={<UserOutlined />}
                                onChange={(e) => {
                                    let val = e.target.value.trim();
                                    setNewUser({ ...newUser, name: val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() });
                                }}
                                onKeyPress={(event) => {
                                    if (!/[a-zA-ZğüıIşöçİĞÜŞÖÇ\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name='surname'
                            rules={
                                [
                                    {
                                        required: true,
                                        message: t('register:surname_required'),
                                    },

                                ]
                            }>
                            <Input
                                className={styles.registerInput}
                                size='large'
                                type="text"
                                name="surname"
                                id="surname"
                                placeholder={t('register:surname')}
                                prefix={<UserOutlined />}
                                onChange={(e) => {
                                    let val = e.target.value.trim();
                                    setNewUser({ ...newUser, surname: val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() });
                                }}
                                onKeyPress={(event) => {
                                    if (!/[a-zA-ZğüıIşöçİĞÜŞÖÇ\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name='email'
                            rules={[
                                {
                                    type: "email",
                                    message: t('register:email_required_invalid'),
                                },
                                {
                                    required: true,
                                    message: t('register:email_required'),
                                },
                            ]}
                        >
                            <Input
                                className={styles.registerInput}
                                size='large'
                                type="email"
                                name="email"
                                id="email"
                                placeholder={t('register:email')}
                                prefix={<MailOutlined />}
                                onChange={(e) => {
                                    setNewUser({ ...newUser, email: e.target.value.trim() });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name="password"
                            rules={[
                                {
                                    min: 6,
                                    message: t('register:password_required_invalid'),
                                },
                                {
                                    required: true,
                                    message: t('register:password_required'),
                                },
                            ]}
                        >
                            <Input.Password
                                className={styles.registerInput}
                                size='large'
                                placeholder={t('register:password')}
                                prefix={<LockOutlined />}
                                onChange={(e) => {
                                    setNewUser({ ...newUser, password: e.target.value.trim() });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            className={styles.inputContainer}
                            name="confirm"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: t('register:password_again_required'),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error(
                                                t('register:password_again_required_invalid')
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                size='large'
                                className={styles.registerInput}
                                placeholder={t('register:password_again')}
                                prefix={<LockOutlined />}
                                onChange={(e) => {
                                    setNewUser({
                                        ...newUser,
                                        passwordConfirm: e.target.value.trim(),
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.phoneContainer}
                            name="phoneNumber"
                            rules={[
                                {
                                    required: true,
                                    message: t('register:phone_required'),
                                },
                            ]}
                        >
                            <PhoneInput
                                className={styles.phoneInput}
                                country={"tr"}
                                inputProps={{ required: true }}
                                inputStyle={{ width: "100%" }}
                                placeholder={t('register:phone')}
                                onChange={(e) => { setNewUser({ ...newUser, phoneNumber: "+" + e.trim() }); }}
                            ></PhoneInput>
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name="age"
                            rules={[
                                { required: true, message: t('register:age_required') },
                            ]}
                        >

                            <Select
                                size='large'
                                className={styles.ageInput}
                                placeholder={t('register:age')}
                                onChange={(e) => { setNewUser({ ...newUser, age_interval: e }); }}
                            >
                                {/* <Option value="0-18">0-18</Option> */}
                                <Option value="18-25">18-25</Option>
                                <Option value="25-40">25-40</Option>
                                <Option value="40-65">40-65</Option>
                                <Option value="65+">65+</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name="gender"
                            rules={[{ required: true, message: t('register:gender_required') }]}
                        >
                            <Select
                                size='large'
                                className={styles.genderInput}
                                dropdownStyle={styles.dropdown}
                                placeholder={t('register:gender')}
                                onChange={(e) => { setNewUser({ ...newUser, gender: e }); }}
                            >
                                <Option value="Male">{t('register:male')}</Option>
                                <Option value="Female">{t('register:female')}</Option>
                                <Option value="Other">{t('register:none')}</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.inputContainer}
                            name="region"
                            rules={[
                                {
                                    required: true,
                                    message: t('register:country_required'),
                                },
                            ]}
                        >
                            <Select
                                size='large'
                                dropdownStyle={styles.dropdown}
                                className={styles.regionInput}
                                showSearch
                                placeholder={t('register:country')}
                                options={newCountryList}
                                onChange={(e) => {
                                    setNewUser({ ...newUser, region: e });
                                }}
                            />
                        </Form.Item>

                        {newUser.region === "türkiye" && <CityDropdown handleCityChange={handleCityChange} />}
                        <button
                            type="submit"
                            className={styles.registerButton}
                        >
                            {loading ? <LoadingOutlined /> : t('register:register')}
                        </button>
                    </Form>
                    <div className={styles.description}>{t('register:already_registered')} <Link to={'/login'} className={styles.loginLink}>{t('register:login')}</Link></div>
                </Col>
            </Row>
        </>
    )
}

export default RegisterComponent