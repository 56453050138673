import { Modal } from 'antd'
import { Typography } from 'antd';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../components/Footer/Footer'
import FrequencyKnowledge from '../components/FrequencyKnowledge/FrequencyKnowledge'
import Navbar from '../components/Navbar/Navbar'
import News from '../components/News/News'
import ServerErrorComp from '../components/ServerErrorComp/ServerErrorComp'
import TotalUserFeedback from '../components/TotalUserFeedback/TotalUserFeedback'
import UserComment from '../components/UserComment/UserComment'
import WelcomeTitle from '../components/WelcomeTitle/WelcomeTitle'
import WorkSteps from '../components/WorkSteps/WorkSteps'
import useNotification from '../hooks/useNotification'

const { Title } = Typography;

const Home = () => {
    const error = false;
    //i18next for language changing
    const { t } = useTranslation(['register', 'alerts']);
    // state
    const [isModalOpen, setIsModalOpen] = useState(false);
    // hook
    const { alertSuccess } = useNotification();
    // react-router-dom
    const location = useLocation();
    const navigate = useNavigate()

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {

        //if pass state is true, show success alert
        let state = location.state;
        //if previous page is https://frewell.com/register
        if (state?.register) {
            alertSuccess(t('succesfull_register', { ns: 'alerts' }));
            showModal()
            navigate('/', { state: { register: false } });
        }
        // eslint-disable-next-line   
    }, [])
    return (
        <>
            {error ?
                <>
                    <ServerErrorComp />
                </>
                :
                <>
                    <Navbar />
                    <WelcomeTitle />
                    <WorkSteps />
                    <FrequencyKnowledge />
                    <UserComment />
                    <TotalUserFeedback />
                    <News />
                    <Footer />
                </>
            }
            <Modal title={t('gift_alert', { ns: 'alerts' })} footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Title level={4} type='success'>{t('gift_alert_description_01', { ns: 'alerts' })}</Title>
                <br />
                <Title level={5} >{t('gift_alert_description_02', { ns: 'alerts' })}</Title>
            </Modal>
        </>
    )
}

export default Home