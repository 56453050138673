// antd
import { Col, Row } from 'antd'
// components
import LastAddedPackage from '../LastAddedPackage/LastAddedPackage'
import PopularFrequency from '../PopularFrequency/PopularFrequency'
import FrequncySearchInput from '../FrequncySearchInput/FrequncySearchInput'
// style
import styles from './FrequencyKnowledge.module.css'

const FrequencyKnowledge = () => {
    return (
        <Row id='frekansContainerId' className={styles.frekansContainer}>
            <Row className={styles.inputsContainer}>
                <Col xl={11} lg={11} md={12} sm={24} xs={24}>
                    <FrequncySearchInput />
                </Col>
            </Row>
            <LastAddedPackage />
            <PopularFrequency link={true} />
        </Row>
    )
}

export default FrequencyKnowledge