// antd
import { StarFilled, StarTwoTone } from '@ant-design/icons'
import { Card } from 'antd'
// style
import styles from './CommentCard.module.css'

// user came from userCommentContainer
const CommentCard = ({ user }) => {
    // style of stars
    const star = {
        rating: {
            display: "inline-block",
            position: "relative",
            lineHeight: "1em",
            marginBottom: "1.5vw",
            fontSize: "18px",
        },
        filledStarContainer: {
            position: "absolute",
            top: "0",
            left: "0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: `calc(${user.rating} * 100% / 5)`,
        },
        outlinedStar: {
            whiteSpace: "nowrap",
        },
    };
    return (
        <Card className={styles.cardContainer} hoverable={true} bordered={true}>
            <div style={star.rating}>
                <div style={star.filledStarContainer}>
                    <StarFilled className={styles.starFilled} />
                    <StarFilled className={styles.starFilled} />
                    <StarFilled className={styles.starFilled} />
                    <StarFilled className={styles.starFilled} />
                    <StarFilled className={styles.starFilled} />
                </div>
                <div style={star.outlinedStar}>
                    <StarTwoTone className={styles.emptyStar} twoToneColor="#55E6C1" />
                    <StarTwoTone className={styles.emptyStar} twoToneColor="#55E6C1" />
                    <StarTwoTone className={styles.emptyStar} twoToneColor="#55E6C1" />
                    <StarTwoTone className={styles.emptyStar} twoToneColor="#55E6C1" />
                    <StarTwoTone className={styles.emptyStar} twoToneColor="#55E6C1" />
                </div>
            </div>
            <p className={styles.comment}>{user.comment}</p>
            <p className={styles.userName}>{user.user}</p>
            <p className={styles.userCountry}>{user.country}</p>
        </Card>
    )
}

export default CommentCard