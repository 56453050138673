import Footer from '../components/Footer/Footer'
import RegisterComponent from '../components/RegisterComponent/RegisterComponent'

const Register = () => {
    return (
        <>
            <RegisterComponent />
            <Footer />
        </>
    )
}

export default Register