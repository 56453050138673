import React, { useState } from 'react';
import { Bar } from '@ant-design/plots';
import useConstructor from './use_constructor';
import { getDiseasesStats } from '../../helpers/firebase/firebase_admin_methods';
import styles from "./statistic_style.module.css"
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';


const DiseaseChart = (props) => {
    const {t} = useTranslation(['record']);
    const [data, setData] = useState([]);
    const { RangePicker } = DatePicker;
    const config = {
        data,
        xField: 'count',
        yField: 'name',
        seriesField: 'name',
        legend: {
            position: 'top-left',
        },
    };
    useConstructor(async () => {
        await getDiseasesStats(props.data,(Date.now() - 30 * 24 * 60 * 60 * 1000), Date.now()).then((res) => {
            res.forEach((element) => {
                if(data.length === 0){
                    setData((prev) => [...prev, { name: t(element.name), count: element.count }]);
                }else {
                    data.forEach((elem) => {
                        if (elem.name === t(element.name)) {
                            setData((prev) => [...prev.filter((e) => e.name !== t(element.name)), { name: t(element.name), count: element.count }]);
                        }
                    })
                }
                
            });
        });
    });
    return (
        <div>
            <RangePicker onChange={async (date) => {
                    await getDiseasesStats(props.data,date[0]._d.getTime(), date[1]._d.getTime()).then((res) => {
                        res.forEach((element) => {
                            if(data.length === 0){
                                setData((prev) => [...prev, { name: t(element.name), count: element.count }]);
                            }else {
                                data.forEach((elem) => {
                                    if (elem.name === t(element.name)) {
                                        setData((prev) => [...prev.filter((e) => e.name !== t(element.name)), { name: t(element.name), count: element.count }]);
                                    }
                                })
                            }
                            
                        });
                    });
                }} />
            <Bar className={styles.Line} {...config} />;
        </div>
    )
    
};

export default DiseaseChart
