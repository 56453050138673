import React, { useState } from 'react';
import { Line } from '@ant-design/plots';
import styles from "./statistic_style.module.css"
import { DatePicker } from 'antd';
import { getTotalUsers } from '../../helpers/firebase/firebase_admin_methods';
import useConstructor from './use_constructor';

const Income = () => {
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [userNumber, setUserNumber] = useState(0);
  const config = {
    data,
    padding: 'auto',
    xField: 'name',
    yField: 'count',
    xAxis: {
      // type: 'timeCat',
      tickCount: 1,
    },
  };
  useConstructor(async () => {
    //call get total users method with now and 1 month before
    //default dates last 1 month
    setData(await getTotalUsers((Date.now() - 30 * 24 * 60 * 60 * 1000),Date.now()).then((res) => {
      setUserNumber(res[res.length - 1].count);
      return res;
    }));
  })
  return (
    <div>
      <h1>Total Users:{userNumber}</h1>
      <RangePicker onChange={async (date) => {
        setData(await getTotalUsers(date[0]._d.getTime(), date[1]._d.getTime()));
      }}/>
      <Line className={styles.Line} {...config} />
    </div>
  )
};
export default Income;
