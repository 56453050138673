// antd
import { Row, TreeSelect } from 'antd'
// axios
import axios from 'axios'
// helpers
import { getDiseasesEmptyParam } from '../../helpers/firebase/firebase_admin_methods';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// style
import styles from './FrequncySearchInput.module.css'

// antd
const { TreeNode } = TreeSelect;

const FrequncySearchInput = () => {
    //i18next for language changing
    const { t } = useTranslation(['frequencies', 'record']);
    // react-router-dom
    const navigate = useNavigate();
    // states
    const [value, setValue] = useState();
    const [diseaseList, setDiseaseList] = useState();

    const handleChange = (e) => {
        //check the clicked is children or not
        setValue(e);
        //if first 4 letters is main 
        if (e.substring(0, 4) === "main") {
            //do nothing
        } else {
            //if clicked is children
            if (localStorage.getItem('i18nextLng') === 'tr') {
                let newLng = axios(`https://frewell-89fea-default-rtdb.firebaseio.com/record/tr.json`);
                newLng
                    .then((res) => {
                        let recordArray = res.data;
                        Object.entries(recordArray).forEach(((item) => {
                            if (item[1] === e) {
                                navigate(`/app/${item[0]}`)
                            }
                        }))
                    })
            } else {
                navigate(`/app/${e}`)
            }
        }
    }
    useEffect(() => {
        getDiseasesEmptyParam()
            .then((res) => {
                setDiseaseList(res);
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Row className={styles.treeSelectContainer} align='middle' wrap={false}>
            <img className={styles.waveIcon} src="/assets/images/wave-icon.png" alt="wave icon" />
            <TreeSelect
                showSearch
                bordered={false}
                className={styles.treeSelect}
                value={value}
                dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                    borderRadius: '1vw',
                }}
                placeholder={t('browse_category_frequencies', { ns: 'frequencies' })}
                allowClear
                onChange={handleChange}
                treeExpandAction='click'
                virtual={false}
            >
                {
                    diseaseList?.map((mainCategory) => {
                        return (
                            //set on change of this node
                            <TreeNode
                                className={styles.treeNode}
                                value={"main" + mainCategory.category_name}
                                title={t(mainCategory.category_name, { ns: 'record' })}
                                key={"main" + mainCategory.category_name}
                                selectable={false}
                            >
                                {
                                    // eslint-disable-next-line array-callback-return
                                    mainCategory?.diseases?.map((disease) => {
                                        return disease?.status !== "Special" && <TreeNode
                                            className={styles.treeNode}
                                            value={t(disease?.disease_name, { ns: 'record' })}
                                            title={t(disease?.disease_name, { ns: 'record' })}
                                            key={t(disease?.disease_name, { ns: 'record' })}
                                        ></TreeNode>
                                    })
                                }
                            </TreeNode>
                        )
                    })
                }
            </TreeSelect>
        </Row >
    )
}

export default FrequncySearchInput