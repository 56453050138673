// antd
import { Col } from 'antd'
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useNavigate } from 'react-router-dom'
// style
import styles from './SpecialFrequencySendError.module.css'

const SpecialFrequencySendError = () => {
    //i18next for language changing
    const { t } = useTranslation(['errors', 'frequencies']);
    // react-router-dom
    const navigate = useNavigate();

    return (
        <Col className={styles.errorContainer} span={24}>
            <p className={styles.warning}>{t('dont_have_package', { ns: "errors" })}</p>
            <button className={styles.nextButton} onClick={() => {
                //navigate to pricing
                navigate('/frequencies');
            }}> {t("buy_special_package", { ns: "errors" })} </button>
        </Col>
    )
}

export default SpecialFrequencySendError