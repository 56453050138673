import Footer from '../components/Footer/Footer'
import PrivacyPolicyComp from '../components/PrivacyPolicyComp/PrivacyPolicyComp'

const PrivacyPolicy = () => {
    return (
        <>
            <PrivacyPolicyComp />
            <Footer />
        </>
    )
}

export default PrivacyPolicy