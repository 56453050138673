
import React from 'react'
import Footer from '../components/Footer/Footer'
import ProfileComponent from '../components/ProfileComponent/ProfileComponent'

const Profile = () => {
    return (
        <>
            <ProfileComponent />
            <Footer />
        </>
    )
}

export default Profile