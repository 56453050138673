import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    preload: ["en", "tr"],
    backend: {
      loadPath: (lng, ns) => {
        if (ns[0] === "record" || ns[0] === "admin") {
          return "https://frewell-89fea-default-rtdb.firebaseio.com/{{ns}}/{{lng}}.json";
        } else {
          return `/locales/{{lng}}/{{ns}}.json`;
        }
      },
    },
    fallbackLng: localStorage.getItem("i18nextLng") || "en",
    debug: false,
    ns: [
      "navbar",
      "worksteps",
      "register",
      "pricing",
      "frequencies",
      "alerts",
      "total_feedback",
      "app",
      "record",
      "admin",
      "help",
      "news",
      "user_comment",
      "what_is_frewell",
      "errors",
      "requires",
      "userAgreement",
      "privacyPolicy",
      "payment",
    ],
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    supportedLngs: ["en", "tr"],
    react: {
      wait: true,
    },
  });

export default i18n;
