import { Col, Row } from 'antd'
import styles from './RemainingPackage.module.css'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { getRemainingActivePackage } from '../../helpers/firebase/firebase_package_methods'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const RemainingPackage = () => {
    //it is used for changing language
    const { t } = useTranslation(['app', 'record']);
    // states
    const [remainingActivePackage, setRemainingActivePackage] = useState(0);

    useEffect(() => {
        // get user's active package count
        getRemainingActivePackage()
            .then((res) => {
                setRemainingActivePackage(res);
            })
    }, [])

    return (
        <Row span={24} align='middle' justify='space-between'>
            <Col span={8}>
                <p className={remainingActivePackage === 0 ? styles.remainingUsageZero : styles.remainingUsage}>
                    {remainingActivePackage} {t('packages', { ns: "app" })}
                </p>
            </Col>
            <Col span={14}>
                <p className={styles.buyButton}>
                    <Link to={'/frequencies'}>{t('buy_package', { ns: "app" })}</Link>
                </p>
            </Col>
        </Row>
    )
}

export default RemainingPackage