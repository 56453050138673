import Footer from '../components/Footer/Footer'
import FrequenciesComponent from '../components/FrequenciesComponent/FrequenciesComponent'

const Frequencies = () => {
    return (
        <>
            <FrequenciesComponent />
            <Footer />
        </>
    )
}

export default Frequencies