// antd
import { AudioFilled, CheckOutlined, ExclamationCircleOutlined, LoadingOutlined, PauseCircleFilled, ReloadOutlined } from '@ant-design/icons'
import { Col, Collapse, Modal, Row } from 'antd'
// components
import FrequncySearchInput from '../FrequncySearchInput/FrequncySearchInput';
import Timer from '../Timer/Timer';
// helpers
import { getDiseasesEmptyParam } from '../../helpers/firebase/firebase_admin_methods';
import { getDiseaseWithName, uploadBytesFirebaseOne } from "../../helpers/firebase/firebase_voice_methods";
import { getExpirationDate } from '../../helpers/firebase/firebase_package_methods';
// hooks
import useNotification from '../../hooks/useNotification';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useEffect, useRef, useState } from 'react';
import { ReactMic } from 'react-mic';
import { useNavigate } from 'react-router-dom';
// style
import styles from './OneFrequencySend.module.css'

// antd
const { Panel } = Collapse;
const { confirm } = Modal;

const OneFrequencySend = (data) => {
    const navigate = useNavigate();
    //i18next for language changing
    const { t } = useTranslation(['app', 'record', 'alerts', 'requires']);
    // states
    const [expirationDate, setExpirationDate] = useState('');
    const [categories, setCategories] = useState();
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState({
        firstStep: true,
        secondStep: false,
        thirthStep: false,
    })
    const [title, setTitle] = useState({
        firstTitle: true,
        secondTitle: false,
        thirthTitle: false,
    })
    const [voiceRecord, setVoiceRecord] = useState({
        record: false,
        isUploading: false,
        blob: null,
        voiceUrl: "",
    });
    const [timer, setTimer] = useState(0);
    const [selectedDisease, setSelectedDisease] = useState("");
    const [diseaseCategory, setDiseaseCategory] = useState("");
    const [recommendedText, setRecommendedText] = useState("");
    // ref
    const countRef = useRef(null);
    // hook
    const { alertError, alertSuccess } = useNotification();

    useEffect(() => {
        if (data.diseaseNameFromUrl) {
            // if user come from url, this set states
            getDiseaseWithName(data.diseaseNameFromUrl).then((res) => {
                setSelectedDisease(res.disease_name);
                setDiseaseCategory(res.disease_category);
                setSteps({ firstStep: false, secondStep: true })
                setRecommendedText(res.recommended_text);
            });
        }
        getDiseasesEmptyParam()
            .then((res) => {
                // console.log(res)
                return (
                    setCategories(res)
                )
            }).catch()

        getExpirationDate().then((res) => {
            setExpirationDate(res.toLocaleString())
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.diseaseNameFromUrl])
    // if user select disease in app first step, this function work
    const handleDisease = (e) => {
        //get value of e
        const disease = e.target.title;
        getDiseaseWithName(disease).then((res) => {
            setRecommendedText(res.recommended_text);
            setSteps({ firstStep: false, secondStep: true });
            setTitle({ secondTitle: true });
            setSelectedDisease(res.disease_name);
            setDiseaseCategory(res.disease_category);
        });
    }
    // if user enter other input, this function work
    const handleDiseaseForInput = () => {
        setSteps({ firstStep: false, secondStep: true });
        setTitle({ secondTitle: true });
        setSelectedDisease("Custom Frequency");
        setDiseaseCategory("Others");
    }
    //When click start recording button, this function call and timer start ticking!
    const timerStart = () => {
        let count = 0;
        countRef.current = setInterval(() => {
            //set timer plus one and if timer is 20, call error function
            count++;
            setTimer(count);
            if (count === 21) {
                timerStop()
                error();
                setVoiceRecord({
                    ...voiceRecord,
                    record: false,
                });
                //stop get the audio from microphone
            }
        }, 1000);
    };
    //When click stop button, this function call and timer stop!
    const timerStop = () => {
        clearInterval(countRef.current);
    };
    // When click start recording button, this function call!
    const startRecording = () => {
        setVoiceRecord({
            ...voiceRecord,
            record: true,
        })
        timerStart();
    }

    // When click stop recording button, this function call!
    const stopRecording = () => {
        timerStop();
        setVoiceRecord({
            ...voiceRecord,
            record: false,
        });
    };

    const error = () => {
        Modal.error({
            title: t('tenseconds', { ns: "requires" }),
            okText: t('ok', { ns: "requires" }),
            content: (
                <div>
                    <p>{t('try_again', { ns: "requires" })}</p>
                </div>
            ),
            onOk() { window.location.reload(true) },
        });
    };
    // React-Mic stop function
    const onStop = (recordedBlob) => {
        //if blob is null or size is 0, call error function
        if (recordedBlob.blob === null || recordedBlob.blob.size === 0 || recordedBlob.blob.size === undefined || (recordedBlob.startTime === recordedBlob.stopTime)) {
            alert(t('novoice', { ns: "requires" }));
            return;
        }
        // console.log(recordedBlob)
        // control of record duration
        const duration = Number(recordedBlob.stopTime) - Number(recordedBlob.startTime);
        // record duration must be under 20000ms
        if (duration < 21000) {
            setVoiceRecord({
                ...voiceRecord,
                isUploading: true,
                blob: recordedBlob.blob,
                voiceUrl: recordedBlob.blobURL,
            });
            setSteps({ firstStep: false, secondStep: false, thirthStep: true });
            setTitle({ ...title, thirthTitle: true })
        } else {
            setVoiceRecord({
                record: false,
                isUploading: false,
                blob: null,
                voiceUrl: "",
            });
            error();
        }
    };

    // when user send button, this function work
    const uploadingRecord = () => {
        setLoading(true);
        setVoiceRecord({
            record: false,
            isUploading: false,
        });
        uploadBytesFirebaseOne(voiceRecord.blob, diseaseCategory, selectedDisease)
            .then((res) => {
                if (res) {
                    alertSuccess(t('succesfull_upload_one_frequency', { ns: "alerts" }));
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 2000);
                } else {
                    alertError(t('network_error', { ns: "alerts" }));
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 2000);
                }
            })
            .catch(() => {
                alertError(t('network_error', { ns: "alerts" }));
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            })
    };
    // removing credit warning
    const showConfirm = () => {
        confirm({
            title: t('confirm_upload', { ns: "requires" }),
            icon: <ExclamationCircleOutlined />,
            content: t('confirm_upload_one', { ns: "requires" }),
            okText: t('ok', { ns: "requires" }),
            cancelText: t('cancel', { ns: "requires" }),
            onOk() {
                // console.log('OK');
                uploadingRecord();
            },
            onCancel() { },
        });
    };
    // if user clicks step one, user goes back to step one
    const handleFirstStep = () => {
        if (steps.firstStep !== true && steps.thirthStep !== true && steps.secondStep === true) {
            navigate('/app');
            setSteps({
                firstStep: true,
                secondStep: false,
                thirthStep: false,
            });
            setTitle({
                firstTitle: true,
                secondTitle: false,
                thirthTitle: false,
            });
            if (voiceRecord.record) {
                window.location.reload(true);
            }
        }
    }
    return (
        <Col className={styles.stepsContainer} span={24}>
            <Row align='middle' className={styles.expirationWarning}>
                {t('expiration_date_warning', { ns: "app" })}
                <span className={styles.date}>{expirationDate}</span>
            </Row>
            <Row className={styles.firstTitleRow} justify='space-between' align='bottom'>
                <Col span={12}>
                    <p className={styles.firstActiveTitle} onClick={handleFirstStep}>1 - {t('choose_frequency', { ns: "app" })} </p>
                </Col>
                <Col span={12} className={styles.searchInput}>
                    <FrequncySearchInput />
                </Col>
            </Row>
            <Row className={styles.contentRow} justify='space-between'>
                <Col span={1} className={styles.lineColumn}>
                    <p className={styles.line}></p>
                </Col>
                <Col span={23} >
                    {
                        steps.firstStep ?
                            <>
                                <Collapse
                                    accordion
                                    className={styles.collapse}
                                    expandIconPosition='end'
                                    defaultActiveKey={['Energy']}
                                    bordered={false}
                                    ghost={true}>
                                    {
                                        categories?.map((mainCategory) => {
                                            // console.log(mainCategory);
                                            return (
                                                <Panel className={styles.panel} header={t(mainCategory?.category_name, { ns: "record" })} key={mainCategory?.category_name}>
                                                    <Row>
                                                        {
                                                            mainCategory?.diseases?.map((subCategory) => {
                                                                return subCategory?.status !== "Special" && (
                                                                    <Col span={6} key={subCategory?.disease_name} className={styles.subCategoryColumn}>
                                                                        <Row align='top' wrap={false} className={styles.subCategoryRow} >
                                                                            <img className={styles.littleIcon} src="/assets/images/wave-gradient.png" alt="wave icon" />
                                                                            <p className={styles.subCategoryName} onClick={handleDisease} title={subCategory?.disease_name}>{t(subCategory?.disease_name, { ns: "record" })}</p>
                                                                        </Row>
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </Panel>
                                            )
                                        })
                                    }
                                    <Panel className={styles.otherPanel} header={t("create_own_frequency", { ns: "app" })} key='4'>
                                        <Row className={styles.otherInputContainer}>
                                            <button className={styles.otherButton} onClick={handleDiseaseForInput}>
                                                {t("next", { ns: "app" })}
                                            </button>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Row className={styles.mobilSearchInput}>
                                    <FrequncySearchInput />
                                    <Collapse
                                        accordion
                                        className={styles.smallCollapse}
                                        expandIconPosition='end'
                                        ghost={true}
                                    >
                                        <Panel className={styles.otherPanel} key='10' header={t("create_own_frequency", { ns: "app" })}>
                                            <Row className={styles.otherInputContainer}>
                                                <button className={styles.otherButton} onClick={handleDiseaseForInput}>{t("next", { ns: "app" })}</button>
                                            </Row>
                                        </Panel>
                                    </Collapse>
                                </Row>
                            </>
                            :
                            <p className={styles.selectedDiseaseParagraph}><CheckOutlined className={styles.selectedDiseaseIcon} /> <b className={styles.selectedDisease}>"{t(selectedDisease, { ns: "record" })}"</b> {t("choosen", { ns: "app" })}</p>
                    }

                </Col>
            </Row>
            <Row justify='space-between' >
                <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                    <p className={selectedDisease ? styles.activeTitle : styles.passiveTitle}>2 - {t('get_record', { ns: "app" })}</p>
                </Col>
            </Row>
            <Row className={styles.contentRow} justify='space-between'>
                <Col span={1} className={styles.lineColumn}>
                    <p className={styles.line}></p>
                </Col>
                <Col span={23} justify='center' align='middle' className={styles.recordColumn}>
                    {steps.secondStep
                        ?
                        (
                            <Col justify='center' >
                                <ReactMic
                                    record={voiceRecord.record}
                                    className={styles.record}
                                    visualSetting="frequencyBars"
                                    onStop={onStop}
                                    strokeColor="#3e4581"
                                    backgroundColor="#FFFFFF"
                                    mimeType="audio/mp3"
                                />

                                <Timer timer={timer} />
                                <Row className={styles.buttonGroup}>
                                    <button className={styles.startButton} onClick={startRecording} disabled={voiceRecord.record}>
                                        <AudioFilled /> {t('start_record', { ns: "app" })}
                                    </button>
                                    <button className={styles.stopButton} onClick={stopRecording} disabled={!voiceRecord.record}>
                                        <PauseCircleFilled /> {t('stop_record', { ns: "app" })}
                                    </button>
                                </Row>
                                {
                                    diseaseCategory !== "Others" &&
                                    <>
                                        <p className={styles.attention}>{t('package_info', { ns: "app" })}</p>
                                        <p className={styles.diseaseText}>{t(recommendedText, { ns: "record" })}</p>
                                    </>
                                }
                                {
                                    diseaseCategory === "Others" &&
                                    <>
                                        <p className={styles.otherDescription}>{t('other_info', { ns: "app" })}</p>
                                        <p className={styles.otherDiseaseText}>
                                            <p>
                                                {t("recommended_text_empty", { ns: "app" })}
                                            </p>
                                            <b className={styles.otherText}>
                                                {t("recommended_text_example", { ns: "app" })}
                                            </b>
                                        </p>
                                    </>
                                }
                            </Col>
                        )
                        :
                        steps.thirthStep ?
                            <p className={styles.recordIsDone}> <CheckOutlined className={styles.selectedDiseaseIcon} /> {t('done', { ns: "app" })}</p>
                            :
                            <p className={styles.recordWarning}> {t('before_the_record_choose_frequency', { ns: "app" })}</p>
                    }
                </Col>
            </Row>
            <Row justify='space-between' >
                <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                    <p className={title.thirthTitle ? styles.activeTitle : styles.passiveTitle}>3 - {t('check_and_send', { ns: "app" })}</p>
                </Col>
            </Row>
            <Row className={styles.contentRow} justify='space-between'>
                <Col span={1} className={styles.lineColumn}>
                </Col>
                <Col span={23} justify='center' align='middle'>
                    {steps.thirthStep &&
                        <Col align='start' className={styles.audioColumn}>
                            {loading && <LoadingOutlined className={styles.loadingGif} />}
                            <p className={styles.uploadWarning}>{t('send_info', { ns: "app" })}</p>
                            <audio
                                controls
                                controlsList="nodownload"
                                src={voiceRecord.voiceUrl}
                                className={styles.voiceAudio}
                            />
                            {
                                loading ?
                                    <button className={styles.uploadButton} onClick={showConfirm} disabled>
                                        <CheckOutlined /> {t('check_and_send2', { ns: "app" })}
                                    </button>
                                    :
                                    <button className={styles.uploadButton} onClick={showConfirm}>
                                        <CheckOutlined /> {t('check_and_send2', { ns: "app" })}
                                    </button>
                            }
                            <button className={styles.refreshButton} onClick={() => { window.location.reload(true) }}>
                                <ReloadOutlined /> {t('again_record', { ns: "app" })}
                            </button>
                        </Col>
                    }
                </Col>
            </Row>
        </Col>
    )
}

export default OneFrequencySend