// antd
import { Col } from 'antd'
// components
import Navbar from '../Navbar/Navbar'
// i18next
import { useTranslation } from 'react-i18next';
// style
import styles from './PrivacyPolicyComp.module.css'

const PrivacyPolicyComp = () => {
    //i18next for language changing
    const { t } = useTranslation(['privacyPolicy']);
    return (
        <>
            <Navbar design={true} />
            <Col className={styles.privacyContainer} span={24} align='middle'>
                <Col className={styles.page} xxl={20} xl={20} lg={20} md={22} sm={23} xs={23}>
                    <h1>{t('privacyPolicy:mainTitle')}</h1>
                    <h2>{t('privacyPolicy:entry')}</h2>
                    <p>{t('privacyPolicy:entry_01')}</p>
                    <h2>{t('privacyPolicy:personalData')}</h2>
                    <p>{t('privacyPolicy:personalData_01')}</p>
                    <h2>{t('privacyPolicy:applicable')}</h2>
                    <p>{t('privacyPolicy:applicable_01')}</p>
                    <h2>{t('privacyPolicy:collection')}</h2>
                    <p>{t('privacyPolicy:collection_01')}</p>
                    <p>{t('privacyPolicy:collection_02')}</p>
                    <p>{t('privacyPolicy:collection_03')}</p>
                    <p>{t('privacyPolicy:collection_04')}</p>
                    <p>{t('privacyPolicy:collection_05')}</p>
                    <h2>{t('privacyPolicy:securityAndAudit')}</h2>
                    <p>{t('privacyPolicy:securityAndAudit_01')}</p>
                    <h2>{t('privacyPolicy:personalDataStorage')}</h2>
                    <p>{t('privacyPolicy:personalDataStorage_01')}</p>
                    <h2>{t('privacyPolicy:changePolicy')}</h2>
                    <p>{t('privacyPolicy:changePolicy_01')}</p>
                    <h1>{t('privacyPolicy:cookiePolicy')}</h1>
                    <h2>{t('privacyPolicy:cookiePolicyInroduction')}</h2>
                    <p>{t('privacyPolicy:cookiePolicyInroduction_01')}</p>
                    <h2>{t('privacyPolicy:whatCookie')}</h2>
                    <p>{t('privacyPolicy:whatCookie_01')}</p>
                    <h2>{t('privacyPolicy:useCookies')}</h2>
                    <p>{t('privacyPolicy:useCookies_01')}</p>
                    <h2>{t('privacyPolicy:typeOfCookies')}</h2>
                    <p>{t('privacyPolicy:typeOfCookies_01')}</p>
                    <p>{t('privacyPolicy:typeOfCookies_02')}</p>
                    <h2>{t('privacyPolicy:intendOfCookies')}</h2>
                    <p>{t('privacyPolicy:intendOfCookies_01')}</p>
                    <ol type="a">
                        <li>{t('privacyPolicy:intendOfCookies_01_01')}</li>
                        <li>{t('privacyPolicy:intendOfCookies_01_02')}</li>
                        <li>{t('privacyPolicy:intendOfCookies_01_03')}</li>
                        <li>{t('privacyPolicy:intendOfCookies_01_04')}</li>
                    </ol>
                    <h2>{t('privacyPolicy:rejectCookies')}</h2>
                    <p>{t('privacyPolicy:rejectCookies_01')}</p>
                </Col>
            </Col>
        </>
    )
}

export default PrivacyPolicyComp