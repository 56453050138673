import Footer from '../components/Footer/Footer'
import PageNotFoundComp from '../components/PageNotFoundComp/PageNotFoundComp'

const PageNotFound = () => {
    return (
        <>
            <PageNotFoundComp />
            <Footer />
        </>
    )
}

export default PageNotFound