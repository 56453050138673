import ContactComponent from '../components/ContactComponent/ContactComponent'
import Footer from '../components/Footer/Footer'

const Contact = () => {
    return (
        <>
            <ContactComponent />
            <Footer />
        </>
    )
}

export default Contact