import "./App.css";
import "antd/dist/antd.min.css";
import "react-phone-input-2/lib/style.css";
import AppRouter from "./app-router/AppRouter";
import { AuthProvider } from "./contexts/AuthContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useState, useEffect } from "react";

function App() {
  const [lang, setLang] = useState("tr");
  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));
  }, []);

  return (
    <HelmetProvider>
      <AuthProvider>
        <Helmet>
          <html lang={lang} />
          {lang === "en" ? (
            <meta
              name="description"
              lang="en"
              content="Frewell is a quantum-based digital software. It has been developed for the users to adapt to the frequencies they wish and to stimulate their frequencies and thoughts. For example; Frewell tries to help them solve many problems based on concentration of thoughts such as weight, appetite control, alcohol, smoking, involuntary clenching, sleep problems, lack of self-confidence, panic attacks, depressive disorders. Frewell can never be used for therapeutic purposes."
            />
          ) : (
            <meta
              name="description"
              lang="tr"
              content="Frewell, kuantum tabanlı dijital bir yazılımdır. Kullanıcılarının diledikleri frekanslara uyumlanmaları ve frekanslarının, düşüncelerinin uyarımları için geliştirilmiştir. Örneğin; kilo, iştah kontrolü, alkol, sigara,  istemsiz diş sıkma, uyku sorunu, özgüven eksikliği, panik atak, depresif bozukluklar gibi daha bir çok düşünce yoğunluğu zeminli  problemleri çözmelerine yardımcı olmaya çalışmaktadır. Frewell kesinlikle tedavi amaçlı kullanılamaz."
            />
          )}
        </Helmet>
        <AppRouter />
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
