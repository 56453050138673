// style
import styles from "./Timer.module.css"

// record time formating
const formatTime = (timer) => {
    const getSeconds = `0${(timer % 60)}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)

    // console.log(timer);
    return `${getMinutes} : ${getSeconds}`
}

const Timer = ({ timer }) => {
    return (
        <div className={styles.timerContainer}>
            <div>
                <p className={styles.timer}>
                    {formatTime(timer)}
                </p>
            </div>
        </div>
    );
}


export default Timer;
