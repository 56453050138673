/* eslint-disable no-loop-func */
import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, fireStorage } from "./firebase";
import { getDiseaseWithName } from "./firebase_voice_methods";

//To get gender details of all user in given week order
export const getGenderStats = async (first_date, last_date) => {
  let genderDetailsFromFirebase = [];

  let firstDateTemp = new Date(first_date);
  let lastDateTemp = new Date(last_date);

  let q = query(
    collection(db, "register_details"),
    where("gender", "==", "Male"),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  let documentSnapshots = await getDocs(q);
  genderDetailsFromFirebase.push({
    name: "Male",
    count: documentSnapshots.size,
  });

  q = query(
    collection(db, "register_details"),
    where("gender", "==", "Female"),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  genderDetailsFromFirebase.push({
    name: "Female",
    count: documentSnapshots.size,
  });

  q = query(
    collection(db, "register_details"),
    where("gender", "==", "Other"),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  genderDetailsFromFirebase.push({
    name: "Other",
    count: documentSnapshots.size,
  });

  return genderDetailsFromFirebase;
};
//To get rating details of all feedbacks in given week order

export const getRatingStats = async (first_date, last_date) => {
  let ratingDetailsFromFirebase = [];

  let firstDateTemp = new Date(first_date);
  let lastDateTemp = new Date(last_date);

  let q = query(
    collection(db, "feedback"),
    where("value", "==", 1),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  let documentSnapshots = await getDocs(q);
  ratingDetailsFromFirebase.push({ name: "⭐", count: documentSnapshots.size });

  q = query(
    collection(db, "feedback"),
    where("value", "==", 2),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  ratingDetailsFromFirebase.push({
    name: "⭐⭐",
    count: documentSnapshots.size,
  });

  q = query(
    collection(db, "feedback"),
    where("value", "==", 3),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  ratingDetailsFromFirebase.push({
    name: "⭐⭐⭐",
    count: documentSnapshots.size,
  });

  q = query(
    collection(db, "feedback"),
    where("value", "==", 4),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  ratingDetailsFromFirebase.push({
    name: "⭐⭐⭐⭐",
    count: documentSnapshots.size,
  });

  q = query(
    collection(db, "feedback"),
    where("value", "==", 5),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  ratingDetailsFromFirebase.push({
    name: "⭐⭐⭐⭐⭐",
    count: documentSnapshots.size,
  });

  return ratingDetailsFromFirebase;
};

//To get age details of all user in given week order
export const getAgeStats = async (first_date, last_date) => {
  let ageDetailsFromFirebase = [];

  let firstDateTemp = new Date(first_date);
  let lastDateTemp = new Date(last_date);

  let q = query(
    collection(db, "register_details"),
    where("age_interval", "==", "0-18"),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  let documentSnapshots = await getDocs(q);
  ageDetailsFromFirebase.push({ name: "0-18", count: documentSnapshots.size });

  q = query(
    collection(db, "register_details"),
    where("age_interval", "==", "18-25"),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  ageDetailsFromFirebase.push({ name: "18-25", count: documentSnapshots.size });

  q = query(
    collection(db, "register_details"),
    where("age_interval", "==", "25-40"),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  ageDetailsFromFirebase.push({ name: "25-40", count: documentSnapshots.size });

  q = query(
    collection(db, "register_details"),
    where("age_interval", "==", "40-65"),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  ageDetailsFromFirebase.push({ name: "40-65", count: documentSnapshots.size });

  q = query(
    collection(db, "register_details"),
    where("age_interval", "==", "65+"),
    where("created_at", ">=", firstDateTemp),
    where("created_at", "<=", lastDateTemp)
  );
  documentSnapshots = await getDocs(q);
  ageDetailsFromFirebase.push({ name: "65+", count: documentSnapshots.size });

  return ageDetailsFromFirebase;
};

//To get disease statistics for admin panel according to the disease category
export const getDiseaseCategoryStats = async (first_date, last_date) => {
  let diseaseCategories = [];
  let diseaseCategoryFromFirebase = [];

  let firstDateTemp = new Date(first_date);
  let lastDateTemp = new Date(last_date);

  let q = query(collection(db, "disease_category"));
  let documentSnapshots = await getDocs(q);
  diseaseCategories = documentSnapshots.docs.map(
    (doc) => doc.data().category_name
  );

  for (let i = 0; i < diseaseCategories.length; i++) {
    q = query(
      collection(db, "voices"),
      where("disease_category", "==", diseaseCategories[i]),
      where("created_at", ">=", firstDateTemp),
      where("created_at", "<=", lastDateTemp)
    );
    documentSnapshots = await getDocs(q);
    diseaseCategoryFromFirebase.push({
      name: diseaseCategories[i],
      count: documentSnapshots.size,
    });
  }
  return diseaseCategoryFromFirebase;
};
export const getDiseaseCategories = async () => {
  let diseaseCategories = [];

  let q = query(collection(db, "disease_category"));
  let documentSnapshots = await getDocs(q);
  documentSnapshots.docs.map((doc) => doc.data().category_name);
  documentSnapshots.docs.forEach((doc) => {
    if (doc.data().category_name !== "Tics") {
      diseaseCategories.push(doc.data().category_name);
    }
  });
  return diseaseCategories;
};
//To get total income for admin panel statistics
export const getIncome = async (first_date, last_date) => {
  let totalIncomeFromFirebase = [];

  //get purchased packages, if purchase history array is not empty
  let q = query(
    collection(db, "purchased_packages"),
    where("purchase_history", "!=", [])
  );
  let documentSnapshots = await getDocs(q);

  let weekDifference = (last_date - first_date) / 4;
  let totalIncome = 0;
  for (let i = 3; i >= 0; i--) {
    let lastDateMinusIthWeekDifference = last_date - weekDifference * i;
    let lastDateMinusIPlusOnethWeekDifference =
      i === 3 ? 0 : last_date - weekDifference * (i + 1);
    //To get 1 week data - Determine first date and last date

    documentSnapshots.forEach((doc) => {
      let pushDate = new Date(lastDateMinusIthWeekDifference);
      //If the data between these dates is present in the purchase history array, then add the price to the total income
      for (let j = 0; j < doc.data().purchase_history.length; j++) {
        if (
          doc.data().purchase_history[j].created_at?.seconds * 1000 <=
            lastDateMinusIthWeekDifference &&
          doc.data().purchase_history[j].created_at?.seconds * 1000 >=
            lastDateMinusIPlusOnethWeekDifference
        ) {
          if (doc.data().purchase_history[j].status !== "gift") {
            //get price as a integer
            totalIncome += parseInt(doc.data().purchase_history[j].price);
          }
        }
      }
      let check = false;

      //If the data with this date is exist in the purchase history array, then add the price to the total income
      //If not, then push to array
      if (totalIncome > 0) {
        totalIncomeFromFirebase.forEach((element) => {
          if (element.name === pushDate.toDateString()) {
            element.count = totalIncome;
            check = true;
          }
        });
        if (!check) {
          totalIncomeFromFirebase.push({
            name: pushDate.toDateString(),
            count: totalIncome,
          });
        }
      }
    });
  }
  return totalIncomeFromFirebase;
};

//To get total user number
export const getTotalUsers = async (first_date, last_date) => {
  let totalUsersFromFirebase = [];
  let totalUsers = 0;
  let weekDifference = (last_date - first_date) / 4;

  for (let i = 3; i >= 0; i--) {
    let lastDateMinusIthWeekDifference = last_date - weekDifference * i;
    let lastDateMinusIPlusOnethWeekDifference =
      last_date - weekDifference * (i + 1);
    if (i === 3) {
      let q = query(
        collection(db, "register_details"),
        where("created_at", "<=", new Date(lastDateMinusIthWeekDifference))
      );
      let documentSnapshots = await getDocs(q);
      totalUsers += documentSnapshots.size;
    }
    let q = query(
      collection(db, "users"),
      where(
        "created_at",
        ">=",
        new Date(lastDateMinusIPlusOnethWeekDifference)
      ),
      where("created_at", "<=", new Date(lastDateMinusIthWeekDifference))
    );
    let documentSnapshots = await getDocs(q);
    totalUsers += documentSnapshots.size;
    totalUsersFromFirebase.push({
      name: new Date(lastDateMinusIthWeekDifference).toDateString(),
      count: totalUsers,
    });
  }
  return totalUsersFromFirebase;
};

export const getDiseasesStats = async (
  disease_category,
  first_date,
  last_date
) => {
  let diseases = [];
  let diseasesFromFirebase = [];

  let firstDateTemp = new Date(first_date);
  let lastDateTemp = new Date(last_date);

  let q = query(
    collection(db, "voices"),
    where("disease_category", "==", disease_category)
  );
  let documentSnapshots = await getDocs(q);
  documentSnapshots.docs.forEach((doc) => {
    if (diseases.indexOf(doc.data().disease_name) === -1) {
      diseases.push(doc.data().disease_name);
    }
  });
  for (let i = 0; i < diseases.length; i++) {
    let q = query(
      collection(db, "voices"),
      where("disease_name", "==", diseases[i]),
      where("created_at", ">=", firstDateTemp),
      where("created_at", "<=", lastDateTemp)
    );
    let documentSnapshots = await getDocs(q);
    diseasesFromFirebase.push({
      name: diseases[i],
      count: documentSnapshots.size,
    });
  }

  return diseasesFromFirebase;
};

//To get region details of all users
export const getRegionUsersStats = async () => {
  let regionUsersFromFirebase = [];
  let regions = [];

  let q = query(collection(db, "register_details"));
  let documentSnapshots = await getDocs(q);
  documentSnapshots.docs.forEach((doc) => {
    if (regions.indexOf(doc.data().region) === -1) {
      regions.push(doc.data().region);
    }
  });
  for (let i = 0; i < regions.length; i++) {
    let q = query(
      collection(db, "register_details"),
      where("region", "==", regions[i])
    );
    let documentSnapshots = await getDocs(q);
    regionUsersFromFirebase.push({
      name: regions[i],
      count: documentSnapshots.size,
    });
  }
  return regionUsersFromFirebase;
};

//To get city details of all users
export const getCityUsersStats = async () => {
  let cityUsersFromFirebase = [];
  let cities = [];

  let q = query(collection(db, "register_details"));
  let documentSnapshots = await getDocs(q);
  documentSnapshots.docs.forEach((doc) => {
    if (cities.indexOf(doc.data().city) === -1) {
      cities.push(doc.data().city);
    }
  });
  for (let i = 0; i < cities.length; i++) {
    let q = query(
      collection(db, "register_details"),
      where("city", "==", cities[i])
    );
    let documentSnapshots = await getDocs(q);
    cityUsersFromFirebase.push({
      name: cities[i],
      count: documentSnapshots.size,
    });
  }
  return cityUsersFromFirebase;
};
//To add new disease to database from admin panel
export const putDisease = async (disease) => {
  //control is there any disease category with same name
  let q = query(
    collection(db, "disease_category"),
    where("category_name", "==", disease.disease_category)
  );
  let documentSnapshots = await getDocs(q);
  let refId = documentSnapshots.docs[0].id;
  let ref = doc(db, "disease_category", refId);

  await updateDoc(ref, {
    diseases: arrayUnion({
      disease_name: disease.disease_name,
      recommended_text: disease.recommended_text,
      status: "Normal",
    }),
  });
};

export const putDiseaseCategory = async (disease_category) => {
  await addDoc(collection(db, "disease_category"), {
    category_name: disease_category.disease_category,
    diseases: [],
  });
};
//Get all diseases to show frontend with disease_category
export const getDiseases = async (disease_category) => {
  let diseases = [];

  let q = query(
    collection(db, "disease_category"),
    where("category_name", "==", disease_category)
  );
  let documentSnapshots = await getDocs(q);
  documentSnapshots.docs.forEach((doc) => {
    doc.data().diseases.forEach((disease) => {
      diseases.push({
        category_name: disease_category,
        disease_name: disease.disease_name,
        status: disease.status,
      });
    });
  });
  return diseases;
};

//Get number of diseases
export const getNumberOfDiseas = async () => {
  let size = 0;
  const querySnapshot = await getDocs(collection(db, "disease_category"));
  querySnapshot.forEach((doc) => {
    size += doc.data().diseases?.length;
  });
  return size;
};

export const getDiseasesEmptyParam = async () => {
  let diseases = [];

  let q = query(collection(db, "disease_category"));
  let documentSnapshots = await getDocs(q);
  documentSnapshots.docs.forEach((doc) => {
    if (doc.data().category_name !== "Tics") {
      diseases.push({
        category_name: doc.data().category_name,
        diseases: doc.data().diseases,
      });
    }
  });
  return diseases;
};

export const getDiseasesEmptyParamForDiseaseTable = async () => {
  let diseases = [];

  let q = query(collection(db, "disease_category"));
  let documentSnapshots = await getDocs(q);
  documentSnapshots.docs.forEach((doc) => {
    doc.data().diseases.forEach((disease) => {
      diseases.push({
        category_name: doc.data().category_name,
        disease_name: disease.disease_name,
      });
    });
  });
  return diseases;
};

//don't use this function, it can not pass security rules
export const putDiseaseCategoryToLocalization = async (disease) => {
  fetch("https://frewell-89fea-default-rtdb.firebaseio.com/record/en.json", {
    method: "PATCH",
    body: JSON.stringify({
      [disease.disease_category]: disease.disease_category,
    }),
  });
  fetch("https://frewell-89fea-default-rtdb.firebaseio.com/record/tr.json", {
    method: "PATCH",
    body: JSON.stringify({
      [disease.disease_category]: disease.hastalık_kategorisi,
    }),
  });
};

//When admin create a new frequency, this function will be called to add new frequency localization data to realtime database
export const putDiseaseToLocalization = async (disease) => {
  // fetch("https://frewell-89fea-default-rtdb.firebaseio.com/record/en.json", {
  //   method: "PATCH",
  //   body: JSON.stringify({
  //     [disease.disease_name]: disease.disease_name,
  //     [disease.recommended_text]: disease.recommended_text,
  //   }),
  // });
  // fetch("https://frewell-89fea-default-rtdb.firebaseio.com/record/tr.json", {
  //   method: "PATCH",
  //   body: JSON.stringify({
  //     [disease.disease_name]: disease.hastalık_adı,
  //     [disease.recommended_text]: disease.önerilen_metin,
  //   }),
  // });
  const functions = getFunctions();
  const putLocalization = httpsCallable(
    functions,
    "createFrequencyLocalization"
  );
  let result = await putLocalization({ disease: disease });
  return result;
};

export const getAllUsers = async () => {
  let users = [];
  const querySnapshot = await getDocs(collection(db, "users"));
  querySnapshot.forEach((doc) => {
    users.push(doc.data());
  });
  let returnPackages = [];
  for (let i = 0; i < users.length; i++) {
    let documentSnapshot = await getDoc(
      doc(db, "purchased_packages", users[i].uid)
    );
    returnPackages.push({
      uid: users[i].uid,
      email: users[i].email,
      expiration_date: new Date(
        documentSnapshot.data().expiration_date?.seconds * 1000
      ).toLocaleDateString(),
      remaining_credit: documentSnapshot.data().remaining_credit,
    });
  }
  return returnPackages;
};

//to increase/decrease expiration date of specific user from admin panel
export const updateExpirationDateOfUser = async (user_id, value) => {
  //value is new date string like dd/mm/yyyy
  let ref = doc(db, "purchased_packages", user_id);
  await updateDoc(ref, {
    expiration_date: new Date(value),
  });

  // //increase expiration date of user 1 day
  // let documentSnapshot = await getDoc(doc(db, "purchased_packages", user_id));
  // let ref = doc(db, "purchased_packages", user_id);
  // if (documentSnapshot.data().expiration_date) {
  //   await updateDoc(ref, {
  //     expiration_date: new Date(
  //       documentSnapshot.data().expiration_date.seconds * 1000 +
  //         value * 24 * 60 * 60 * 1000
  //     ),
  //   });
  // } else {
  //   await updateDoc(ref, {
  //     expiration_date: new Date(Date.now() + value * 24 * 60 * 60 * 1000),
  //   });
  // }
};
// to increase/decrease remaining credit of user from admin panel
export const updateRemainingCreditOfUser = async (user_id, value) => {
  //increase remaining credit of user by one
  let ref = doc(db, "purchased_packages", user_id);
  await updateDoc(ref, {
    remaining_credit: value,
  });
};

// to create special package from admin panel
export const createSpecialPackage = async (special_package) => {
  let urlRef = "";
  //upload image ref
  const storageRef = ref(
    fireStorage,
    "special_packages_img/" + special_package.package_name + ".jpg"
  );

  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, special_package.image);
  await getDownloadURL(storageRef).then((url) => {
    urlRef = url;
  });

  //get attribute names
  // traverse in special_package attributes
  var keys = Object.keys(special_package);
  //get starts with disease_sub_
  var disease_sub = keys.filter((key) => key.startsWith("disease_sub_"));
  //get diseases with name
  var diseases = [];
  for (let i = 0; i < disease_sub.length; i++) {
    //get disease object with name
    await getDiseaseWithName(special_package[disease_sub[i]]).then(
      (disease) => {
        diseases.push({
          step_name: disease.disease_name,
          recommenned_text: disease.recommended_text,
        });
      }
    );
  }
  await addDoc(collection(db, "special_packages"), {
    package_name: special_package.package_name,
    credit_value: parseInt(special_package.price),
    disease_category: special_package.disease_category,
    hour_limit: parseInt(special_package.hours),
    img_url: urlRef,
    steps: diseases,
    is_active: false,
  });
  await createSpecialPackageDetails(special_package, urlRef);
};

export const createSpecialPackageDetails = async (special_package, url) => {
  //get attribute names
  // traverse in special_package attributes
  var keys = Object.keys(special_package);
  //get starts with subtitle_en_ sort by number
  var subtitle_en = keys
    .filter((key) => key.startsWith("subtitle_en_"))
    .sort((a, b) => a.split("_")[2] - b.split("_")[2]);

  //get starts with subtitle_tr_ sort by number
  /* eslint-disable no-unused-vars */
  var subtitle_tr = keys
    .filter((key) => key.startsWith("subtitle_tr_"))
    .sort((a, b) => a.split("_")[2] - b.split("_")[2]);
  //get starts with paragraph_en_ sort by number
  var paragraph_en = keys
    .filter((key) => key.startsWith("paragraph_en_"))
    .sort((a, b) => a - b);
  //get starts with paragraph_tr_ sort by number
  var paragraph_tr = keys
    .filter((key) => key.startsWith("paragraph_tr_"))
    .sort((a, b) => a - b);
  //get starts with disease_sub_
  var disease_sub = keys.filter((key) => key.startsWith("disease_sub_"));

  var paragraphs = [];
  //combine subtitle and paragraph as a one object
  for (let i = 0; i < subtitle_en.length; i++) {
    paragraphs.push({
      subtitle: special_package[subtitle_en[i]],
      text: special_package[paragraph_en[i]],
    });
  }
  //get document of special package details
  const querySnapshot = await getDocs(
    collection(db, "special_package_details")
  );

  let document = querySnapshot.docs[0];
  let ref = doc(db, "special_package_details", document.id);
  //update array
  await updateDoc(ref, {
    special_packages: arrayUnion({
      package_name: special_package.package_name,
      price: parseInt(special_package.price),
      paragraphs: paragraphs,
      //array union frequencies array all disease_sub values
      frequencies: disease_sub.map((key) => special_package[key]),
      img_url: url,
    }),
  });
};

export const controlForDiseaseNameAndRecom = (disease_name) => {
  //make first letter of first word uppercase
  let disease_name_uppercase =
    disease_name.charAt(0).toUpperCase() + disease_name.slice(1);
  //if there is "/" change this with "-"
  if (disease_name_uppercase.includes("/")) {
    disease_name_uppercase = disease_name_uppercase.replace("/", "-");
  }
  return disease_name_uppercase;
};
//get contact messages order by created_at to show in admin panel
export const getContactMessages = async () => {
  //query get contact with order by created_at
  const q = query(collection(db, "contact"), orderBy("created_at", "desc"));
  //get all contact messages
  const querySnapshot = await getDocs(q);
  let messages = [];
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    messages.push({
      id: querySnapshot.docs[i].id,
      ...querySnapshot.docs[i].data(),
    });
  }
  return messages;
};

//delete given contact message in admin panel
export const deleteContactMessages = async (doc_id) => {
  await deleteDoc(doc(db, "contact", doc_id));
};

//get all newsletters order by created_at to show in admin panel
export const getNewsletters = async () => {
  const q = query(collection(db, "newsletter"), orderBy("created_at", "desc"));
  const querySnapshot = await getDocs(q);
  let newsletters = [];
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    let date = querySnapshot.docs[i].data().created_at.toDate();
    newsletters.push({
      id: querySnapshot.docs[i].id,
      email: querySnapshot.docs[i].data().email,
      created_at: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
    });
  }
  return newsletters;
};
