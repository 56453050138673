import React, { useState } from "react";
import styles from "../Create.module.css";
import { Button, Form, Col, Row, Card, Select, Input } from "antd";
// import useNotification from "../../components/usemessage/UseMessage";
import { useEffect } from "react";
import {
  getAllPackages, updatePackagePriceWithDate,
} from "../../helpers/firebase/firebase_package_methods";
import useNotification from "../../hooks/useNotification";

const Packages = () => {
  const { alertSuccess, alertError } = useNotification();
  const [packageRef, setPackage] = useState();
  const [packages, setPackages] = useState([]);
  const { Option } = Select;
  const handleSubmit = async () => {
    try {
      await updatePackagePriceWithDate(packageRef.package_name, packageRef.package_price);
      alertSuccess("Paket Güncellendi");
    } catch (err) {
      alertError(`${err}`);
    }
  };
  useEffect(() => {
    //call async function
    const getPackage = async () => {
      await getAllPackages().then((res) => {
        setPackages(res);
      });
    }
    getPackage();
  }, []);
  return (
    <div className={styles.container}>
      <Row className={styles.rowstyle}>
        <Col>
          <Card>
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="package_name"
                label="Package Name"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input package!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a package!"
                  onChange={(value) => {
                    setPackage({ ...packageRef, package_name: value });
                  }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {
                    packages.map((packageDoc, index) => {
                      return <Option value={packageDoc.date} key={index}>{packageDoc.date}</Option>;
                    })
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name="package_price"
                label="Package Price"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input package price!",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Package Price"
                  onChange={(e) => {
                    setPackage({ ...packageRef, package_price: e.target.value });
                  }}
                />
              </Form.Item>
              <Form.Item className={styles.loginButton}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginformbutton}
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Packages;
