// antd
import { Col, Table } from 'antd';
// helpers
import { getAllPurchasedPackages } from '../../helpers/firebase/firebase_package_methods';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { useEffect, useState } from 'react';
// style
import styles from './PurchaseHistory.module.css'

const PurchaseHistory = () => {
    //i18next for language changing
    const { t } = useTranslation(['app', 'record']);
    // state
    const [data, setData] = useState([]);
    // antd
    const columns = [
        {
            title: t('date', { ns: 'app' }),
            dataIndex: 'date',
        },
        {
            title: t('value', { ns: 'app' }),
            dataIndex: 'value',
            render: (value) => {
                return (
                    parseInt(value) ?
                        <p>{value} {t('frekans', { ns: 'app' })}</p>
                        : <p>{value}</p>
                )
            }
        },
        {
            title: t('price', { ns: 'app' }),
            dataIndex: 'price',
            render: (text) => <p>{text} $</p>,
        },
    ];

    useEffect(() => {
        //call async function
        const getData = async () => {
            await getAllPurchasedPackages().then((res) => {
                //use i18 t function to translate
                //if item.value can be parse to int, then use t function to translate
                //else use item.value
                let data = res.map((item) => {
                    if (parseInt(item.value)) {
                        return {
                            date: item.date,
                            value: item.value,
                            price: item.price,
                            key: Math.random().toString(),
                        }
                    } else {
                        return {
                            date: item.date,
                            value: t(item.value, { ns: 'record' }),
                            price: item.price,
                            key: Math.random().toString(),
                        }
                    }
                })
                setData(data);
            });
        };
        getData();
    }, [t]);
    return (
        <Col className={styles.historyContainer}>
            <h1 className={styles.purchaseHistoryHeader}>{t('purchase_history', { ns: 'app' })}</h1>
            <Table columns={columns} dataSource={data} size="middle" rowKey={(record) => record.key} />
        </Col>
    )
}

export default PurchaseHistory