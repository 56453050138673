
import { Pie } from "@ant-design/plots";
import React, { useState } from "react";
import { getRatingStats } from '../../helpers/firebase/firebase_admin_methods';
import useConstructor from "./use_constructor";
import styles from "./statistic_style.module.css"
import { useTranslation } from "react-i18next";
import { Button, Card, Col, DatePicker, Row } from "antd";
import { getRatingsWithValueByLimit } from "../../helpers/firebase/firebase_feedback_methods";

const RatingChart = () => {
    const { t } = useTranslation(['admin']);
    const [data, setData] = useState([]);
    const { RangePicker } = DatePicker;
    const [feedbacks, setFeedbacks] = useState([]);
    const config = {
        appendPadding: 10,
        data,
        angleField: "count",
        colorField: "name",
        radius: 0.75,
        label: {
            type: "spider",
            labelHeight: 28,
            content: "{name}\n{percentage}",
        },
        interactions: [
            {
                type: "element-selected",
            },
            {
                type: "element-active",
            },
        ],
    };

    useConstructor(async () => {
        await getRatingStats((Date.now() - 30 * 24 * 60 * 60 * 1000), Date.now()).then((res) => {
            setData(res);
        });
    });

    return (
        <div className="App">
            <h2>{t("rating")}</h2>
            <div>
                <RangePicker onChange={async (date) => {
                    await getRatingStats(date[0]._d.getTime(), date[1]._d.getTime()).then((res) => {
                        setData(res);
                    });
                }} />
                <Pie className={styles.Pie} {...config} />
                <Button onClick={async () => {
                    await getRatingsWithValueByLimit(1, 30).then((res) => {
                        setFeedbacks(res);
                    });
                }}>
                    1 Yıldız
                </Button>
                <Button onClick={async () => {
                    await getRatingsWithValueByLimit(2, 30).then((res) => {
                        setFeedbacks(res);
                    });
                }}>
                    2 Yıldız
                </Button>
                <Button onClick={async () => {
                    await getRatingsWithValueByLimit(3, 30).then((res) => {
                        setFeedbacks(res);
                    });
                }}>
                    3 Yıldız
                </Button>
                <Button onClick={async () => {
                    await getRatingsWithValueByLimit(4, 30).then((res) => {
                        setFeedbacks(res);
                    });
                }}>
                    4 Yıldız
                </Button>
                <Button onClick={async () => {
                    await getRatingsWithValueByLimit(5, 30).then((res) => {
                        setFeedbacks(res);
                    });
                }}>
                    5 Yıldız
                </Button>
                <Row justify="center" gutter={16}>
                    {
                        feedbacks?.map((item, index) => {
                            return (
                                <Col lg={7} sm={24} key={index} justify="start">
                                    <Card hoverable={true} style={{textAlign:"start"}}>
                                        <p><strong>User Name: </strong>{item.name + " " + item.surname}</p>
                                        <p><strong>Disease Name: </strong>{item.disease_name}</p>
                                        <p><strong>Feedback Text: </strong>{item.feedback_text}</p>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export default RatingChart