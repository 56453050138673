// antd
import { Col, Row, Carousel } from 'antd'
// components
import SpecialPackageCard from '../SpecialPackageCard/SpecialPackageCard'
// helper
import { getAllSpecialPackages } from '../../helpers/firebase/firebase_package_methods'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// style
import styles from './LastAddedPackage.module.css'

const LastAddedPackage = () => {
    //i18next for language changing
    const { t } = useTranslation(['frequencies']);
    // states
    const [specialPackages, setSpecialPackages] = useState([]);

    useEffect(() => {
        const getData = async () => {
            setSpecialPackages(await getAllSpecialPackages());
        }
        getData();
    }, [])

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2500,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 438,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    }
    return (
        <Col className={styles.lastAddedContainer} align='middle'>
            <Row className={styles.header} justify='space-between' align='middle'>
                <h1>{t('frequencies:frequencies_packs')}</h1>
                <Link to='/frequencies' className={styles.descriptionLink}>{t('frequencies:explore_all_frequencies')}</Link>
            </Row>
            <Carousel {...settings}>
                {
                    specialPackages.map((specialPackage, index) => {
                        return (
                            specialPackage.is_active &&
                            <Col span={24} key={index}>
                                <SpecialPackageCard specialPackage={specialPackage} />
                            </Col>
                        )
                    })
                }
            </Carousel>
        </Col>
    )
}

export default LastAddedPackage