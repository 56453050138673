import Footer from '../components/Footer/Footer'
import UserAgreementComp from '../components/UserAgreementComp/UserAgreementComp'

const UserAgreement = () => {
    return (
        <>
            <UserAgreementComp />
            <Footer />
        </>
    )
}

export default UserAgreement