// antd
import { Col, Row } from 'antd'
// context
import { useAuth } from '../../contexts/AuthContext';
// helpers
// import { getNumberOfDiseas } from '../../helpers/firebase/firebase_admin_methods';
// i18next
import { useTranslation } from 'react-i18next';
// react
import { Link } from 'react-router-dom';
// react-countup
import { useCountUp } from "react-countup";
// style
import styles from './TotalUserFeedback.module.css'

const TotalUserFeedback = () => {
    //i18next for language changing
    const { t } = useTranslation(['total_feedback']);
    // auth context
    const { currentUser } = useAuth()

    useCountUp({
        ref: "percentage",
        end: 95,
        enableScrollSpy: true,
        scrollSpyOnce: true,
        scrollSpyDelay: 3
    });

    return (
        <Row id='totalUserContainerId' className={styles.container} align='middle' justify='center'>
            <Row className={styles.statisticsNumberContainer} justify='center' align='middle'>
                <Col xxl={5} xl={5} lg={5} md={24} sm={24} xs={24} className={styles.frequencyCount} align='middle'>
                    <div className={styles.frequencyCountNumber}>
                        <img src="https://img.icons8.com/external-becris-lineal-becris/64/FFFFFF/external-infinity-mintab-for-ios-becris-lineal-becris.png" alt='infinite' />
                    </div>
                    <div className={styles.frequencyCountDescription}>
                        {t('total_feedback:frequency_number')}
                    </div>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={24} sm={24} xs={24} className={styles.totalUser} align='middle'>
                    <div className={styles.totalUserNumber}>
                        {t("total_feedback:million")}
                    </div>
                    <div className={styles.totalUserDescription}>
                        {t('total_feedback:frewell_user')}
                    </div>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={24} sm={24} xs={24} className={styles.commentPercentage} align='middle'>
                    <div className={styles.commentPercentageNumber}>
                        <span id="percentage" /><span>%</span>
                    </div>
                    <div className={styles.commentPercentageDescription}>
                        {t('total_feedback:positive_comments')}
                    </div>
                </Col>
            </Row>
            <Row className={styles.mottoContainer} align='middle' justify='center'>
                <Col justify='center' align='middle'>
                    <h1 className={styles.motto}>{t('total_feedback:total_feedback_description')}</h1>
                    <p className={styles.mottoDescription}>{t('total_feedback:total_feedback_subdescription')}</p>
                    {!currentUser && <Link to={'/register'} className={styles.mottoButton}>{t('total_feedback:register')}</Link>}
                </Col>
            </Row>
        </Row>
    )
}

export default TotalUserFeedback