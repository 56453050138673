// anntd
import { Col, Row } from 'antd'
// helpers
import { getPopulerFrequencies } from '../../helpers/firebase/firebase_voice_methods'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
//style
import styles from './PopularFrequency.module.css'

const PopularFrequency = ({ link }) => {
    //i18next for language changing
    const { t } = useTranslation(['frequencies', 'record']);
    // states
    const [frequencies, setFrequencies] = useState([]);

    useEffect(() => {
        const getData = async () => {
            setFrequencies(await getPopulerFrequencies())
        }
        getData();
    }, [])
    return (
        <Col className={styles.container}>
            <Row className={styles.headerRow} align='middle'>
                <img className={styles.headerIcon} src="/assets/images/wave-gradient.png" alt="frewell" />
                <h1 className={styles.headerTitle}>{t('popular_frequencies', { ns: "frequencies" })}</h1>
            </Row>
            <Row wrap={true} align='middle' justify='center' className={styles.popularFrequencyRow}>
                {frequencies.map((frequency, index) => (
                    <Col className={styles.popularFrequencyColumn}
                        xxl={4} xl={4} lg={6} md={7} sm={7} xs={15}
                        key={index}
                    >
                        <Link to={`/app/${frequency}`} className={styles.popularFrequency}>
                            {t(frequency, { ns: 'record' })}
                        </Link>
                    </Col>
                ))
                }
                <Col xxl={4} xl={6} lg={7} md={10} sm={10} xs={24} align='middle'>
                    <Link to={'/frequencies'} className={link ? styles.descriptionLink : styles.descriptionLinkNone}>
                        {t('browse_frequencies', { ns: "frequencies" })}
                    </Link>
                </Col>
            </Row>
        </Col>
    )
}

export default PopularFrequency