import { loadStripe } from "@stripe/stripe-js";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "./firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
//Purchase Package Function with Price,Credit Value and day value
export const purchaseSpecialPackageForAdmin = async (
  package_name
) => {
   //Get special packages with query package name === package name
  //with query
  let q = query(
    collection(db, "special_packages"),
    where("package_name", "==", package_name)
  );
  let querySnapshot = await getDocs(q);
  let specialPackage = querySnapshot.docs[0];
  //Get purchased packages with query uid === uid
  let packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
//write the package to purchased packages
await updateDoc(packageRef, {
  purchase_history: arrayUnion({
    price: specialPackage.data().credit_value,
    created_at: new Date(),
    total_credit: specialPackage.data().credit_value,
    package_day: 1,
    package_name: specialPackage.data().package_name,
  }),
});
let steps = [];
let length = specialPackage.data().steps.length;
for (let i = 0; i < length; i++) {
if (i === 0) {
 steps.push({
   is_enabled: true,
   step: specialPackage.data().steps[i],
 });
} else {
 steps.push({
   is_enabled: false,
   step: specialPackage.data().steps[i],
 });
}
}
//update special_packages in purchased packages
await updateDoc(packageRef, {
special_packages: arrayUnion({
  package_name: specialPackage.data().package_name,
  steps: [...steps],
  is_active: true,
  created_at: new Date(),
  disease_category: specialPackage.data().disease_category,
  hour_limit: specialPackage.data().hour_limit,
}),
});
 return true;
};

// //Purchase gift package function with price,credit value and day value
// export const purchaseGift = async (creditValue) => {
//   const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
//   //if expiration date is bigger than today + package day number then update expiration date
//   //else do nothing
//   let documentSnapshots = await getDoc(packageRef);
//   const newExpirationDate = new Date(Date.now() + 1 * 86400000);
//   if (documentSnapshots.data().expiration_date == null) {
//     //increase remaining credit by creditValue
//     await updateDoc(packageRef, {
//       remaining_credit: increment(creditValue),
//       expiration_date: newExpirationDate,
//     });
//   } else {
//     const oldExpirationDate = new Date(
//       documentSnapshots.data().expiration_date.seconds * 1000
//     );
//     //increase remaining credit by creditValue
//     await updateDoc(packageRef, {
//       remaining_credit: increment(creditValue),
//       expiration_date:
//         newExpirationDate > oldExpirationDate
//           ? newExpirationDate
//           : oldExpirationDate,
//     });
//   }

//   //push this purchase to purchase_history array
//   await updateDoc(packageRef, {
//     purchase_history: arrayUnion({
//       created_at: new Date(),
//       total_credit: creditValue,
//       package_day: 1,
//       status: "gift",
//     }),
//   });
// };
//When user record a voice, decrease remaining credit by 1
export const decreaseOneRemainingCredit = async () => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  await updateDoc(packageRef, {
    remaining_credit: increment(-1),
  });
};

//When user upload a voice, update waiting_date to current date + 3 hours
export const updateWaitingTime = async () => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  // new waiting date is current date + 3 hours
  const newWaitingDate = new Date(Date.now() + 3 * 3600000);
  await updateDoc(packageRef, {
    waiting_date: newWaitingDate,
  });
};
//To get remaining credit of logged user
export const getRemainingCredit = async () => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  return documentSnapshots.data().remaining_credit;
};

export const getRemainingActivePackage = async () => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  //if document is not exist then return 0
  if (documentSnapshots.data().special_packages == null) {
    return 0;
  } else {
    let numberOfPackage = 0;
    documentSnapshots.data().special_packages.forEach((element) => {
      if (element.is_active) {
        numberOfPackage++;
      }
    });
    return numberOfPackage;
  }
};

//to create promo code when purchase package
//get expire_limit as an input strin R1, R2, R3
export const createPromoCode = async (number) => {
  const promoRef = doc(db, "promotions", auth.currentUser.uid);
  let documentSnapshots = await getDoc(promoRef);
  let keys = [];

  //create number times random key
  for (let i = 0; i < number; i++) {
    let key = createRandomKey();
    keys.push(key);
  }

  if (documentSnapshots.data() == null) {
    //create doc with auth.currentUser.uid as id with promo_keys array and promo_values array
    await setDoc(doc(db, "promotions", auth.currentUser.uid), {
      promo_keys: arrayUnion(...keys),
    });
  } else {
    await updateDoc(promoRef, {
      promo_keys: arrayUnion(...keys),
    });
  }
};
//To use promo code with gifted account
export const getPromoCode = async (promo_code) => {
  //if user is not new, has already purchase some credit, return false
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  if (documentSnapshots.data().purchase_history.length > 0) {
    return false;
  }else {
    const functions = getFunctions();
    const purchaseGift = httpsCallable(functions, 'purchaseGift');
    let result = await purchaseGift({ customer_id: auth.currentUser.uid, promo_code: promo_code });
    return result;
  }
};

//get expire_limit as an input strin R1, R2, R3
export const createRandomKey = () => {
  let randomKey = "R1-";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 6; i++) {
    randomKey += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return randomKey;
};

// export const reverseString = (str) => {
//     return str.split('').reverse().join('');
// }

//Get expiration date of current user to show profile
export const getExpirationDate = async () => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  const expirationDate = new Date(
    documentSnapshots.data().expiration_date?.seconds * 1000
  );
  return expirationDate;
};

export const getCurrentUsersPromoCodes = async () => {
  const promoRef = doc(db, "promotions", auth.currentUser.uid);
  let documentSnapshot = await getDoc(promoRef);
  let promo_keys = [];
  if (documentSnapshot.data() != null) {
    documentSnapshot.data().promo_keys.forEach((promo_key) => {
      //get second string of promo code
      let secondChar = promo_key.charAt(1);
      //get expiration date of promo code
      if (secondChar === "1") {
        promo_keys.push({ expiration_date: "1 Day", promo_code: promo_key });
      } else if (secondChar === "2") {
        promo_keys.push({ expiration_date: "1 Month", promo_code: promo_key });
      } else if (secondChar === "3") {
        promo_keys.push({ expiration_date: "1 Year", promo_code: promo_key });
      }
    });
  }
  return promo_keys;
};

// //Get special packages like sleep package/student package with credit
// export const buySpecialPackages = async (package_name, price) => {
//   let steps = [];
//   let q = query(
//     collection(db, "special_packages"),
//     where("package_name", "==", package_name)
//   );
//   let querySnapshot = await getDocs(q);
//   let data = querySnapshot.docs[0].data();
//   await purchasePackage(
//     parseInt(data.credit_value),
//     parseInt(price),
//     1,
//     package_name
//   );
//   decreaseRemainingCreditByValue(data.credit_value);

//   let length = Object.keys(data.steps).length;
//   for (let i = 0; i < length; i++) {
//     if (i === 0) {
//       steps.push({
//         is_enabled: true,
//         step: querySnapshot.docs[0].data().steps[i],
//       });
//     } else {
//       steps.push({
//         is_enabled: false,
//         step: querySnapshot.docs[0].data().steps[i],
//       });
//     }
//   }
//   const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
//   await updateDoc(packageRef, {
//     special_packages: arrayUnion({
//       package_name: package_name,
//       disease_category: data.disease_category,
//       steps: [...steps],
//       hour_limit: data.hour_limit,
//       is_active: true,
//     }),
//   });
//   return true;
// };

//Decrease remaining credit by value
export const decreaseRemainingCreditByValue = async (value) => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  await updateDoc(packageRef, {
    remaining_credit: increment(-value),
  });
};

//To change is enabled of special package steps by value(true or false)
export const makeSpecialPackageStepEnabled = async (
  package_name,
  step_name
) => {
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  let special_packages = documentSnapshots.data().special_packages;
  special_packages.forEach((special_package, index) => {
    if (special_package.package_name === package_name && special_package.is_active) {
      special_package.steps.forEach((step, index) => {
        if (step.step.step_name === step_name) {
          special_package.steps[index].is_enabled = false;
          if (special_package.steps[index + 1] != null) {
            special_package.steps[index + 1].is_enabled = true;
            //set waiting date date now + hour limit
            special_package.steps[index + 1].hour_limit = new Date(
              Date.now() + special_package.hour_limit * 60 * 60 * 1000
            );
          } else {
            special_package.is_active = false;
          }
        }
      });
    }
  });
  await updateDoc(packageRef, {
    special_packages: special_packages,
  });
};

export const getAllSpecialPackages = async () => {
  let special_packages = [];
  let q = query(collection(db, "special_packages"));
  let querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    special_packages.push({
      image: doc.data().img_url,
      title: doc.data().package_name,
      is_active: doc.data().is_active,
    });
  });
  return special_packages;
};

//Get special packages like sleep package/student package to show steps user on user profile
export const getSpecialPackages = async () => {
  //get user special packages
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  return documentSnapshots.data().special_packages;
};

export const getAllPackages = async () => {
  let packages = [];
  let q = query(collection(db, "packages"));
  let querySnapshot = await getDocs(q);
  //get data from packages collection
  querySnapshot.forEach((doc) => {
    doc.data().packages.forEach((packageRef) => {
      packages.push(packageRef);
    });
  });
  return packages;
};

export const updatePackagePriceWithDate = async (
  package_name,
  package_price
) => {
  //get all packages from firebase firestorage
  let q = query(collection(db, "packages"));
  let querySnapshot = await getDocs(q);
  //get data from packages collection
  querySnapshot.forEach((doc) => {
    let oldPackages = doc.data().packages;
    oldPackages.forEach((packageRef) => {
      if (packageRef.date === package_name) {
        //update package price from old packages
        packageRef.price = package_price;
        //update doc ref with new price
        updateDoc(doc.ref, {
          packages: oldPackages,
        });
      }
    });
  });
};

export const updateSpecialPackage = async (
  package_name,
  is_active,
  package_price
) => {
  //get all packages from firebase firestorage
  let q = query(collection(db, "special_packages"));
  let querySnapshot = await getDocs(q);
  //get data from packages collection
  querySnapshot.forEach((doc) => {
    if (doc.data().package_name === package_name) {
      //update package price from old packages
      if (package_price == null) {
        updateDoc(doc.ref, {
          is_active: is_active,
        });
      } else {
        updateDoc(doc.ref, {
          credit_value: parseInt(package_price),
          is_active: is_active,
        });
      }
    }
  });
};

export const getPackageWithNameFromCurrentUser = async (package_name) => {
  //get purchased p
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  let purchased_packages = documentSnapshots.data().special_packages;
  for (let i = 0; i < purchased_packages.length; i++) {
    if (purchased_packages[i].package_name === package_name && purchased_packages[i].is_active) {
      return purchased_packages[i];
    }
  }
};

export const getSpecialPackageDetailsWithName = async (package_name) => {
  //get all special package details from firebase firestorage
  let q = query(collection(db, "special_package_details"));
  let querySnapshot = await getDocs(q);
  //get first data of special packages details collection
  let data = querySnapshot.docs[0].data();
  for (let i = 0; i < data.special_packages.length; i++) {
    if (data.special_packages[i].package_name === package_name) {
      return data.special_packages[i];
    }
  }
};

export const getOtherSpecialPackagesWithoutThis = async (package_name) => {
  let special_packages = [];
  let q = query(collection(db, "special_packages"));
  let querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.data().package_name !== package_name) {
      special_packages.push({
        image: doc.data().img_url,
        title: doc.data().package_name,
      });
    }
  });
  return special_packages;
};

export const checkThisStepIsEnabledAndHoursLimit = async (
  package_name,
  step_name
) => {
  //get user special packages

  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  let special_packages = documentSnapshots.data().special_packages;
  for (let i = 0; i < special_packages.length; i++) {
    if (special_packages[i].package_name === package_name) {
      for (let j = 0; j < special_packages[i].steps.length; j++) {
        if (
          special_packages[i].steps[j].step.step_name === step_name &&
          special_packages[i].steps[j].is_enabled === true
        ) {
          if (special_packages[i].steps[j].hour_limit != null) {
            let hour_limit = special_packages[i].steps[j].hour_limit;
            let now = new Date();
            if (now < new Date(hour_limit.seconds * 1000)) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }
  }
};

export const getHourLimitForThisStep = async (package_name, step_name) => {
  //get user special packages
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  let special_packages = documentSnapshots.data().special_packages;
  for (let i = 0; i < special_packages.length; i++) {
    if (special_packages[i].package_name === package_name) {
      for (let j = 0; j < special_packages[i].steps.length; j++) {
        if (
          special_packages[i].steps[j].step.step_name === step_name &&
          special_packages[i].steps[j].is_enabled === true
        ) {
          if (special_packages[i].steps[j].hour_limit != null) {
            let hour_limit = special_packages[i].steps[j].hour_limit;
            let date = new Date(hour_limit.seconds * 1000);
            return date;
          } else {
            return 0;
          }
        }
      }
    }
  }
};

export const getAllPurchasedPackages = async () => {
  //get all purchased packages
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  let purchased_packages = documentSnapshots.data().purchase_history;
  let returnPackages = [];
  for (let i = 0; i < purchased_packages.length; i++) {
    let date = new Date(
      purchased_packages[i].created_at.seconds * 1000
    ).toLocaleDateString();
    if (purchased_packages[i].package_name != null) {
      returnPackages.push({
        key: i,
        date: date,
        price: purchased_packages[i].price,
        value: purchased_packages[i].package_name,
      });
    } else {
      returnPackages.push({
        key: i,
        date: date,
        price: purchased_packages[i].price,
        value: purchased_packages[i].total_credit,
      });
    }
  }
  //sort return packages by date
  returnPackages.sort(function (a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  return returnPackages;
};

export const isUserHaveThisPackage = async (package_name) => {
  //get all purchased packages
  const packageRef = doc(db, "purchased_packages", auth.currentUser.uid);
  let documentSnapshots = await getDoc(packageRef);
  let purchased_packages = documentSnapshots.data().special_packages;
  let lengthOfPackages =
    purchased_packages != null ? purchased_packages.length : 0;
  for (let i = 0; i < lengthOfPackages; i++) {
    if (
      purchased_packages[i].package_name === package_name &&
      purchased_packages[i].is_active === true
    ) {
      return true;
    }
  }
  return false;
};

export const getProducts = async () => {
  let productObject = {};
  //get active true products
  let q = query(collection(db, "products"), where("active", "==", true));
  let querySnapshot = await getDocs(q);
  querySnapshot.docs.forEach(async (doc) => {
    productObject[doc.id] = doc.data();
    const priceSnap = await getDocs(collection(doc.ref, "prices"));
    priceSnap.docs.forEach((price) => {
      if (price.data().active === true) {
        productObject[doc.id].price = {
          priceId: price.id,
          priceData: price.data(),
        };
      }
    });
  });
  let productObjectArray = Object.entries(productObject);
  return productObjectArray;
  // return productObject;
};

export const checkOut = async (priceId) => {
  const docRef = await addDoc(
    collection(db, "customers", auth.currentUser.uid, "checkout_sessions"),
    {
      price: priceId,
      success_url: "https://frewell.com/success?session_id={CHECKOUT_SESSION_ID}",
      cancel_url: "https://frewell.com/cancel",
      mode: "payment",
    }
  );
  onSnapshot(docRef, async (snap) => {
    const { error, sessionId } = snap.data();
    if (error) {
      alert(error.message);
      window.location.reload();
    }
    if (sessionId) {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PK);
      stripe.redirectToCheckout({ sessionId });
    }
  });
};

export const getProductIdWithPackageName = async (package_name) => {
  //get active true products
  let q = query(collection(db, "products"), where("active", "==", true));
  let querySnapshot = await getDocs(q);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    if (querySnapshot.docs[i].data().name === package_name) {
      const priceSnap = await getDocs(
        collection(querySnapshot.docs[i].ref, "prices")
      );
      for (let j = 0; j < priceSnap.docs.length; j++) {
        if (priceSnap.docs[j].data().active === true) {
          return priceSnap.docs[j].id;
        }
      }
    }
  }
};
