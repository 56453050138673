// antd
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
// style
import styles from './Loading.module.css'

const Loading = ({ payment }) => {
    const {t} = useTranslation("alerts")
    return (
        <Row className={styles.loadingContainer} justify='center' align='middle'>
            <Col className={styles.container} align='middle'>
                {payment &&
                    <Row justify='center' className={styles.payment}>
                        {t("loading",{ns:"alerts"})}
                    </Row>
                }
                <img className={styles.waveLoading} src="/assets/images/Loading3.gif" alt="loading" />
            </Col>
        </Row>
    )
}

export default Loading