// antd
import { Col, Row } from 'antd'
// i18next
import { useTranslation } from 'react-i18next';
// react
// import { Link } from 'react-router-dom';
// style
import styles from './WorkSteps.module.css'

const WorkSteps = () => {
    //i18next for language changing
    const { t } = useTranslation(['worksteps']);

    return (
        <Row id='stepContainerId' className={styles.stepContainer} xxl={20} xl={20} lg={20} md={20} sm={24} xs={24} justify="center">
            <Col className={styles.titleContainer} align="middle">
                {
                    localStorage.getItem("i18nextLng") === "tr" ?
                        <h1 className={styles.title}>
                            Fre<span className={styles.titleSpan}>Well</span> Nasıl Çalışıyor?
                        </h1> :
                        <h1 className={styles.title}>How Does Fre<span className={styles.titleSpan}>
                            Well</span> Work?
                        </h1>
                }
                {
                    localStorage.getItem("i18nextLng") === "tr" ?
                        <p className={styles.description}>Kuantum tabanlı dijital bir yazılım olan "Fre<span className={styles.descriptionSpan}>Well</span>  teknolojisi" ile dilediğiniz frekanslara 4 adımda kolayca uyumlanın!</p> :
                        <p className={styles.description}>Tune the frequencies you want in 4 easy steps with "Fre<span className={styles.descriptionSpan}>Well</span>  technology", a quantum-based digital software!</p>
                }
                {/* {
                    localStorage.getItem("i18nextLng") === "tr" ?
                        <p className={styles.descriptionSecond}>Üstelik bu teknolojiyi paket satın aldıktan sonra oluşan <Link to={'/app/promoCode'}><b className={styles.promotion}> PROMOSYON KODUNU </b></Link> sevdiklerinle paylaşarak onlarında deneyimlemesini sağlayabilirsin.</p> :
                        <p className={styles.descriptionSecond}>Moreover, you can share the <b className={styles.promotion}>PROMOTIONAL CODE</b> created after purchasing this technology with your loved ones so that they can experience it as well.</p>
                } */}

            </Col>
            <Row justify='center' xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
                <div className={styles.waveContainer}>
                    <img className={styles.waveImage} src="/assets/images/steps-wave.png" alt="steps" />
                </div>
                <Row className={styles.cardRow} justify='center'>
                    <Col xxl={4} xl={4} lg={4} md={6} className={styles.firstCardContainer} align='middle'>
                        <p className={styles.number}>1</p>
                        <h1 className={styles.header}>{t('worksteps:first_title')}</h1>
                        <p className={styles.cardDescription}>{t('worksteps:first_description')}</p>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={6} className={styles.secondCardContainer} align='middle'>
                        <p className={styles.number}>2</p>
                        <h1 className={styles.header}>{t('worksteps:second_title')}</h1>
                        <p className={styles.cardDescription}>{t('worksteps:second_description')}</p>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={6} className={styles.thithCardContainer} align='middle'>
                        <p className={styles.number}>3</p>
                        <h1 className={styles.header}>{t('worksteps:third_title')}</h1>
                        <p className={styles.cardDescription}>{t('worksteps:third_description')}</p>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={6} className={styles.fourthCardContainer} align='middle'>
                        <p className={styles.number}>4</p>
                        <h1 className={styles.header}>{t('worksteps:fourth_title')}</h1>
                        <p className={styles.cardDescription}>{t('worksteps:fourth_description')}</p>
                    </Col>
                </Row>
            </Row>
        </Row>
    )
}

export default WorkSteps