import { auth, db } from "./firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

//To control the current user is null or not
export const isLoggedIn = () => {
  return auth.currentUser !== null;
};

//Register user with given extra info
export const registerFirebase = async (newUser) => {
  await setDoc(doc(db, "users", auth.currentUser.uid), {
    name: newUser.name,
    surname: newUser.surname,
    email: newUser.email,
    phone: newUser.phoneNumber,
    gender: newUser.gender,
    age_interval: newUser.age_interval,
    region: newUser.region,
    city: newUser.city,
    created_at: new Date(),
    is_admin: false,
    uid: auth.currentUser.uid,
    voices: {},
  });
  await setDoc(doc(db, "register_details", auth.currentUser.uid), {
    gender: newUser.gender,
    age_interval: newUser.age_interval,
    region: newUser.region,
    city: newUser.city,
    created_at: new Date(),
  });
  await setDoc(doc(db, "purchased_packages", auth.currentUser.uid), {
    purchase_history: [],
    remaining_credit: 0,
    expiration_date: null,
    waiting_date: null,
  });
};

//To update profile with given info
export const updateProfileFirebase = async (userInfoUpdate) => {
  const user = auth.currentUser;
  const userRef = doc(db, "users", user.uid);
  await updateDoc(userRef, {
    name: userInfoUpdate.name,
    surname: userInfoUpdate.surname,
    phone: userInfoUpdate.phoneNumber,
    age_interval: userInfoUpdate.age,
    gender: userInfoUpdate.gender,
    region: userInfoUpdate.region,
    city: userInfoUpdate.city,
    updated_at: new Date(),
  });
  const userDetailsRef = doc(db, "register_details", user.uid);
  await updateDoc(userDetailsRef, {
    age_interval: userInfoUpdate.age,
    region: userInfoUpdate.region,
    gender: userInfoUpdate.gender,
    city: userInfoUpdate.city,
    updated_at: new Date(),
  });
};
//Get current user info from firebase users table
export const getCurrentUserDetails = async () => {
  //get ref with current user id
  const userRef = doc(db, "users", auth.currentUser.uid);
  //get user info from ref
  const user = await getDoc(userRef);
  return user.data();
};

//Change the current user's password
export const changePasswordAuth = async (oldPassword, newPassword) => {
  //get current user
  var user = auth.currentUser;
  var credential = EmailAuthProvider.credential(user.email, oldPassword);
  let errorMessage;
  //reauhenticate with current user's email and old password and give error if there is any such as old password is wrong

  try {
    await reauthenticateWithCredential(user, credential).then(async (res) => {
      await updatePassword(user, newPassword);
      errorMessage = "true";
    });
  } catch (error) {
    errorMessage = error.code;
  } finally {
    return errorMessage;
  }
};
//To send verification email to current user email
// after the email verification redirect to website
export const sendEmailVerificationFirebase = async () => {
  await sendEmailVerification(auth.currentUser, {
    url: "https://frewell.com/?reload=true",
  })
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
};

//To reset password for the user with given email - use firebase authentication
export const resetPasswordFirebase = async (email) => {
  await sendPasswordResetEmail(auth, email, {
    url: "https://frewell.com/",
  })
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
};

//To control current user is admin or not
export const isCurrentUserAdmin = async () => {
  if (auth.currentUser !== null) {
    const usersRef = doc(db, "users", auth.currentUser.uid);
    let documentSnapshots = await getDoc(usersRef);
    return documentSnapshots.data().is_admin;
  }
  return false;
};
//Get user with user id and get their voices
export const getUserVoices = async () => {
  const userRef = doc(db, "users", auth.currentUser.uid);
  let documentSnapshots = await getDoc(userRef);
  let voicesTemp = [];
  for (let key in documentSnapshots.data().voices) {
    let created_at = new Date(
      documentSnapshots.data().voices[key]?.created_at?.seconds * 1000
    ).toLocaleDateString();
    let isListened = documentSnapshots.data().voices[key].is_listened;
    let delete_date = documentSnapshots.data().voices[key].delete_date
      ? documentSnapshots.data().voices[key].delete_date
      : "0";
    //cut 2 characters of the end of the delete date
    delete_date = delete_date.substring(0, delete_date.length - 3);
    voicesTemp.push({
      category_name: documentSnapshots.data().voices[key].disease_category,
      disease_name: documentSnapshots.data().voices[key].disease_name,
      created_at: created_at,
      is_listened: isListened,
      is_available_feedback:
        documentSnapshots.data().voices[key].is_available_feedback,
      action_id: key,
      delete_date: delete_date,
    });
  }
  //sort voices temp by action id
  voicesTemp.sort((a, b) => {
    return b.action_id - a.action_id;
  });
  return voicesTemp;
};

//To control user is verified or not with user_id
export const isUserVerified = async () => {
  return auth.currentUser.emailVerified;
};

export const getPurchaseToNewsletters = async (email) => {
  //check this email is in newsletter table or not
  let q = query(collection(db, "newsletter"), where("email", "==", email));
  let querySnapshot = await getDocs(q);
  if (querySnapshot.empty) {
    //create a new ref in newsletter table with auto id and given email
    await addDoc(collection(db, "newsletter"), {
      email: email,
      created_at: new Date(),
    });
    return true;
  } else {
    return false;
  }
};

export const getUserWithEmail = async (email) => {
  let user = [];
  let q = query(collection(db, "users"), where("email", "==", email));
  let querySnapshot = await getDocs(q);
  user = querySnapshot.docs[0].data();

  let returnPackagesAndUser = [];
  let documentSnapshot = await getDoc(doc(db, "purchased_packages", user.uid));

  returnPackagesAndUser.push({
    uid: user.uid,
    email: user.email,
    expiration_date: new Date(
      documentSnapshot.data().expiration_date?.seconds * 1000
    ),
    remaining_credit: documentSnapshot.data().remaining_credit,
  });
  return returnPackagesAndUser;
};
export const getUserWithNameAndSurname = async (name, surname) => {
  let users = [];
  let q = query(
    collection(db, "users"),
    where("name", "==", name),
    where("surname", "==", surname)
  );
  let querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    users.push(doc.data());
  });
  let returnPackagesAndUser = [];
  for (let i = 0; i < users.length; i++) {
    let documentSnapshot = await getDoc(
      doc(db, "purchased_packages", users[i].uid)
    );
    returnPackagesAndUser.push({
      uid: users[i].uid,
      email: users[i].email,
      expiration_date: new Date(
        documentSnapshot.data().expiration_date?.seconds * 1000
      ),
      remaining_credit: documentSnapshot.data().remaining_credit,
    });
  }
  return returnPackagesAndUser;
};

export const getCurrentUserPackages = async () => {
  let documentSnapshot = await getDoc(
    doc(db, "purchased_packages", auth.currentUser.uid)
  );
  return documentSnapshot.data();
};

export const contactMessage = async (help) => {
  await addDoc(collection(db, "contact"), {
    name: help.userName,
    surname: help.userSurname,
    email: help.userEmail,
    message: help.userMessage,
    created_at: new Date(),
    phone: help.userPhone,
    uid: auth.currentUser === null ? "null" : auth.currentUser.uid,
  });
  return true;
};
export const deleteUserFunc = async () => {
  let uid = auth.currentUser.uid;
  let email = auth.currentUser.email;
  const functions = getFunctions();
  const deleteUser = httpsCallable(functions, "deleteUser");
  let result = await deleteUser({ uid: uid, email: email });
  return result;
};

// try {
//   await setDoc(doc(db, "contact", auth.currentUser.uid), {
//     name: help.userName,
//     surname: help.userSurname,
//     email: help.userEmail,
//     message: help.userMessage,
//     phone: help.userPhone,
//     uid: auth.currentUser.uid,
//     created_at: new Date(),
//   });
//   return true;
// } catch (error) {
//   return false;
// }

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}
