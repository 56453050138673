// antd
import { Col, Row } from 'antd'
// helpers
import { getDiseasesEmptyParam } from '../../helpers/firebase/firebase_admin_methods'
import { getAlertMessage } from '../../helpers/utils/AlertUtils'
// hook
import useNotification from '../../hooks/useNotification'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// style
import styles from './Categories.module.css'

const Categories = () => {
    //i18next for language changing
    const { t } = useTranslation(['frequencies', 'record', 'alerts']);
    // hook
    const { alertError } = useNotification();
    // states
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // get all frequencies with main category
        getDiseasesEmptyParam()
            .then((res) => {
                return (
                    setCategories(res)
                )
            }).catch((err) => {
                let errorMessage = getAlertMessage(err.code);
                alertError(t(errorMessage, { ns: 'alerts' }));
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.categoriesContainer}>
            <Row className={styles.categoriesHeaderRow} align='middle'>
                <img className={styles.frequenciesIcon} src="/assets/images/wave-gradient.png" alt="frewell" />
                <h1 className={styles.frequenciesHeader}>{t('all_frequencies', { ns: 'frequencies' })}</h1>
            </Row>
            {
                categories?.map((mainCategory, index) => {
                    return (
                        <Row key={index} className={styles.mainCategoriesContainer}>
                            <Col span={24}>
                                <h1 className={styles.mainCategoryHeader}>{t(mainCategory?.category_name, { ns: 'record' })}</h1>
                                <Row className={styles.mainCategoryRow}>
                                    {
                                        mainCategory?.diseases?.map((subCategory, index) => {
                                            return subCategory?.status !== "Special" && (
                                                <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={12} key={index} className={styles.subCategoryColumn}>
                                                    <Row align='middle' wrap={false} className={styles.subCategoryRow}>
                                                        <img className={styles.littleIcon} src="/assets/images/wave-gradient.png" alt="wave icon" />
                                                        <Link to={`/app/${subCategory?.disease_name}`} className={styles.subCategoryName}>
                                                            {t(subCategory?.disease_name, { ns: 'record' })}
                                                        </Link>
                                                    </Row>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Col>
                        </Row>
                    )
                })
            }
            <Row className={styles.mainCategoriesContainer}>
                <Col span={24}>
                    <h1 className={styles.mainCategoryHeader}>
                        {t('create_own_frequency', { ns: 'frequencies' })}
                    </h1>
                    <Row className={styles.mainCategoryRow}>
                        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24} className={styles.subCategoryColumn}>
                            <Row align='top' wrap={false} className={styles.subCategoryRow}>
                                <img className={styles.littleIcon} src="/assets/images/wave-gradient.png" alt="wave icon" />
                                <Link to={`/app`} className={styles.subCategoryName}>
                                    {t('create_own_01', { ns: 'frequencies' })}
                                </Link>
                            </Row>
                            <Row align='top' wrap={false} className={styles.subCategoryRow}>
                                <img className={styles.littleIcon} src="/assets/images/wave-gradient.png" alt="wave icon" />
                                <Link to={`/app`} className={styles.subCategoryName}>
                                    {t('create_own_02', { ns: 'frequencies' })}
                                </Link>
                            </Row>
                            <Row align='top' wrap={false} className={styles.subCategoryRow}>
                                <img className={styles.littleIcon} src="/assets/images/wave-gradient.png" alt="wave icon" />
                                <Link to={`/app`} className={styles.subCategoryName}>
                                    {t('create_own_03', { ns: 'frequencies' })}
                                </Link>
                            </Row>
                            <Row align='top' wrap={false} className={styles.subCategoryRow}>
                                <img className={styles.littleIcon} src="/assets/images/wave-gradient.png" alt="wave icon" />
                                <Link to={`/app`} className={styles.subCategoryName}>
                                    {t('create_own_04', { ns: 'frequencies' })}
                                    <ol>
                                        <li>{t('example_01', { ns: 'frequencies' })}</li>
                                        <li>{t('example_02', { ns: 'frequencies' })}</li>
                                        <li>{t('example_03', { ns: 'frequencies' })}</li>
                                        <li>{t('example_04', { ns: 'frequencies' })}</li>
                                    </ol>
                                </Link>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    )
}

export default Categories