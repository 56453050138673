import AppComponent from "../components/AppComponent/AppComponent"
import Footer from "../components/Footer/Footer"

const App = () => {
    return (
        <>
            <AppComponent />
            <Footer />
        </>
    )
}

export default App