import { HistoryOutlined, LockOutlined, LogoutOutlined, MailOutlined, RightOutlined, UserDeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { isUserVerified } from '../../helpers/firebase/firebase_user_methods';
import ChangePassword from '../ChangePassword/ChangePassword';
import DeleteUser from '../DeleteUser/DeleteUser';
import Navbar from '../Navbar/Navbar';
import PurchaseHistory from '../PurchaseHistory/PurchaseHistory';
import SendVerification from '../SendVerification/SendVerification';
import UserKnowledge from '../UserKnowledge/UserKnowledge';
import styles from './ProfileComponent.module.css'

const ProfileComponent = () => {
    //it is used for changing language
    const { t } = useTranslation(['app', 'record']);
    // react-router-dom
    const navigate = useNavigate();
    // states
    const [menu, setMenu] = useState({
        userKnowledge: true,
        changePassword: false,
        purchaseHistory: false,
        deleteUser: false
    })
    const [userVerified, setUserVerified] = useState(false);
    // useContext logout function
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        navigate('/')
    }

    useEffect(() => {
        const checkVerified = async () => {
            setUserVerified(await isUserVerified());
        }
        // check user's email verification
        checkVerified();
    }, [])

    return (
        <>
            <Navbar design={true} />
            <Row className={styles.appContainer} justify='center'>
                <Col xxl={5} xl={5} lg={5} md={22} sm={22} xs={22} justify='end' align='end'>
                    <Col className={styles.menuContainer}>
                        <Row
                            className={menu?.userKnowledge ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={() => {
                                setMenu({ userKnowledge: true })
                            }
                            }
                            wrap={false}
                        >
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                <UserOutlined className={styles.menuIcons} />
                                <p className={menu.userKnowledge ? styles.menuTitleActive : styles.menuTitlePassive}>{t('user_info', { ns: "app" })}</p>
                            </Row>
                            {menu.userKnowledge && <RightOutlined className={styles.arrow} />}
                        </Row>
                        <Row
                            className={menu?.changePassword ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={() => {
                                setMenu({ changePassword: true })
                            }
                            } wrap={false}>
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                <LockOutlined className={styles.menuIcons} />
                                <p className={menu.changePassword ? styles.menuTitleActive : styles.menuTitlePassive}>{t('change_password', { ns: "app" })}</p>
                            </Row>
                            {menu.changePassword && <RightOutlined className={styles.arrow} />}
                        </Row>
                        <Row
                            className={menu?.purchaseHistory ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={() => {
                                setMenu({ purchaseHistory: true })
                            }
                            }
                            wrap={false}
                        >
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                <HistoryOutlined className={styles.menuIcons} />
                                <p className={menu.purchaseHistory ? styles.menuTitleActive : styles.menuTitlePassive}>{t('purchaseHistory', { ns: "app" })}</p>
                            </Row>
                            {menu.purchaseHistory && <RightOutlined className={styles.arrow} />}
                        </Row>
                        <Row
                            className={menu.deleteUser ? styles.menuTitleActiveRow : styles.menuTitleRow}
                            span={24}
                            justify='space-between'
                            align='middle'
                            onClick={() => {
                                setMenu({ deleteUser: true })
                            }
                            }
                            wrap={false}
                        >
                            <Row align='middle' className={styles.iconContainer} wrap={false}>
                                <UserDeleteOutlined className={styles.menuIcons} />
                                <p className={menu.deleteUser ? styles.menuTitleActive : styles.menuTitlePassive}>
                                    {t('delete_user', { ns: "app" })}
                                </p>
                            </Row>
                            {menu.deleteUser && <RightOutlined className={styles.arrow} />}
                        </Row>
                        {
                            !userVerified
                            &&
                            <Row
                                className={menu?.verifyEmail ? styles.menuTitleActiveRow : styles.menuTitleRow}
                                span={24}
                                justify='space-between'
                                align='middle'
                                onClick={() => {
                                    setMenu({ verifyEmail: true })
                                }
                                } wrap={false}>
                                <Row align='middle' className={styles.iconContainer} wrap={false}>
                                    <MailOutlined className={styles.menuIcons} />
                                    <p className={menu.verifyEmail ? styles.menuTitleActive : styles.menuTitlePassive}>{t('verify_email', { ns: "app" })}</p>
                                </Row>
                                {menu.verifyEmail && <RightOutlined className={styles.arrow} />}
                            </Row>
                        }

                        <Row className={styles.signOutRow} span={24} align='middle' wrap={false} onClick={handleLogout}>
                            <Row align='middle' wrap={false}>
                                <LogoutOutlined className={styles.signOutIcon} />
                                <p className={styles.signOut}>{t('logout', { ns: "app" })}</p>
                            </Row>
                        </Row>
                    </Col>
                </Col>
                <Col xxl={16} xl={16} lg={16} md={22} sm={22} xs={22} className={styles.sideComponents}>
                    {menu.userKnowledge && <UserKnowledge />}
                    {menu.changePassword && <ChangePassword />}
                    {menu.purchaseHistory && <PurchaseHistory />}
                    {menu.verifyEmail && <SendVerification />}
                    {menu.deleteUser && <DeleteUser />}
                </Col>
            </Row>
        </>
    )
}

export default ProfileComponent