import Footer from '../components/Footer/Footer'
import PackageDetailComponent from '../components/PackageDetailComponent/PackageDetailComponent'

const PackageDetail = () => {
    return (
        <>
            <PackageDetailComponent />
            <Footer />
        </>
    )
}

export default PackageDetail