// antd
import { Col, Modal, Row } from 'antd'
import { CopyrightOutlined, InstagramOutlined, YoutubeFilled } from '@ant-design/icons'
// i18next
import { useTranslation } from 'react-i18next'
// react
import { Link } from 'react-router-dom'
import { useState } from 'react'
// style
import styles from './Footer.module.css'

const Footer = ({ error }) => {
    //i18next for language changing
    const { t } = useTranslation(['navbar']);
    // states
    const [disclaimer, setDisclaimer] = useState(false)
    // close disclaimer modal
    const handleCancel = () => {
        setDisclaimer(false);
    };

    return (
        <div id='footerContainerId' className={error ? styles.displayNone : styles.footerContainer}>
            <Row className={styles.linkContainer} align="middle">
                <Col xxl={6} xl={6} lg={4} md={3} sm={24} xs={24}>
                    <div className={styles.logoContainer}>
                        <img src="/assets/images/frewell-logo.png" alt="frewell logo" />
                    </div>
                </Col>
                <Col xxl={12} xl={14} lg={18} md={18} sm={24} xs={24}>
                    <Row align='middle' justify='space-between'>
                        <Link to={'/'} className={styles.footerPagination}>{t('navbar:home')}</Link>
                        <Link to={"/what-is-frewell"} className={styles.footerPagination}>{t('navbar:whatisfrewell')}</Link>
                        <Link to={"/frequencies"} className={styles.footerPagination}>{t('navbar:frequencies')}</Link>
                        <Link to={"/pricing"} className={styles.footerPagination}>{t('navbar:pricing')}</Link>
                        <Link to={"/help"} className={styles.footerPagination}>{t('navbar:help')}</Link>
                        <Link to={"/contact"} className={styles.footerPagination}>{t('navbar:contact')}</Link>
                    </Row>

                </Col>
                <Col xxl={6} xl={4} lg={2} md={3} sm={24} xs={24}>
                    <Row justify='end' className={styles.socialMediaRow}>
                        {/* <a shref='https://www.facebook.com/' target="_blank" rel="noopener noreferrer" className={styles.socialMediaIcon}><FacebookOutlined /></a> */}
                        <a href='https://www.instagram.com/frewellofficial/?igshid=ZjA0NjI3M2I%3D' target="_blank" rel="noopener noreferrer" className={styles.socialMediaIcon}><InstagramOutlined /></a>
                        <a href='https://www.youtube.com/@frewellofficial' target="_blank" rel='noopener noreferrer' className={styles.youtubeIcon}><YoutubeFilled /></a>
                    </Row>
                </Col>
            </Row>
            <Row align='middle' className={styles.documentContainer}>
                <Col xxl={6} xl={6} lg={6} md={6} sm={0} xs={0}></Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Row className={styles.agreementRow} justify='center'>
                        <Link to={'/privacy-policy'} className={styles.footerDocument}>{t('navbar:security')}</Link>
                        <Link to={'/user-agreement'} className={styles.footerDocument}>{t('navbar:user_agreement')}</Link>
                        {/* <Link to={'/'} className={styles.footerDocument}>{t('navbar:kvkk')}</Link> */}
                        <p className={styles.footerDocument} onClick={() => { setDisclaimer(true) }}>{t('navbar:disclaimer')}</p>
                        <Modal
                            title={t('navbar:disclaimer')}
                            open={disclaimer}
                            onCancel={handleCancel}
                            footer={null}
                            closable={true}
                        >
                            <p>{t('navbar:disclaimerAgreement')}</p>
                        </Modal>
                    </Row>
                    <Row className={styles.designedByContainer} wrap={false}>
                        <Row className={styles.designedBy} align='middle' justify='center' >
                            <Col xxl={2} xl={2} lg={4} md={6} sm={20} xs={23} justify='center' align='middle'>
                                <img className={styles.allRightReserved} src="/assets/images/frewell-logo.png" alt="logo" />
                            </Col>
                            <Col xxl={10} xl={14} lg={15} md={18} sm={20} xs={22} justify='center' align='middle'>
                                <p className={styles.allRightReservedDescription}>
                                    <Link to={'/contact'}>
                                        <strong>
                                            <CopyrightOutlined /> 2022 {t("falcomed", { ns: "navbar" })}.
                                        </strong> {t("allRightReserved", { ns: "navbar" })}
                                    </Link>
                                </p>
                            </Col>
                        </Row>
                    </Row>
                    {/* <Row className={styles.designedBy} align='middle' justify='center' wrap={false}>
                        <a className={styles.bilisimAcademy} href='https://bilisimacademy.com/' target="_blank" rel='noopener noreferrer'>Designed & Developed by<span>Bilişim Academy</span></a>
                    </Row> */}
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}></Col>
            </Row>
        </div>
    )
}

export default Footer