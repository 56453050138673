// antd
import { Col, Row } from 'antd'
// components
import Navbar from '../Navbar/Navbar'
import News from '../News/News'
import TotalUserFeedback from '../TotalUserFeedback/TotalUserFeedback'
// i18next
import { useTranslation } from 'react-i18next'
// style
import styles from './WhatIsFrewellComponent.module.css'

const WhatIsFrewellComponent = () => {
    //i18next for language changing
    const { t } = useTranslation(['what_is_frewell']);
    return (
        <>
            <Navbar design={true} />
            <Col className={styles.whatFrewellContainer}>
                <Row className={styles.headerContainer} align='middle'>
                    <h1 className={styles.header}>Fre<span className={styles.headerWell}>Well</span></h1>
                </Row>
                <Row className={styles.firstDescription} align='middle' justify='center'>
                    <Col xxl={14} xl={14} lg={14} md={22} sm={22} xs={22} className={styles.firstDescriptionColumn}>
                        <p>{t("what_is_frewell:description_1")}</p> <br></br>
                        <p>{t("what_is_frewell:description_2")}</p>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24} className={styles.humanImageContainer} align='middle'>
                        <img className={styles.humanImage} src="/assets/images/human-universe-connected.png" alt="human universe" />
                    </Col>
                </Row>
                <Row className={styles.secondDescription} align='middle' justify='center'>
                    <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24} className={styles.happyImageContainer}>
                        <img className={styles.happyImage} src="/assets/images/new-woman.png" alt="happy human" />
                    </Col>
                    <Col xxl={11} xl={11} lg={11} md={22} sm={22} xs={22} className={styles.secondDescriptionColumn}>
                        <p>{t("what_is_frewell:description_3")}</p>
                        <p>{t("what_is_frewell:description_4")}</p>
                        <p>{t("what_is_frewell:description_5")}</p>
                        <p>{t("what_is_frewell:description_6")}</p>
                    </Col>
                </Row>
                <TotalUserFeedback />
                <News />
            </Col>
        </>
    )
}

export default WhatIsFrewellComponent